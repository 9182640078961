import { ElxScopedContainer, useScope } from '@elixir/fx';
import Overview from './ContractSearchPivots/Overview/Overview';
import SCCEvent from './SSCEvent/SCCEvent';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory, useRouteMatch, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import SearchContract from './SearchBox/SearchContract';
import { CLEAR_STATE } from '../store/types';
import ContractChecker from './ContractChecker/ContractChecker';
import ContractSearchByAlias from './ContractSearchByAlias/ContractSearchByAlias';

const contractPivot = {
  itemKey: 'BraavosSearch',
  headerText: 'Contract Search',
  exact: false,
  component: Overview,
};
const sccEvent = {
  itemKey: 'SCCEvent',
  headerText: 'SCC Event',
  exact: false,
  component: SCCEvent,
};
const contractChecker = {
  itemKey: 'ContractValidator',
  headerText: 'Contract Validator',
  exact: false,
  component: ContractChecker,
};
const contractSearchByAlias = {
  itemKey: 'ContractSearchByAlias',
  headerText: 'Contract Search By Alias',
  exact: false,
  component: ContractSearchByAlias,
};

export const Contracts = () => {
  const scope = useScope();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const selectedKey = pathname.replace(match.path, '');
  const pivots = [contractPivot, sccEvent, contractChecker, contractSearchByAlias];
  return (
    <ElxScopedContainer
      scope={scope}
      headerText="CONTRACT SEARCH"
      subActions={[<SearchContract scope={scope} key={1} />]}
      pivotProps={{
        items: pivots,
        selectedKey: selectedKey,
        onItemSelect: selectedKey => {
          dispatch({ type: CLEAR_STATE });
          //dispatch(uxClearContainerMessageAction(scope));
          push(`${match.url}/${selectedKey}`);
        },
      }}>
      <Switch>
        {pivots.map(
          (item: { itemKey: string; headerText: string; exact?: boolean; component: any }) => {
            return (
              <Route
                key={item.itemKey}
                exact={item.exact}
                path={`${match.url}/${item.itemKey}`}
                render={props => <item.component {...props} scope={scope} />}
              />
            );
          },
        )}
        <Redirect to={match.url + '/' + pivots[0].itemKey} />
      </Switch>
    </ElxScopedContainer>
  );
};

export default Contracts;
