import * as type from './types';
import { IScope } from '@elixir/fx';
import { IFasSettingsModel } from './types/variabletypes';

export function getSetting(scope: IScope) {
  return {
    type: type.GET_SETTINGS_REQUESTED,
    scope,
    payload: {
      scope,
    },
  };
}

export function saveSetting(
  setting: IFasSettingsModel,
  scope: IScope,
) {
  return {
    type: type.SAVE_SETTING_REQUESTED,
    setting,
    scope,
    payload: {
      setting,
      scope,
    },
  };
}
