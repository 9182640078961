import { IModule } from '@elixir/fx';
import Operation from '../components/operations/store/reducers';
import { OperationSagas } from '../components/operations/store/sagas';
import Operations from '../components/operations/Operations';

export const operationsModule: IModule = {
  name: 'Operations',
  env: 'development',
  reducer: Operation,
  saga: OperationSagas,
  route: {
    index: 'Operations',
    name: 'Operations',
    breadcrumbLabel: 'Operations',
    iconProps: {
      iconName: 'Search',
      styles: {
        root: {
          color: '#e59216',
        },
      },
    },
    path: '/Operations/',
    isRouteable: true,
    exact: false,
    inSidebar: true,
    component: Operations,
  },
};
