import {
  IScope,
  ElxSearchBox,
  SearchBoxSize,
  ISearchCriteria,
  isNullOrWhitespace,
} from '@elixir/fx'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getContract } from '../../store/action'
import '../../styling/css/style.css'

function SearchContract(props: { scope: IScope }) {
  const dispatch = useDispatch()
  const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
    keyword: '',
    filters: {},
  });
  const [searchCriteria2, setSearchCriteria2] = useState<ISearchCriteria>({
		keyword: '',
		filters: {},
	});

  useEffect(() => {
    dispatch(getContract(searchCriteria.keyword, props.scope))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteria.keyword, dispatch])

  useEffect(() => {
		if (isNullOrWhitespace(searchCriteria2.keyword) && !isNullOrWhitespace(searchCriteria.keyword)) {
			setSearchCriteria({ keyword: '', filters: {} });
		}
	}, [searchCriteria2.keyword, searchCriteria.keyword]);

  return (
    <ElxSearchBox
      size={SearchBoxSize.Medium}
      onSearch={setSearchCriteria}
      onChange={setSearchCriteria2}
      placeholder="SEARCH"
    />
  )
}
export default SearchContract
