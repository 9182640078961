import { IModule } from "@elixir/fx";
import announcementsReducers from "../components/editAnnouncements/store/reducer";
import { AnnouncementsSagas } from "../components/editAnnouncements/store/sagas";
import Announcements from "../components/editAnnouncements/announcements";

export const editAnnouncements: IModule = {
  name: "Announcements",
  env: "development",
  reducer: announcementsReducers,
  saga: AnnouncementsSagas,
  route: {
    index: "Edit Announcements",
    name: "Edit Announcements",
    iconProps: {
      iconName: "Megaphone",
			styles: {
			  root: {
				color: '#eac510'
			  }
			}
    },
    path: "/EditAnnouncements",
    isRouteable: true,
    exact: true,
    inSidebar: true,
    component: Announcements,
  },
};
