import { IModule } from '@elixir/fx';
import featureReducers from '../components/features/store/reducers';
import { FeaturesSagas } from '../components/features/store/sagas';
import feature from '../components/features/features/features';

export const featuresModule: IModule = {
	name: 'Features',
	env: 'development',
	reducer: featureReducers,
	saga: FeaturesSagas,
	route: {
		index: 'Features',
		name: 'Features',
		iconProps: {
			iconName: "SetAction",
			styles: {
			  root: {
				color: '#4aaac2'
			  }
			}
		},
		path: '/Features',
		isRouteable: true,
		exact: true,
		inSidebar: true,
		component: feature,
	},
};
