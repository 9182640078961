import {
  Http,
  Logger,
  containerManagedAction,
  IScopedPayload,
  isNullOrWhitespace,
} from '@elixir/fx';
import {
  GET_CONTRACT_REQUESTED,
  GET_CONTRACT_DETAILS_REQUESTED,
  GET_MS_CONTACT_REQUESTED,
  GET_SERVICES_REQUESTED,
  GET_CONTACTS_REQUESTED,
  SEND_SCC_EVENT_REQUESTED,
  IContractPayload,
  IContractDetailsPayload,
  IMSContactPayload,
  IServicesPayload,
  IContactsPayload,
  ISCCEventPayload,
  IContractByAliasPayload,
  GET_CONTRACT_CHECKER_REQUESTED,
  GET_CONTRACT_BY_ALIAS_REQUESTED,
} from './types';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, takeEvery, put } from 'redux-saga/effects';
import { getConfig } from '../../../OpsConfig';
import { isEmpty } from 'lodash';
import { getApiErrorAction } from '../../utility/errorHandling'

export function getContractSystemUrlPath(isFederal: boolean|null): string {
  return isFederal ? 'sap' : 'braavos';
}

function* getContractById(action: PayloadAction<IScopedPayload & IContractPayload>): SagaIterator {
  yield put({ type: 'GET_CONTRACT_SUCCESS', payload: {}, contract: {} });
  try {
    const contractId = action.payload.contractId;
    if (isNullOrWhitespace(contractId) || contractId === '' || isEmpty(contractId)) {
    } else {
      const config = yield call(getConfig);
      let contractPath = `${config.apiBaseURL}/${getContractSystemUrlPath(
        config.isFederal,
      )}/GetAgreement?contractId=${contractId}&includePackages=true&includeServices=false`;

      const contract = yield call(Http.get, action, contractPath);
      
      yield put({
        type: 'GET_CONTRACT_SUCCESS',
        contract: contract.data,
        config: config,
        contractId
      });
    }
  } catch (err) {
    yield put(
      getApiErrorAction(err, 'GET_CONTRACT_FAILURE',
        new Map([
          [400, 'Please enter a valid ContractId (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx).'],
          [0, 'An unexpected error occurred while attempting to retrieve the contract.']
        ])));
    Logger.error(err);
  }
}

function* getContractDetailsById(
  action: PayloadAction<IScopedPayload & IContractDetailsPayload>,
): SagaIterator {
  try {
    const contractId = action.payload.contractId;
    const config = yield call(getConfig);
    let contractPath = `${config.apiBaseURL}/${getContractSystemUrlPath(
      config.isFederal,
    )}/GetAgreement?contractId=${contractId}&includePackages=true&includeServices=true&includePackageContacts=true`;

    const contract = yield call(Http.get, action, contractPath);
    yield put({
      type: 'GET_CONTRACT_DETAILS_SUCCESS',
      payload: contract.data,
      contractDetails: contract.data,
      config: config,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_CONTRACT_DETAILS_FAILURE'));
    Logger.error(err);
  }
}
function* getMSContactById(
  action: PayloadAction<IScopedPayload & IMSContactPayload>,
): SagaIterator {
  const pId = action.payload.packageId;
  try {
    const config = yield call(getConfig);
    let mscontactPath = `${config.apiBaseURL}/${getContractSystemUrlPath(
      config.isFederal,
    )}/GetMSContacts?packageId=${pId}`;
    const msContact = yield call(Http.get, action, mscontactPath);
    yield put({
      type: 'GET_MS_CONTACT_SUCCESS',
      msContact: msContact.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_MS_CONTACT_FAILURE'));
    Logger.error(err);
  }
}

function* getServicesById(action: PayloadAction<IScopedPayload & IServicesPayload>): SagaIterator {
  const pId = action.payload.packageId;

  try {
    const config = yield call(getConfig);
    let servicePath = `${config.apiBaseURL}/${getContractSystemUrlPath(
      config.isFederal,
    )}/GetServices?packageId=${pId}`;

    const services = yield call(Http.get, action, servicePath);
    yield put({
      type: 'GET_SERVICES_SUCCESS',
      services: services.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_SERVICES_FAILURE'));
    Logger.error(err);
  }
}

function* getContactsById(action: PayloadAction<IScopedPayload & IContactsPayload>): SagaIterator {
  const pId = action.payload.packageId;
  try {
    const config = yield call(getConfig);
    let contactPath = `${config.apiBaseURL}/${getContractSystemUrlPath(
      config.isFederal,
    )}/GetCustomerContacts?packageId=${pId}`;
    const contacts = yield call(Http.get, action, contactPath);
    yield put({
      type: 'GET_CONTACTS_SUCCESS',
      contacts: contacts.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_CONTACTS_FAILURE'));
    Logger.error(err);
  }
}
function* sendSCC(action: PayloadAction<IScopedPayload & ISCCEventPayload>): SagaIterator {
  const contractId = action.payload.contractId;
  const scheduleId = action.payload.scheduleId;
  const installationId = action.payload.installationId;
  try {
    const config = yield call(getConfig);
    let contactPath = `${config.apiBaseURL}/SCCEventController/SendSCCEEvent?contractId=${contractId}&scheduleId=${scheduleId}&siteId=${installationId}&locale=en-US`;

    const res = yield call(Http.post, action, contactPath);
    if (res.status === 200) {
      yield put(
        {
          type: 'SEND_SCC_EVENT_SUCCESS',
        });
    }
  } catch (err) {
    yield put(getApiErrorAction(err, 'SEND_SCC_EVENT_FAILURE'));
    Logger.error(err);
  }
}
function* getContractCheckerById(
  action: PayloadAction<IScopedPayload & IContractDetailsPayload>,
): SagaIterator {
  const contractId = action.payload.contractId;
  const installationSiteId = action.payload.installationSiteId || '1';
  try {
    const config = yield call(getConfig);
    let contractPath = `${config.apiBaseURL}/ContractValidationController/GetContractValidationReport?contractId=${contractId}&installationSiteId=${installationSiteId}`;
    
    const contract = yield call(Http.get, action, contractPath);
    yield put({
      type: 'GET_CONTRACT_CHECKER_SUCCESS',
      contractChecker: contract.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_CONTRACT_CHECKER_FAILURE'));
    Logger.error(err);
  }
}

function* getContractByAlias(
  action: PayloadAction<IScopedPayload & IContractByAliasPayload>,
): SagaIterator {
  try {
    const csamAlias = action.payload.csamAlias;
    const config = yield call(getConfig);
    let contractPath = `${config.apiBaseURL}/${getContractSystemUrlPath(
      config.isFederal,
    )}/GetAgreement?tamAlias=${csamAlias}&includePackages=true&includeServices=true&includePackageContacts=true`;

    const contractsByAlias = yield call(Http.get, action, contractPath);
    yield put({
      type: 'GET_CONTRACT_BY_ALIAS_SUCCESS',
      payload: contractsByAlias.data,
      contractsByAlias: contractsByAlias.data,
      config: config,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_CONTRACT_BY_ALIAS_FAILURE'));
    Logger.error(err);
  }
}

export function* ContractSagas(): Generator {
  yield takeLatest(GET_CONTRACT_REQUESTED, containerManagedAction(getContractById));
  yield takeLatest(GET_CONTRACT_DETAILS_REQUESTED, containerManagedAction(getContractDetailsById));

  yield takeEvery(GET_MS_CONTACT_REQUESTED, containerManagedAction(getMSContactById));

  yield takeEvery(GET_SERVICES_REQUESTED, containerManagedAction(getServicesById));
  yield takeEvery(GET_CONTACTS_REQUESTED, containerManagedAction(getContactsById));
  yield takeEvery(SEND_SCC_EVENT_REQUESTED, containerManagedAction(sendSCC));

  yield takeLatest(GET_CONTRACT_CHECKER_REQUESTED, containerManagedAction(getContractCheckerById));

  yield takeLatest(GET_CONTRACT_BY_ALIAS_REQUESTED, containerManagedAction(getContractByAlias));
}
export default ContractSagas;
