import { useState } from "react";
import { ElxShell } from "@elixir/fx";
import { ImageIcon, IToggleProps, Toggle } from "@fluentui/react";
//import { darkTheme, lightTheme } from './styling/themeStyling';
import mslogo from "./styling/images/Grid.svg";
import { sidebarModules } from "./sidebar";
import { ExampleThemeContext } from "./Utility/theme.utility";
import { IElxShellProps } from "@elixir/fx/lib/framework/shell/ElxShell.types";
import { createTheme } from "@fluentui/react";
import {
  lightTheme as elxLightTheme,
  darkTheme as elxDarkTheme,
} from "@elixir/fx/lib/common/styling";
import { IConfig } from "./OpsConfig";

const DarkModeToggle = (props: IToggleProps): JSX.Element => {
  return (
    <Toggle
      styles={{ root: { display: "inline-block", padding: "0 1rem" } }}
      onText="Dark theme"
      offText="Light theme"
      {...props}
    />
  );
};
const storage_theme = "theme";
export const getCurrentThemeFromStorage = (storage: Storage) =>
  storage.getItem(storage_theme);
const storageContainsDarkTheme = (storage: Storage) =>
  getCurrentThemeFromStorage(storage) === "darkTheme";
const updateThemeInStorage = (
  storage: Storage,
  theme: "lightTheme" | "darkTheme"
) => {
  storage.setItem(storage_theme, theme);
};

// Create new themes based on default theme colors to ensure consistency between light and dark themes

export const darkTheme = createTheme({
  palette: elxDarkTheme.palette,

  semanticColors: {
    ...elxDarkTheme.semanticColors,

    // Copied from Azure theme

    link: "#4894fe",
    bodyText: "#fff",
    linkHovered: "#82C7FF",
  },

  isInverted: true,
});

export const lightTheme = createTheme({
  palette: elxLightTheme.palette,

  semanticColors: {
    ...elxLightTheme.semanticColors,

    // Copied from Azure theme

    link: "#0078D4",
    bodyText: "#000",
    linkHovered: "#004578",
  },
  isInverted: false,
});

export interface IAppProps extends IElxShellProps {
  config?: IConfig
}

export const OpsSite = (props: IAppProps) => {
  const storageSolution = localStorage;
  const [darkMode, setDarkMode] = useState<boolean>(() =>
    storageContainsDarkTheme(storageSolution)
  );

  return (
    <ExampleThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <ElxShell
        {...props}
        modules={sidebarModules}
        defaultPath="/Home"
        theme={darkMode ? darkTheme : lightTheme}
      >
        <ElxShell.Branding
          applicationName="Services Hub Operations"
          onRenderLogo={() => (
            <>
              <ImageIcon imageProps={{ src: mslogo }} />
              <p className="logo-text"></p>
            </>
          )}
        />
        <ElxShell.Actions
          actions={[
            {
              name: "Feedback",
              iconProps: { iconName: "Feedback" },
              buttonProps: {
                href: "https://aka.ms/SH-OpsCenterFeedback",
                target: "_blank",
              },
            },
          ]}
        >
          <DarkModeToggle
            checked={darkMode}
            tabIndex={0}
            onChange={() => {
              setDarkMode((darkTheme: boolean) => {
                updateThemeInStorage(
                  storageSolution,
                  darkTheme ? "lightTheme" : "darkTheme"
                );
                return !darkTheme;
              });
            }}
          />
        </ElxShell.Actions>
      </ElxShell>
    </ExampleThemeContext.Provider>
  );
};
