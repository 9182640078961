import { Action } from '@reduxjs/toolkit'
import IIncidentModel from './types/variablestypes'

export const GET_CONTRACT_REQUESTED = 'GET_CONTRACT_REQUESTED'
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS'
export const GET_CONTRACT_FAILURE = 'GET_CONTRACT_FAILURE'
export const GET_MS_CONTACT_REQUESTED = 'GET_MS_CONTACT_REQUESTED'
export const GET_MS_CONTACT_SUCCESS = 'GET_MS_CONTACT_SUCCESS'
export const GET_MS_CONTACT_FAILURE = 'GET_MS_CONTACT_FAILURE'
export const GET_SERVICES_REQUESTED = 'GET_SERVICES_REQUESTED'
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS'
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE'
export const GET_CONTACTS_REQUESTED = 'GET_CONTACTS_REQUESTED'
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS'
export const GET_CONTACTS_FAILURE = 'GET_CONTACTS_FAILURE'
export const GET_CONTRACT_DETAILS_REQUESTED = 'GET_CONTRACT_DETAILS_REQUESTED'
export const GET_CONTRACT_DETAILS_SUCCESS = 'GET_CONTRACT_DETAILS_SUCCESS'
export const GET_CONTRACT_DETAILS_FAILURE = 'GET_CONTRACT_DETAILS_FAILURE'
export const SEND_SCC_EVENT_REQUESTED = 'SEND_SCC_EVENT_REQUESTED'
export const SEND_SCC_EVENT_SUCCESS = 'SEND_SCC_EVENT_SUCCESS'
export const SEND_SCC_EVENT_FAILURE = 'SEND_SCC_EVENT_FAILURE'
export const CLEAR_STATE = 'CLEAR_STATE';
export const GET_CONTRACT_CHECKER_REQUESTED = 'GET_CONTRACT_CHECKER_REQUESTED'
export const GET_CONTRACT_CHECKER_SUCCESS = 'GET_CONTRACT_CHECKER_SUCCESS'
export const GET_CONTRACT_CHECKER_FAILURE = 'GET_CONTRACT_CHECKER_FAILURE'
export const GET_CONTRACT_BY_ALIAS_REQUESTED = 'GET_CONTRACT_BY_ALIAS_REQUESTED'
export const GET_CONTRACT_BY_ALIAS_SUCCESS = 'GET_CONTRACT_BY_ALIAS_SUCCESS'
export const GET_CONTRACT_BY_ALIAS_FAILURE = 'GET_CONTRACT_BY_ALIAS_FAILURE'

// getContract
export interface IContractPayload extends Action<typeof GET_CONTRACT_REQUESTED> {
  contractId: string
}

export interface IContractSuccess extends Action<typeof GET_CONTRACT_SUCCESS> {
  contract: IIncidentModel,
  config: any,
  contractId: any
}

// getContractDetails
export interface IContractDetailsPayload
  extends Action<typeof GET_CONTRACT_DETAILS_REQUESTED> {
  contractId: string
}

export interface IContractDetailsSuccess
  extends Action<typeof GET_CONTRACT_DETAILS_SUCCESS> {
  contractDetails: Array<any>,
  config: any
}

// getMSContact
export interface IMSContactPayload
  extends Action<typeof GET_MS_CONTACT_REQUESTED> {
  //msContact: Array<Object>
  packageId: string
}

export interface IMSContactSuccess
  extends Action<typeof GET_MS_CONTACT_SUCCESS> {
  msContact: Object
}

// getServices
export interface IServicesPayload
  extends Action<typeof GET_SERVICES_REQUESTED> {
  //services: Array<Object>
  packageId: string
}

export interface IServicesSuccess extends Action<typeof GET_SERVICES_SUCCESS> {
  services: Object
}

// getContacts
export interface IContactsPayload
  extends Action<typeof GET_CONTACTS_REQUESTED> {
  //contacts: Array<Object>
  packageId: string
}

export interface IContactsSuccess extends Action<typeof GET_CONTACTS_SUCCESS> {
  contacts: Array<Object>
}

// sendSccEvent
export interface ISCCEventPayload
  extends Action<typeof SEND_SCC_EVENT_REQUESTED> {
  contractId: string
  scheduleId: string
  installationId: string
}

export interface IContractColumns {
  [key: string]: string
}

export interface IContactColumns {
  [key: string]: string
}

export interface IPackageServiceOffering {
  PackageId: string,
  ServiceOfferingId: string,
}

export interface IAttachedToWorkspace {
  PackageId: string,
  WorkspaceId: string,
}

export interface IValidationReportMessage {
  Category: string,
  Message: string,
}

export interface IValidationReportItem {
  EntityType: string,
  EntityId: number,
  EntityInstallationSiteId: number,
  Messages: Array<IValidationReportMessage>,
}

export interface IContractCheckerPayload
  extends Action<typeof GET_CONTRACT_CHECKER_REQUESTED> {
  ReportDateUtc: string,
  ContractId: string,
  InstallationSiteId: number,
  CurrentContractType: string,
  CurrentCountOfPackages: number,
  CurrentCountOfContacts: number,
  CurrentCountOfContactsDistinct: number,
  RenewedFromContracts: string,
  HasErrors: boolean,
  HasWarnings: boolean,
  IsActive: boolean,
  IsSupportEnabled: boolean,
  IsMultiYear: boolean,
  IsMultiCountry: boolean,
  AttachedToCustomers: [string],
  AttachedToWorkspaces: Array<IAttachedToWorkspace>,
  ValidationReportItems: Array<IValidationReportItem>,
  PackageServiceOfferings: Array<IPackageServiceOffering>
}

export interface IContractCheckerSuccess
  extends Action<typeof GET_CONTRACT_CHECKER_SUCCESS> {
  contractChecker: IContractCheckerPayload
}

export interface IContractCheckerTableRow {
  Icon: any,
  Severity: string,
  Type: string,
  Id: number,
  Action: string,
  Condition: string,
  Topic: string,
  Who: string,
}

export interface IContractByAliasPayload extends Action<typeof GET_CONTRACT_BY_ALIAS_REQUESTED> {
    csamAlias: string,
    installationId: string,
}

export interface IContractByAliasSuccess extends Action<typeof GET_CONTRACT_BY_ALIAS_SUCCESS> {
  contractsByAlias: Array<any>,
  config: any
}
