import { ElxScopedContainer, useScope } from '@elixir/fx';
import { Switch, Route } from 'react-router-dom';
import { useLocation, useHistory, useRouteMatch, Redirect } from 'react-router';
import SearchBar from './SearchBar/SearchBar';
import Customer from './OperationsPivot/Customer';
import Workspace from './OperationsPivot/Workspace';
import Users from './OperationsPivot/Users';
import Registration from './OperationsPivot/Registration';

const customerPivot = {
  itemKey: 'Customer',
  headerText: 'CUSTOMER',
  exact: false,
  component: Customer,
};
const workspacePivot = {
  itemKey: 'Workspace',
  headerText: 'WORKSPACE',
  exact: false,
  component: Workspace,
};
const userPivot = {
  itemKey: 'User',
  headerText: 'USERS',
  exact: false,
  component: Users,
};
const registrationPivot = {
  itemKey: 'Registration',
  headerText: 'REGISTRATION',
  exact: false,
  component: Registration,
};

export const Operations = (props: { ID: string; OpsType: string; servicesHubUrl?: string; }) => {
  const scope = useScope();
  const { push } = useHistory();
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const selectedKey = pathname.replace(match.path, '');
  //const selectedKey=pathname.replace('/Operations/', '');
  const pivots = [customerPivot, workspacePivot, userPivot, registrationPivot];
  return (
    <ElxScopedContainer
      scope={scope}
      headerText="OPERATIONS SEARCH"
      subActions={[<SearchBar scope={scope} OpsType={selectedKey} key={1} />]}
      pivotProps={{
        items: pivots,
        selectedKey: selectedKey,
        onItemSelect: selectedKey => {
          push(`${match.url}/${selectedKey}`);
        },
      }}>
      <Switch>
        {pivots.map(
          (item: { itemKey: string; headerText: string; exact?: boolean; component: any }) => {
            return (
              <Route
                key={item.itemKey}
                exact={item.exact}
                path={`${match.url}/${item.itemKey}`}
                render={routeprops => (
                  <item.component {...routeprops} scope={scope} OpsType={selectedKey} />
                )}
              />
            );
          },
        )}
        <Redirect to={match.url + '/' + pivots[0].itemKey} />
      </Switch>
    </ElxScopedContainer>
  );
};
export default Operations;
