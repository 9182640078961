import {
	IElxColumn,
	IElxContainerProps,
	IElxSearchBoxProps,
	ElxTableContainer,
	IScope,
	SelectionMode,
  MessageBarType,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';
import { ErrorType, IError } from '../../../../utility/errorHandling'
import { PermissionErrorMessage } from '../../../../utility/PermissionErrorMessage';
import { isEmpty, isEqual } from 'lodash';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMSContact } from '../../../store/action';
import '../../../styling/css/style.css';

export const Microsoft = (props: {
	packageID: string;
	scope: IScope;
	searchProps?: IElxSearchBoxProps;
	containerProps?: IElxContainerProps;
}) => {

	const searchBoxProps = {
		...props.searchProps,
	};

	const MSContactsColumns: {
		'First Name': any;
		'Last Name': any;
		Alias: any;
		Role: any;
	}[] = [];

	const dispatch = useDispatch();

  // Subscribe for data changes
	const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Contract?.error
  );
	const msContact: Array<Object> = useSelector(
		(state: any) => state?.modules?.Contract?.msContact
	);

	// Get MSContacts for current Contract
	useEffect(() => {
		dispatch(getMSContact(props.packageID, props.scope));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Check for an error
	useEffect(() => {
		if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
			if (error?.errorType === ErrorType.Permission) {
				dispatch(
					uxAddContainerMessageAction(
						{
							type: MessageBarType.error,
							message: (
								<PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
							)
						},
						props.scope)
				);
			} else {
				dispatch(
					uxAddContainerMessageAction(
						{
							type: MessageBarType.error,
							message: error?.errorMessage ?? 'An unexpected error occurred.'
						},
						props.scope)
				);
			}
		} else {
			// Clear any existing error message
			dispatch(uxClearContainerMessageAction(props.scope));
		}
	});

	// Check if there are no contacts to display
	if (msContact === undefined || isEqual(msContact, {}) || isEmpty(msContact)) {
		return (
			<div className='default-contract'>
				<b style={{ color: '#605E5C', letterSpacing: 2 }}>
					THERE ARE NO MICROSOFT CONTACTS TO DISPLAY
				</b>
			</div>
		);
	}

	// Otherwise, display the returned contact data
	else {
		// eslint-disable-next-line array-callback-return
		msContact?.map((x: any) => {
			const MSContactsJSON = {
				'First Name': x?.firstName,
				'Last Name': x?.lastName ?? 'null',
				Alias: x?.alias,
				Role: x?.role,
			};
			MSContactsColumns.push(MSContactsJSON);
		});
		return (
			<>
				<ElxTableContainer
					containerProps={{
						headerText:'SEARCH MICROSOFT CONTACTS',
						fillBackground: false,
						compact: false,
					}}
					tableProps={{
						compact: true,
						columns: getColumns(),
						items: MSContactsColumns,
						selectionMode: SelectionMode.none,
					}}
					searchBoxProps={searchBoxProps}
				/>
			</>
		);
	}

	function getColumns(): IElxColumn[] {
		return [
			{
				key: '2',
				name: 'First Name',
				fieldName: 'First Name',
				minWidth: 100,
				maxWidth: 150,
				isResizable: false,
			},
			{
				key: '3',
				name: 'Last Name',
				fieldName: 'Last Name',
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
			},
			{
				key: '4',
				name: 'Alias',
				fieldName: 'Alias',
				minWidth: 100,
				maxWidth: 150,
				isResizable: false,
			},
			{
				key: '5',
				name: 'Role',
				fieldName: 'Role',
				minWidth: 100,
				maxWidth: 150,
				isResizable: false,
			},
		];
	}
};

export default Microsoft;
