import * as type from './types'
import { IScope } from '@elixir/fx'
import { IAnnouncement } from './types/variableTypes'

export function getAnnouncements(scope: IScope, workspaceId: string) {
  return {
    type: type.GET_ANNOUNCEMENTS_REQUESTED,
    workspaceId,
    scope,
    payload: {
      workspaceId,
      scope,
    },
  }
}

export function saveAnnouncement(
  announcement: IAnnouncement,
  scope: IScope,
) {
  return {
    type: type.SAVE_ANNOUNCEMENT_REQUESTED,
    announcement,
    scope,
    payload: {
      announcement,
      scope,
    },
  }
}

export function previewAnnouncementImages(imageIdList: Array<string>, scope: IScope) {
  return {
    type: type.ANNOUNCEMENT_IMAGES_REQUESTED,
    imageIdList,
    scope,
    payload: {
      imageIdList,
      scope,
    },
  }
}

export function previewAnnouncementImageRead(imageName: string, scope: IScope) {
  return {
    type: type.ANNOUNCEMENT_IMAGE_READ_REQUESTED,
    imageName,
    scope,
    payload: {
      imageName,
      scope,
    },
  }
}

export function addAnnouncement(
  newAnnouncement: IAnnouncement,
  scope: IScope,
) {
  return {
    type: type.ADD_ANNOUNCEMENT_REQUESTED,
    newAnnouncement,
    scope,
    payload: {
      newAnnouncement,
      scope,
    },
  }
}

export function deleteAnnouncement(announcementId: string, scope: IScope) {
  return {
    type: type.DELETE_ANNOUNCEMENT_REQUESTED,
    announcementId,
    scope,
    payload: {
      announcementId,
      scope,
    },
  }
}

export function approveAnnouncements(announcementIdList: {}, scope: IScope) {
  return {
    type: type.APPROVE_ANNOUNCEMENTS_REQUESTED,
    announcementIdList,
    scope,
    payload: {
      announcementIdList,
      scope,
    },
  }
}

// NOT USED
//export function getDesktopImageURI(imageName: string, image: File) {
//  return {
//    type: type.GET_DESKTOP_IMAGE_URI_REQUESTED,
//    imageName,
//    image,
//    payload: {
//      imageName,
//      image,
//    },
//  }
//}
//
//export function getMobileImageURI(imageName: string, image: File) {
//  return {
//    type: type.GET_MOBILE_IMAGE_URI_REQUESTED,
//    imageName,
//    image,
//    payload: {
//      imageName,
//      image,
//    },
//  }
//}
//
//export function getTabletImageURI(imageName: string, image: File) {
//  return {
//    type: type.GET_TABLET_IMAGE_URI_REQUESTED,
//    imageName,
//    image,
//    payload: {
//      imageName,
//      image,
//    },
//  }
//}
//
//export function putDesktopImageToBlob(imageURL: string, image: File) {
//  return {
//    type: type.PUT_DESKTOP_IMAGE_TO_BLOB,
//    imageURL,
//    image,
//    payload: {
//      imageURL,
//      image,
//    },
//  }
//}

//export function putMobileImageToBlob(imageURL: string, image: File) {
//  return {
//    type: type.PUT_MOBILE_IMAGE_TO_BLOB,
//    imageURL,
//    image,
//    payload: {
//      imageURL,
//      image,
//    },
//  }
//}

//export function putTabletImageToBlob(imageURL: string, image: File) {
//  return {
//    type: type.PUT_TABLET_IMAGE_TO_BLOB,
//    imageURL,
//    image,
//    payload: {
//      imageURL,
//      image,
//    },
//  }
//}
