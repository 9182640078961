import {
	Http,
	Logger,
	containerManagedAction,
	IScopedPayload,
} from '@elixir/fx';
import {
	GET_FEATURES_REQUESTED,
	SAVE_FEATURE_REQUESTED,
	IGetFeaturesPayload,
	ISaveFeaturePayload,
} from './types';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, takeEvery, put } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { getConfig } from '../../../OpsConfig';
import { getApiErrorAction, ErrorType, IError } from '../../utility/errorHandling'

// NOT USED
//export const NotAvailableMessage: IMessage = {
//	message:
//		'Common Solutions editing is temporarily unavailable.  Please try again in several minutes.',
//	type: MessageBarType.blocked,
//	isMultiline: false,
//};

function* getFeatureList(
	action: PayloadAction<IScopedPayload & IGetFeaturesPayload>
): SagaIterator {
	yield put({ type: 'GET_FEATURES_SUCCESS', featureList: {} });
	try {
		const config = yield call(getConfig);
		let featurePath = `${config.apiBaseURL}/feature/GetFeatureList`;
		const features = yield call(Http.get, action, featurePath);
		yield put({
			type: 'GET_FEATURES_SUCCESS',
			featureList: features.data,
		});
	} catch (err) {
		yield put(getApiErrorAction(err, 'GET_FEATURES_FAILURE'));
		Logger.error(err)
	} finally {
	}
}

function* saveFeature(
	action: PayloadAction<IScopedPayload & ISaveFeaturePayload>
): SagaIterator {
	const feature = action.payload.feature;
	try {
		const config = yield call(getConfig);
		let featurePath = `${config.apiBaseURL}/feature/UpsertFeature`;
		const updatedFeature = yield call(Http.post, action, featurePath, feature);
		// save was successful if we got an updated feature back
		if (!isEmpty(updatedFeature.data)) {
			yield put(
				{
					type: 'SAVE_FEATURE_SUCCESS',
					feature: updatedFeature.data,
				});
		} else {
			yield put(
				{
					type: 'SAVE_FEATURE_FAILURE',
					error: {
						errorType: ErrorType.Other,
						errorMessage: 'Feature update failed'
					} as IError
				});
		}
	} catch (err) {
		yield put(getApiErrorAction(err, 'SAVE_FEATURE_FAILURE'));
		Logger.error(err);
	} finally {
	}
}

export function* FeaturesSagas(): Generator {
	yield takeEvery(
		GET_FEATURES_REQUESTED,
		containerManagedAction(getFeatureList)
	);
	yield takeLatest(
		SAVE_FEATURE_REQUESTED,
		containerManagedAction(saveFeature)
	);
}
export default FeaturesSagas;
