import {
  ElxScopedContainer,
  IScope,
  useScope,
  MessageBarType,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
  ElxPrimaryButton,
} from '@elixir/fx';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ResultStatus, ErrorType, IError } from '../../../utility/errorHandling';
import { sendSccEvent } from '../../store/action'
import { PermissionErrorMessage } from '../../../utility/PermissionErrorMessage';

export const SCCEvent = (props: { scope: IScope }) => {
  const scope = useScope()
  const dispatch = useDispatch()

  // Subscribe for data changes
  const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Contract?.error
  );
  const sendSccEventStatus: ResultStatus = useSelector(
    (state: any) => state?.modules?.Contract?.sendSccEventStatus
  );

  // Setup state variables
  const [contractID, setContractID] = useState('')
  const [scheduleID, setScheduleID] = useState('')
  const [installationID, setInstallationID] = useState('')

  // Define button callback function that will initiate the SCC action request
  function callSCCAction() {
    dispatch(sendSccEvent(contractID, scheduleID, installationID, props.scope))
  }

  // Check for an error
  useEffect(() => {
      if (sendSccEventStatus === ResultStatus.Failure && !isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: (
                <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
              )
            },
            props.scope)
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.'
            },
            props.scope)
        );
      }
    }

    // Display message if action was successful
    else if (sendSccEventStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: `SCC event was successfully sent.`,
          },
          props.scope
        )
      );
    }

    // Otherwise, clear any previous message
    else {
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Return data form
  return (
    <ElxScopedContainer scope={scope}>
      <div style={{ margin: 20 }}>
        <div>
          <input
            type="text"
            className="scc-input-style"
            placeholder="Enter Contract ID"
            value={contractID}
            onChange={(event) => setContractID(event.target.value)}
          />
        </div>
        <div>
          <input
            type="text"
            className="scc-input-style"
            placeholder="Enter Schedule ID"
            value={scheduleID}
            onChange={(event) => setScheduleID(event.target.value)}
          />
        </div>
        <div>
          <input
            type="text"
            className="scc-input-style"
            placeholder="Enter Installation ID"
            value={installationID}
            onChange={(event) => setInstallationID(event.target.value)}
          />
        </div>
        <div>
          <ElxPrimaryButton
            text="Submit"
            className="json-button"
            onClick={callSCCAction}
          />
        </div>
      </div>
    </ElxScopedContainer>
  )
}

export default SCCEvent
