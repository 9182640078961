import * as type from './types';
import { ResultStatus, IError } from '../../utility/errorHandling'

const initialState = {
	scriptList: [],
	scriptName: '',
	scriptJson: '',
	traces: [],
	startDate: '',
	endDate: '',
	scriptSuccessId:'',
	runScriptStatus: ResultStatus.Unknown,
	cancelScriptStatus: ResultStatus.Unknown,
	getTracesStatus: ResultStatus.Unknown,
  loading: false,
	error: null,
};

export default function script(
	state = initialState,
	action: {
		type: any;
		scriptList: [];
		scriptName: string;
		scriptJson: string;
		startDate: string;
		endDate: string;
		scriptSuccessId:string;
		runScriptStatus: ResultStatus,
		cancelScriptStatus: ResultStatus,
		getTracesStatus: ResultStatus,
		traces: [];
		script: any;
		error: IError;
	}
) {
	switch (action.type) {
	
		// Clear state
		case type.CLEAR_STATE:
			return {
				...state,
				error: initialState.error,
				runScriptStatus: ResultStatus.Unknown,
				cancelScriptStatus: ResultStatus.Unknown,
				getTracesStatus: ResultStatus.Unknown,
			};

		// Get Scripts
		case type.GET_SCRIPTS_REQUESTED:
			return {
				...state,
				loading: true,
			};
		case type.GET_SCRIPTS_SUCCESS:
			return {
				...state,
				loading: false,
				scriptList: action.scriptList,
				error: initialState.error,
				runScriptStatus: ResultStatus.Unknown,
				cancelScriptStatus: ResultStatus.Unknown,
				getTracesStatus: ResultStatus.Unknown,
			};
		case type.GET_SCRIPTS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
				runScriptStatus: ResultStatus.Unknown,
				cancelScriptStatus: ResultStatus.Unknown,
				getTracesStatus: ResultStatus.Unknown,
			};

		// Run a script
		case type.RUN_SCRIPT_REQUESTED:
			return {
				...state,
				loading: false,
				//scriptSuccessId: initialState.scriptSuccessId,
				error: null,
			};
		case type.RUN_SCRIPT_SUCCESS:
			return {
				...state,
				loading: false,
				scriptSuccessId: action.scriptSuccessId,
				runScriptStatus: ResultStatus.Success,
				cancelScriptStatus: ResultStatus.Unknown,
				getTracesStatus: ResultStatus.Unknown,
				error: initialState.error,
			};
		case type.RUN_SCRIPT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
				runScriptStatus: ResultStatus.Failure,
				cancelScriptStatus: ResultStatus.Unknown,
				getTracesStatus: ResultStatus.Unknown,
      }

		// Cancel a running script
		case type.CANCEL_SCRIPT_REQUESTED:
			return {
				...state,
				loading: false,
			};
		case type.CANCEL_SCRIPT_SUCCESS:
			return {
				...state,
				loading: false,
				scriptName: action.scriptName,
				error: initialState.error,
				cancelScriptStatus: ResultStatus.Failure,
				runScriptStatus: ResultStatus.Unknown,
				getTracesStatus: ResultStatus.Unknown,
			};
		case type.CANCEL_SCRIPT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
				cancelScriptStatus: ResultStatus.Failure,
				runScriptStatus: ResultStatus.Unknown,
				getTracesStatus: ResultStatus.Unknown,
			}

		// Get traces from script run
		case type.GET_TRACES_REQUESTED:
			return {
				...state,
				loading: true,
			};
		case type.GET_TRACES_SUCCESS:
			return {
				...state,
				loading: false,
				scriptName: action.scriptName,
				startDate: action.startDate,
				endDate: action.endDate,
				traces: action.traces,
				getTracesStatus: ResultStatus.Success,
				runScriptStatus: ResultStatus.Unknown,
				cancelScriptStatus: ResultStatus.Unknown,
				error: initialState.error,
			};
		case type.GET_TRACES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
				getTracesStatus: ResultStatus.Failure,
				runScriptStatus: ResultStatus.Unknown,
				cancelScriptStatus: ResultStatus.Unknown,
			}

		default:
			return state;
	}
}
