import '../../../styling/css/style.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IIncidentModel, { ICustomerSearchModel } from '../store/types/variabletypes';
import { IStackTokens, IStackItemStyles, mergeStyles } from '@fluentui/react';
import { isEmpty, isEqual } from 'lodash';
import { DocumentCard, DocumentCardTitle } from 'office-ui-fabric-react/lib/DocumentCard';
import { Stack } from '@fluentui/react';
import JSONPretty from 'react-json-pretty';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { useLocation } from 'react-router';
import {
  ElxCopy,
  ElxDialog,
  ElxIconButton,
  ElxPanel,
  ElxScopedContainer,
  ElxSecondaryButton,
  ElxTableContainer,
  getLogger,
  IElxColumn,
  IElxContainerProps,
  IElxSearchBoxProps,
  IMessage,
  IScope,
  isNullOrWhitespace,
  MessageBarType,
  PanelSize,
  SelectionMode,
  useScope,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';
import {
  addAccessToCustomer,
  addAccessToWorkspace,
  addMsaToWorkspace,
  getSearchResults,
  getCustomerWorkspaces,
  removeAccessFromCustomer,
  removeAccessFromWorkspaces,
  removeMsaFromWorkspaces,
} from '../store/action';
import { Link } from '@fluentui/react';
import { getCurrentThemeFromStorage, darkTheme, lightTheme } from '../../../app';
import { ICustomerColumns } from '../store/types';
import { ResultStatus, ErrorType, IError } from '../../utility/errorHandling';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';
import { Resources } from '../../../resources';

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 5,
    cursor: 'Pointer',
    flex: 1,
  },
};
export const cardContentStackStyles = mergeStyles({
  width: 300,
});
export const cardContentStackStyles2 = mergeStyles({
  width: 280,
});
export const cardContentStackStyles3 = mergeStyles({
  width: 300,
});
export const cardContentStackTokens: IStackTokens = {
  childrenGap: '1',
};
const stackItemStyles2: IStackItemStyles = {
  root: {
    //background: DefaultPalette.themePrimary,
    //color: DefaultPalette.white,
    padding: 5,
    cursor: 'Pointer',
    textAlign: 'right',
  },
};

interface IPanelExampleState {
  show: boolean;
  size: PanelSize;
  message?: IMessage;
  isLoading: boolean;
  fillBackground: boolean;
}

interface IDialogScopeState {
  modal: boolean;
  toggleChecked: boolean;
  workspaceId: string;
}

export const Customer = (props: {
  scope: IScope;
  OpsType: string;
  servicesHubUrl: string;
  searchProps?: IElxSearchBoxProps;
  containerProps?: IElxContainerProps;
}) => {
  const logger = getLogger();
  const scope = useScope();
  const dispatch = useDispatch();
  const theme = getCurrentThemeFromStorage(localStorage);
  const customerColumns: ICustomerColumns[] = [];
  const customerWorkspaceColumns: ICustomerColumns[] = [];
  const customerWorkspaceID: { [key: string]: Array<any> } = {};
  const workspaceList: { [key: string]: Array<any> } = {};
  const workspaceColumns4: { [key: string]: boolean } = {};
  const workspaceColumns5: { [key: string]: boolean } = {};
  const customerColumns2: { [key: string]: boolean } = {};
  const customerColumns3: { [key: string]: ICustomerColumns } = {};
  const [listOfWorkspace, setListOfWorkspace] = useState<{
    [key: string]: boolean;
  }>({});
  const CustomerContract: { [key: string]: Array<any> } = {};
  const [workspaceIDsToShow, setWorkspaceIDsToShow] = useState<string[]>([]);
  const [copyJSON, setCopyJSON] = React.useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const Id = queryParams.get('id') ?? '';

  // Subscribe for data changes
  const error: IError | undefined = useSelector((state: any) => state?.modules?.Operations?.error);
  const customer: IIncidentModel<ICustomerSearchModel> | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.allresults,
  );
  const customerWorkspaceList: { results: Array<Object> } = useSelector(
    (state: any) => state?.modules?.Operations?.workspaceList,
  );
  const IdForCustomer: string = useSelector(
    (state: any) => state?.modules?.Operations?.idForCustomerAccess,
  );
  const accessibleCustomerIdList: Array<string> | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.accessibleCustomerIdList,
  );
  const accessibleWorkspaceIdList: Array<string> | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.accessibleWorkspaceIdList,
  );
  const msaWorkspaceIdList: Array<string> | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.msaWorkspaceIdList,
  );
  const addAccessToWorkspaceStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.addAccessToWorkspaceStatus,
  );
  const removeAccessFromWorkspaceStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.removeAccessFromWorkspaceStatus,
  );
  const addAccessToCustomerStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.addAccessToCustomerStatus,
  );
  const removeAccessFromCustomerStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.removeAccessFromCustomerStatus,
  );
  const addMsaToWorkspaceStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.addMsaToWorkspaceStatus,
  );
  const removeMsaFromWorkspaceStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.removeMsaFromWorkspaceStatus,
  );
  const addRemoveAccessId: string | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.id,
  );

  // Setup state variables
  const [panelData, setPanelData] = useState({});
  const [panelState, setPanelState] = React.useState<IPanelExampleState>({
    show: false,
    size: PanelSize.large,
    isLoading: false,
    fillBackground: false,
  });

  const [showDialog, setShowDialog] = React.useState<IDialogScopeState>({
    modal: false,
    toggleChecked: false,
    workspaceId: '',
  });
  const [wizardPanelOpen, setWizardPanelOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyID, setCompanyID] = useState('');

  // Setup event handlers
  function _onChange(ev: React.MouseEvent<HTMLElement>, checked: boolean, workspaceID: string) {
    if (checked) {
      dispatch(addAccessToCustomer(workspaceID, props.scope));
    } else {
      dispatch(removeAccessFromCustomer(workspaceID, props.scope));
    }
    customerColumns2[workspaceID] = checked;
    setListOfWorkspace({ ...listOfWorkspace, [workspaceID]: checked });
  }

  function _onChangeWorkspace(
    ev: React.MouseEvent<HTMLElement>,
    checked: boolean,
    workspaceID: string,
    //sco: IScope,
  ) {
    if (checked) {
      dispatch(addAccessToWorkspace(workspaceID, scope));
    } else {
      dispatch(removeAccessFromWorkspaces(workspaceID, scope));
    }
    workspaceColumns4[workspaceID] = checked;
  }

  function _onCancelMsaException() {
    let workspaceId = showDialog.workspaceId;
    let checked = showDialog.toggleChecked;
    logger.debug(`clicked on cancel ${workspaceId} checked:${checked}`);
  }

  function _onChangeMsaException() {
    let workspaceId = showDialog.workspaceId;
    let checked = showDialog.toggleChecked;
    logger.debug(
      `clicked on save:${workspaceId} Checked:${checked} Current:${workspaceColumns5[workspaceId]}`,
    );
    if (checked) {
      dispatch(addMsaToWorkspace(workspaceId, scope));
    } else {
      dispatch(removeMsaFromWorkspaces(workspaceId, scope));
    }
    workspaceColumns5[workspaceId] = checked;
  }

  const closeWizard = useCallback(() => {
    setWizardPanelOpen(false);
  }, []);

  function openPanel(
    data: any,
    size: PanelSize,
    fillBackground: boolean,
    setPanelState: React.Dispatch<React.SetStateAction<IPanelExampleState>>,
  ): void {
    setPanelData(data);
    setPanelState(state => ({
      ...state,
      show: true,
      size,
      fillBackground,
    }));
  }

  function moreDetailsPanel(column: ICustomerColumns, sco: IScope) {
    let name = column['COMPANY NAME'];
    let id = column.Id;
    let workspaceIds = workspaceList[id];
    dispatch(getCustomerWorkspaces(name, id, workspaceIds, scope));
    setWorkspaceIDsToShow(workspaceIds);
    setWizardPanelOpen(true);
  }

  // Setup columns for use in tabs
  function getColumns(): IElxColumn[] {
    return [
      {
        key: '2',
        name: 'Workspace ID',
        fieldName: 'WorkspaceID',
        minWidth: 100,
        maxWidth: 250,
        isResizable: false,
        isMultiline: true,
        onRender: item => {
          return (
            <Link
              href={`${'Workspace'}?id=${item.WorkspaceID}`}
              target="_blank"
              rel="noreferrer"
              style={{
                color:
                  theme === 'darkTheme'
                    ? darkTheme.semanticColors.link
                    : lightTheme.semanticColors.link,
                fontSize: 14,
                fontWeight: 600,
              }}>
              {item.WorkspaceID}
            </Link>
          );
        },
      },
      {
        key: '3',
        name: 'Workspace Name',
        fieldName: 'Workspace Name',
        minWidth: 150,
        //maxWidth: 800,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '4',
        name: 'Company Name',
        fieldName: 'Company Name',
        minWidth: 150,
        //maxWidth: 70,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '5',
        name: 'TPID',
        fieldName: 'TPID',
        minWidth: 70,
        //maxWidth: 70,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '6',
        name: 'Access',
        minWidth: 70,
        //maxWidth: 800,
        isResizable: true,
        isMultiline: true,
        onRender: item => {
          return (
            <Toggle
              inlineLabel
              checked={
                workspaceColumns4[item.WorkspaceID] ||
                accessibleWorkspaceIdList?.includes(item.WorkspaceID)
              }
              onChange={(ev, checked?: boolean) =>
                _onChangeWorkspace(ev, checked ?? false, item.WorkspaceID)
              }
            />
          );
        },
      },
      {
        key: '7',
        name: 'MSA',
        minWidth: 70,
        isResizable: true,
        isMultiline: true,
        onRender: item => {
          return (
            <Toggle
              checked={
                workspaceColumns5[item.WorkspaceID] ||
                msaWorkspaceIdList?.includes(item.WorkspaceID)
              }
              onText="Y"
              offText="N"
              onChange={(ev, checked?: boolean) => {
                setShowDialog({
                  modal: true,
                  toggleChecked: checked ?? false,
                  workspaceId: item.WorkspaceID,
                });
              }}
            />
          );
        },
      },
      {
        key: '8',
        name: 'View',
        minWidth: 70,
        //maxWidth: 800,
        isResizable: true,
        isMultiline: true,
        onRender: item => {
          return (
            <Link
              href={`${props.servicesHubUrl}/databoard/${item.WorkspaceID}`}
              target="_blank"
              underline
              tabIndex={-1}
              disabled={
                !workspaceColumns4[item.WorkspaceID] &&
                !accessibleWorkspaceIdList?.includes(item.WorkspaceID)
              }
              style={{
                fontSize: 15,
                fontWeight: 600,
                textDecoration: 'none',
              }}>
              View
            </Link>
          );
        },
      },
    ];
  }

  // Setup tabs
  const searchBoxProps = {
    ...props.searchProps,
  };

  // Search for requested customer
  useEffect(() => {
    if (!isNullOrWhitespace(Id)) {
      dispatch(getSearchResults(Id, props.OpsType, props.scope));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Do something with any returned data

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />,
            },
            props.scope,
          ),
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.',
            },
            props.scope,
          ),
        );
      }
    }

    // Display message if one of the add/remove access actions was successful
    else if (addAccessToWorkspaceStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: `You have been granted access to Workspace ${addRemoveAccessId}.`,
          },
          scope,
        ),
      );
    } else if (removeAccessFromWorkspaceStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: `Your access to Workspace ${addRemoveAccessId} has been revoked.`,
          },
          scope,
        ),
      );
    } else if (addAccessToCustomerStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: `You have been granted access to Customer ${addRemoveAccessId}.`,
          },
          props.scope,
        ),
      );
    } else if (removeAccessFromCustomerStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: `Your access to Customer ${addRemoveAccessId} has been revoked.`,
          },
          props.scope,
        ),
      );
    } else if (addMsaToWorkspaceStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: `You successfully added an MSA exception for ${addRemoveAccessId}.`,
          },
          scope,
        ),
      );
    } else if (removeMsaFromWorkspaceStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: `You successfully removed MSA exception from ${addRemoveAccessId}.`,
          },
          scope,
        ),
      );
    }
    // Otherwise, clear any previous message
    else {
      dispatch(uxClearContainerMessageAction(scope));
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there is no customer data to display
  if (
    customer === undefined ||
    isEqual(customer, {}) ||
    isEmpty(customer.results) ||
    !customer.results.some((r: any) => r.documentType === props.OpsType.toLowerCase())
  ) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO RESULTS TO DISPLAY. START SEARCHING !{' '}
        </b>
      </div>
    );
  }

  // Otherwise, display customer data
  else {
    if (customer?.results) {
      // eslint-disable-next-line array-callback-return
      customer.results?.map((x: ICustomerSearchModel) => {
        const updatedDate = x?.item.LastUpdated?.Date ?? '';
        const LastUpdated = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(new Date(updatedDate));

        let CreatedDate = 'UNK';
        if (x?.item.Created?.Date != null) {
          CreatedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          }).format(new Date(x?.item.Created?.Date));
        }

        const customerJSON: any = {
          Id: x?.item?.Id ?? 'N/A',
          'COMPANY NAME': x?.item.Name?.Value ?? 'N/A',
          'CREATED DATE': CreatedDate ?? 'N/A',
          'LAST UPDATED': LastUpdated ?? 'N/A',
        };

        const showCustomer = {
          Customer: (x?.item as any) ?? 'N/A',
        };
        const contractDetails: Array<any> = [];

        const workspaceIdsList: Array<any> = [];
        // eslint-disable-next-line array-callback-return
        x.item?.ContractDetails?.map((contract: any, index: number) => {
          contractDetails.push(contract);
        });
        const workspaceIds: Array<any> = [];
        // eslint-disable-next-line array-callback-return
        x.item?.WorkspaceIds?.map((id: any, index: number) => {
          workspaceIds.push(id);
          workspaceIdsList.push(id.Value);
        });
        customerWorkspaceID[x.item.Id] = workspaceIds;
        customerColumns.push(customerJSON);
        workspaceList[x.item.Id] = workspaceIdsList;
        customerColumns2[x.item.Id] = x?.hasAccess;
        CustomerContract[x.item.Id] = contractDetails;
        customerColumns3[x.item.Id] = showCustomer;
      });
      // eslint-disable-next-line array-callback-return
      customerWorkspaceList?.results?.map((x: any) => {
        const customerWorkspaceListJSON: any = {
          WorkspaceID: x?.item?.Id ?? 'N/A',
          'Workspace Name': x?.item?.WorkspaceName ?? 'N/A',
          'Company Name': x?.item?.CompanyName ?? 'N/A',
          TPID: x?.item?.TPID ?? 'N/A',
        };
        workspaceColumns4[x.item.Id] = x?.hasAccess;
        workspaceColumns5[x.item.Id] = x?.hasMsa;
        workspaceIDsToShow.includes(x?.item?.Id) &&
          customerWorkspaceColumns.push(customerWorkspaceListJSON);
      });
    }

    var JSONPrettyMon = require('react-json-pretty/dist/monikai');
    return (
      <div style={{ padding: 20 }}>
        <ElxPanel
          headerText="CUSTOMER JSON"
          isOpen={panelState.show}
          size={panelState.size}
          fillBackground={panelState.fillBackground}
          message={panelState.message}
          isLoading={panelState.isLoading}
          onDismiss={() => setPanelState({ ...panelState, show: false })}>
          <div className="contract-modal">
            <ElxCopy
              textToCopy={JSON.stringify(panelData, null, 2)}
              onClick={() => setCopyJSON(copyJSON)}>
              <ElxIconButton text="Copy" iconProps={{ iconName: 'Info' }} />
            </ElxCopy>
            <JSONPretty data={panelData} theme={JSONPrettyMon} style={{ padding: 10 }}></JSONPretty>
          </div>
        </ElxPanel>
        <ElxPanel
          isOpen={wizardPanelOpen}
          size={PanelSize.large}
          headerText={`Customer Name: ${companyName}`}
          onRenderSubHeader={() => <Stack.Item>{`Company ID: ${companyID}`}</Stack.Item>}
          onDismiss={closeWizard}>
          <ElxScopedContainer scope={scope}>
            <ElxTableContainer
              containerProps={{
                headerText: 'WORKSPACE DETAILS',
                fillBackground: false,
                compact: false,
              }}
              tableProps={{
                compact: false,
                columns: getColumns(),
                items: customerWorkspaceColumns,
                selectionMode: SelectionMode.none,
              }}
              searchBoxProps={searchBoxProps}
            />
            <ElxDialog
              hidden={!showDialog.modal}
              dismissable={false}
              minWidth={600}
              primaryButtonProps={{
                text: 'Continue',
                onClick: () => _onChangeMsaException(),
              }}
              cancelButtonProps={{
                text: 'Cancel',
                onClick: () => _onCancelMsaException(),
              }}
              onDismiss={() =>
                setShowDialog({
                  modal: false,
                  toggleChecked: false,
                  workspaceId: '',
                })
              }>
              <div>{Resources?.Users?.AllowMicrosoftAccountsTooltip1 ?? 'test'}</div>
              <div>&nbsp;</div>
              <div>{Resources?.Users?.AllowMicrosoftAccountsTooltip2 ?? 'test'}</div>
              <div>&nbsp;</div>
              <div>{Resources?.Users?.AllowMicrosoftAccountsTooltip3 ?? 'test'}</div>
              <div>&nbsp;</div>
              <div>{Resources?.Users?.AllowMicrosoftAccountsTooltip4 ?? 'test'}</div>
              <div>&nbsp;</div>
              <div>
                <ul>
                  <li>{Resources?.Users?.AllowMicrosoftAccountsListItem1 ?? 'test'}</li>
                  <li>{Resources?.Users?.AllowMicrosoftAccountsListItem2 ?? 'test'}</li>
                </ul>
              </div>
            </ElxDialog>
          </ElxScopedContainer>
        </ElxPanel>

        {customerColumns.map((column: ICustomerColumns, index: number) => {
          return (
            <div key={`${column.Id}${index}`}>
              <DocumentCard className="sub-card-styles-packages">
                <Stack horizontal>
                  <Stack.Item styles={stackItemStyles}>
                    <DocumentCardTitle title="CUSTOMER" className="user-title" shouldTruncate />
                    <Toggle
                      inlineLabel
                      className="access-toggle"
                      onText="Access"
                      offText="No Access"
                      checked={
                        customerColumns2[column.Id] || accessibleCustomerIdList?.includes(column.Id)
                      }
                      onChange={(ev, checked?: boolean) =>
                        _onChange(ev, checked ?? false, column.Id)
                      }
                    />
                  </Stack.Item>
                  <Stack.Item align="start" styles={stackItemStyles2}>
                    <ElxSecondaryButton
                      text="SHOW WORKSPACE DETAILS"
                      title="json"
                      className="json-button"
                      tabIndex={0}
                      ariaDescription="Show workspace details table"
                      onClick={() => {
                        setCompanyName(column['COMPANY NAME']);
                        setCompanyID(column.Id);
                        moreDetailsPanel(column, scope);
                      }}
                      style={{ marginRight: 10 }}
                    />
                  </Stack.Item>
                  <Stack.Item align="start" styles={stackItemStyles2}>
                    <ElxSecondaryButton
                      text="SHOW CUSTOMER JSON"
                      title="json"
                      className="json-button"
                      tabIndex={0}
                      ariaDescription="Show Customer JSON Button"
                      onClick={() =>
                        openPanel(
                          customerColumns3[column['Id']],
                          PanelSize.large,
                          false,
                          setPanelState,
                        )
                      }
                    />
                  </Stack.Item>
                </Stack>

                <Link
                  href={`${props.servicesHubUrl}/customers/${IdForCustomer}/${column.Id}/?pageId=ManageContracts`}
                  target="_blank"
                  underline
                  disabled={
                    !customerColumns2[column.Id] && !accessibleCustomerIdList?.includes(column.Id)
                  }
                  tabIndex={-1}
                  style={{
                    fontSize: 15,
                    fontWeight: 600,
                    textDecoration: 'none',
                  }}>
                  {column.Id}
                </Link>
                <br />
                <br />

                <Stack horizontal tokens={cardContentStackTokens} wrap>
                  {Object.entries(column).map(([keys, value], index) => {
                    return keys !== 'Id' ? (
                      <Stack className={cardContentStackStyles} key={keys}>
                        <Stack.Item key={keys + index}>
                          <b>{keys}</b>: {value}
                        </Stack.Item>
                      </Stack>
                    ) : (
                      <div key={keys + index}></div>
                    );
                  })}
                  <br />
                  <br />
                </Stack>
                <Stack>
                  <DocumentCardTitle
                    className="customer-contract-title"
                    title="CONTRACT DETAILS"
                    shouldTruncate
                  />

                  {CustomerContract[column['Id']].map((value, index) => {
                    const link = `../contract/BraavosSearch?id=${value.ContractId.Value}`;
                    return (
                      <Stack
                        horizontal
                        tokens={cardContentStackTokens}
                        wrap
                        key={`${column['Id']} ${index}`}>
                        <Stack.Item className={cardContentStackStyles2}>
                          <b>INSTALLATION SITE ID:</b> {value.InstallationSiteId.Value}
                        </Stack.Item>
                        <Stack.Item className={cardContentStackStyles2}>
                          <b>CONTRACT ID: </b>
                          <a
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color:
                                theme === 'darkTheme'
                                  ? darkTheme.semanticColors.link
                                  : lightTheme.semanticColors.link,
                              fontSize: 14,
                              fontWeight: 600,
                            }}>
                            {value.ContractId.Value}
                          </a>
                        </Stack.Item>
                        <Stack.Item className={cardContentStackStyles2}>
                          <b>CONTRACT NUMBER:</b> {value.ContractNumber.Value}
                        </Stack.Item>
                        <Stack.Item className={cardContentStackStyles2}>
                          <b>CONTRACT NAME:</b> {value.ContractName.Value}
                        </Stack.Item>
                      </Stack>
                    );
                  })}
                </Stack>
                <br />
              </DocumentCard>
            </div>
          );
        })}
      </div>
    );
  }
};
export default Customer;
