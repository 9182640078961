import { Action } from '@reduxjs/toolkit';
import { IAnnouncement } from './types/variableTypes';

export const GET_ANNOUNCEMENTS_REQUESTED = 'GET_ANNOUNCEMENTS_REQUESTED';
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';
export const GET_ANNOUNCEMENTS_FAILURE = 'GET_ANNOUNCEMENTS_FAILURE';
export const SAVE_ANNOUNCEMENT_REQUESTED = 'SAVE_ANNOUNCEMENT_REQUESTED';
export const SAVE_ANNOUNCEMENT_SUCCESS = 'SAVE_ANNOUNCEMENT_SUCCESS';
export const SAVE_ANNOUNCEMENT_FAILURE = 'SAVE_ANNOUNCEMENT_FAILURE';
export const ANNOUNCEMENT_IMAGES_REQUESTED = 'ANNOUNCEMENT_IMAGES_REQUESTED';
export const ANNOUNCEMENT_IMAGES_SUCCESS = 'ANNOUNCEMENT_IMAGES_SUCCESS';
export const ANNOUNCEMENT_IMAGES_FAILURE = 'ANNOUNCEMENT_IMAGES_FAILURE';
export const ANNOUNCEMENT_IMAGE_READ_REQUESTED = 'ANNOUNCEMENT_IMAGE_READ_REQUESTED';
export const ANNOUNCEMENT_IMAGE_READ_SUCCESS = 'ANNOUNCEMENT_IMAGE_READ_SUCCESS';
export const ANNOUNCEMENT_IMAGE_READ_FAILURE = 'ANNOUNCEMENT_IMAGE_READ_FAILURE';
export const ADD_ANNOUNCEMENT_REQUESTED = 'ADD_ANNOUNCEMENT_REQUESTED';
export const ADD_ANNOUNCEMENT_SUCCESS = 'ADD_ANNOUNCEMENT_SUCCESS';
export const ADD_ANNOUNCEMENT_FAILURE = 'ADD_ANNOUNCEMENT_FAILURE';
export const DELETE_ANNOUNCEMENT_REQUESTED = 'DELETE_ANNOUNCEMENT_REQUESTED';
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS';
export const DELETE_ANNOUNCEMENT_FAILURE = 'DELETE_ANNOUNCEMENT_FAILURE';
export const APPROVE_ANNOUNCEMENTS_REQUESTED = 'APPROVE_ANNOUNCEMENTS_REQUESTED';
export const APPROVE_ANNOUNCEMENTS_SUCCESS = 'APPROVE_ANNOUNCEMENTS_SUCCESS';
export const APPROVE_ANNOUNCEMENTS_FAILURE = 'APPROVE_ANNOUNCEMENTS_FAILURE';
// not used
//export const GET_DESKTOP_IMAGE_URI_REQUESTED = 'GET_DESKTOP_IMAGE_URI_REQUESTED';
//export const GET_MOBILE_IMAGE_URI_REQUESTED = 'GET_MOBILE_IMAGE_URI_REQUESTED';
//export const GET_TABLET_IMAGE_URI_REQUESTED = 'GET_TABLET_IMAGE_URI_REQUESTED';
//export const PUT_DESKTOP_IMAGE_TO_BLOB = 'PUT_DESKTOP_IMAGE_TO_BLOB';
//export const PUT_MOBILE_IMAGE_TO_BLOB = 'PUT_MOBILE_IMAGE_TO_BLOB';
//export const PUT_TABLET_IMAGE_TO_BLOB = 'PUT_TABLET_IMAGE_TO_BLOB';

export interface IGetAnnouncementsPayload
  extends Action<typeof GET_ANNOUNCEMENTS_REQUESTED> {
};

export interface IGetAnnouncementsSuccess
  extends Action<typeof GET_ANNOUNCEMENTS_SUCCESS> {
  announcementList: Array<any>;
};

export interface ISaveAnnouncementPayload
  extends Action<typeof SAVE_ANNOUNCEMENT_REQUESTED> {
  announcement: IAnnouncement;
};

export interface IAddAnnouncementPayload
  extends Action<typeof ADD_ANNOUNCEMENT_REQUESTED> {
  newAnnouncement: IAnnouncement;
};

export interface IAddAnnouncementSuccess
  extends Action<typeof ADD_ANNOUNCEMENT_SUCCESS> {
  newAnnouncementDetail: Array<any>;
};

export interface IDeleteAnnouncementPayload
  extends Action<typeof DELETE_ANNOUNCEMENT_REQUESTED> {
  announcementId: string;
};

export interface IDeleteAnnouncementSuccess
  extends Action<typeof DELETE_ANNOUNCEMENT_SUCCESS> {
  announcementId: string;
};

export interface IApproveAnnouncementsPayload
  extends Action<typeof APPROVE_ANNOUNCEMENTS_REQUESTED> {
  announcementIdList: any;
};

export interface IAnnouncementImagePreviewPayload
  extends Action<typeof ANNOUNCEMENT_IMAGES_REQUESTED> {
  announcementId: Array<string>;
};

export interface IAnnouncementImagePreviewSuccess
  extends Action<typeof ANNOUNCEMENT_IMAGES_SUCCESS> {
  imageDetails: Array<any>;
};

export interface IAnnouncementImageReadPreviewPayload
  extends Action<typeof ANNOUNCEMENT_IMAGE_READ_REQUESTED> {
  imageName: string;
};

export interface IAnnouncementImageReadPreviewSuccess
  extends Action<typeof ANNOUNCEMENT_IMAGE_READ_SUCCESS> {
  imageURL: string;
};

export interface IAnnouncementColumn {
  [key: string]: string;
};

// NOT USED
//export interface IGetDesktopImagePayload extends Action<typeof GET_DESKTOP_IMAGE_URI_REQUESTED> {
//  image: File;
//  imageName: string;
//};
//
//export interface IGetMobileImagePayload extends Action<typeof GET_MOBILE_IMAGE_URI_REQUESTED> {
//  image: File;
//  imageName: string;
//};
//
//export interface IGetTabletImagePayload extends Action<typeof GET_TABLET_IMAGE_URI_REQUESTED> {
//  image: File;
//  imageName: string;
//};
//
//export interface IDesktopImageFile
//  extends Action<typeof PUT_DESKTOP_IMAGE_TO_BLOB> {
//  imageURL: string;
//  image: File;
//};
//
//export interface IMobileImageFile
//  extends Action<typeof PUT_MOBILE_IMAGE_TO_BLOB> {
//  imageURL: string;
//  image: File;
//};
//
//export interface ITabletImageFile
//  extends Action<typeof PUT_TABLET_IMAGE_TO_BLOB> {
//  imageURL: string;
//  image: File;
//};
