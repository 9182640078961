import { featuresModule } from './features'
import { settingsModule } from './settings'
import { scriptsModule } from './scripts'
import { contractModule } from './contracts'
import { operationsModule } from './operations'
import { homeModule } from './home'
import { editAnnouncements } from './editAnnouncements'

export const sidebarModules = [
  homeModule,
  operationsModule,
  contractModule,
  scriptsModule,
  featuresModule,
  settingsModule,
  editAnnouncements,
]
