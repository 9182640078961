import * as type from './types'
import { IAnnouncement } from './types/variableTypes'
import { IError } from '../../utility/errorHandling'

const initialState = {
  announcementList: [],
  imagePreviewResult: [],
  announcementId: '',
  loading: false,
  error: null,
}

export default function announcement(
  state = initialState,
  action: {
    type: any
    announcementList: Array<any>
    announcementId: string
    loading: boolean,
    error: IError
  },
) {
  switch (action.type) {
    // Get announcements
    case type.GET_ANNOUNCEMENTS_REQUESTED:
      return {
        ...state,
        loading: true,
        announcementList: [],
      }
    case type.GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        announcementList: ((action as unknown) as type.IGetAnnouncementsSuccess)
          .announcementList,
        error: initialState.error,
      }
    case type.GET_ANNOUNCEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        announcementList: initialState.announcementList,
      };

    // Save announcement
    case type.SAVE_ANNOUNCEMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.SAVE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: initialState.error,
      }
    case type.SAVE_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // Preview announcement images
    case type.ANNOUNCEMENT_IMAGES_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.ANNOUNCEMENT_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        imageDetails: ((action as unknown) as type.IAnnouncementImagePreviewSuccess)
          .imageDetails,
        imagePreviewResult: ((action as unknown) as type.IAnnouncementImagePreviewSuccess)
          .imageDetails,
        error: initialState.error,
      }
    case type.ANNOUNCEMENT_IMAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // Announcemenrt image read
    case type.ANNOUNCEMENT_IMAGE_READ_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.ANNOUNCEMENT_IMAGE_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        imageURL: ((action as unknown) as type.IAnnouncementImageReadPreviewSuccess)
          .imageURL,
        error: initialState.error,
      }
    case type.ANNOUNCEMENT_IMAGE_READ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // Add announcement
    case type.ADD_ANNOUNCEMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.ADD_ANNOUNCEMENT_SUCCESS: {
      const newAnnouncementDetail = ((action as unknown) as type.IAddAnnouncementSuccess).newAnnouncementDetail;
      const newAnnouncementsList = (state.announcementList as IAnnouncement[]).concat(
        newAnnouncementDetail as IAnnouncement[],
      )
      return {
        ...state,
        loading: false,
        announcementList: newAnnouncementsList,
        error: initialState.error,
      }
    }
    case type.ADD_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // Delete announcement
    case type.DELETE_ANNOUNCEMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.DELETE_ANNOUNCEMENT_SUCCESS: {
      const announcementId = ((action as unknown) as type.IDeleteAnnouncementSuccess).announcementId;
      const newAnnouncementsList = state.announcementList.filter(
        (x) => (x as IAnnouncement).rowKey !== announcementId
      );
      return {
        ...state,
        loading: false,
        announcementList: newAnnouncementsList,
        error: initialState.error,
      }
    }
    case type.DELETE_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // Approve announcements
    case type.APPROVE_ANNOUNCEMENTS_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case type.APPROVE_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: initialState.error,
      }
    case type.APPROVE_ANNOUNCEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state
  }
}
