import { IModule } from "@elixir/fx";
import settingReducers from "../components/settings/store/reducers";
import { SettingSagas } from "../components/settings/store/sagas";
import Settings from "../components/settings/settings/settings";

export const settingsModule: IModule = {
  name: "Settings",
  env: "development",
  reducer: settingReducers,
  saga: SettingSagas,
  route: {
    index: "Settings",
    name: "Settings",
    iconProps: {
      iconName: "Settings",
			styles: {
			  root: {
				color: '#127122'
			  }
			}
    },
    path: "/settings",
    isRouteable: true,
    exact: true,
    inSidebar: true,
    component: Settings,
  },
};
