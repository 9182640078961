import { IModule } from '@elixir/fx';
import scriptReducers from '../components/scripts/store/reducers';
import { ScriptSagas } from '../components/scripts/store/sagas';
import Scripts from '../components/scripts/scripts/ScriptDetails';

export const scriptsModule: IModule = {
	name: 'Scripts',
	env: 'development',
	reducer: scriptReducers,
	saga: ScriptSagas,
	route: {
		index: 'Scripts',
		name: 'Scripts',
		iconProps: {
			iconName: "Script",
			styles: {
			  root: {
				color: '#7f2c8c'
			  }
			}
		},
		path: '/script',
		isRouteable: true,
		exact: true,
		inSidebar: true,
		component: Scripts,
	},
};
