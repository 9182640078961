import * as type from './types';
import { IScope } from '@elixir/fx';

export function getScripts(scope: IScope) {
	return {
		type: type.GET_SCRIPTS_REQUESTED,
		scope,
		payload: {
			scope,
		},
	};
}

export function runScript(
	scope: IScope,
	scriptName: string,
	scriptJson: string,
) {
	return {
		type: type.RUN_SCRIPT_REQUESTED,
		scriptName,
		scriptJson,
		scope,
		payload: {
			scriptName,
			scriptJson,
			scope,
		}
	};
}

export function cancelScript(scriptName: string, scope: IScope) {
	return {
		type: type.CANCEL_SCRIPT_REQUESTED,
		scriptName,
		scope,
		payload: {
			scriptName,
			scope,
		}
	};
}

export function getTraces(
	scriptName: string,
	startDate: string,
	endDate: string,
	scope: IScope
) {
	return {
		type: type.GET_TRACES_REQUESTED,
		scriptName,
		startDate,
		endDate,
		scope,
		payload: {
			scriptName,
			startDate,
			endDate,
			scope,
		},
	};
}
