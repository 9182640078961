import * as ReactDOM from 'react-dom';
import { setConfig, IElxConfig, LoginType } from '@elixir/fx';
import { getConfig, IConfig } from './OpsConfig';
import { OpsSite } from './app';

getConfig().then((config: IConfig) => {

  const operationCenterConfig: Partial<IElxConfig> = {
    authentication: config.authentication,
    applicationTitle: config.applicationTitle,
    applicationIconName: config.applicationIconName,
    baseUrl: config.baseUrl,
    tenantId: config.tenantId,
    restrictExternalUsers: config.restrictExternalUsers,
    clientId: config.clientId,
    //loginType: config.loginType,
    loginType: LoginType.Redirect,
    scopes: config.scopes,
    // telemetry: {
    //   trackAuthenticatedId: true,
    //   trackInteraction: true,
    //   settings: {
    //     config: {
    //       instrumentationKey: '282fafb6-8eef-46ba-a2e0-0a09b56dbba2',
    //     },
    //   },
    // },
  };

  setConfig(operationCenterConfig);

  ReactDOM.render(<OpsSite />, document.getElementById('root'));
});
