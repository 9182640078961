import { Http, LoginType } from '@elixir/fx';
import { AuthMode } from '@elixir/fx/lib/framework/shell/AuthModule';

export interface IConfig {
  authentication: AuthMode;
  applicationTitle: string;
  applicationIconName: string;
  baseUrl: string;
  tenantId: string;
  restrictExternalUsers: boolean;
  clientId: string;
  loginType: LoginType.Redirect;
  scopes: string[];
  apiBaseURL: string;
  servicesHubUrl: string;
  isFederal: boolean;
}

async function getConfigInternal(): Promise<IConfig> {
  const staticConfig: IConfig = {
    authentication: (process.env.REACT_APP_AUTHENTICATION as AuthMode) ?? ('aad' as AuthMode),
    applicationTitle: process.env.REACT_APP_APPLICATION_TITLE ?? '',
    applicationIconName: process.env.REACT_APP_APPLICATION_ICON_NAME ?? '',
    baseUrl: process.env.REACT_APP_BASE_URL ?? globalThis.location.origin,
    tenantId: process.env.REACT_APP_TENANT_ID ?? '',
    restrictExternalUsers: process.env.REACT_APP_RESTRICT_EXTERNAL_USERS === 'true' ?? '',
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    loginType: process.env.REACT_APP_LOGIN_TYPE === 'Popup' ? 0 : 1,
    scopes: [process.env.REACT_APP_SCOPE ?? ''],
    apiBaseURL: process.env.REACT_APP_API_BASE_URL ?? '',
    servicesHubUrl: process.env.REACT_APP_HUB_URL ?? '',
    isFederal: process.env.REACT_APP_IS_FEDERAL === 'true',
  };

  const dynamicResp = await Http.get<IConfig>({}, staticConfig.apiBaseURL + '/config');

  return {
    ...staticConfig,
    ...dynamicResp.data,
  };
}

const configResponse: Promise<IConfig> = getConfigInternal();

export function getConfig(): Promise<IConfig> {
  return configResponse;
}
