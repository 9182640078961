import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  ContainerNavigationItem,
  ElxScopedContainer,
  ElxSearchBox,
  IElxSidebarItemProps,
  ISearchCriteria,
  ResponsiveMode,
  SearchBoxSize,
  useResponsiveContext,
  useScope,
  MessageBarType,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
  uxSetSidebarDefaultCollapsedAction,
} from '@elixir/fx';
import FeatureDetails from './featureDetailsElxir';
import { getFeatures } from '../store/action';
import '../styling/css/style.css';
import EmptyData from '../styling/images/EmptyData.svg';
import { ErrorType, IError } from '../../utility/errorHandling';
import { IFeature, IFeatureModel } from '../store/types/variabletypes';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';

interface NavigationItem extends ContainerNavigationItem {
  featureList: Array<object>;
}

export const Features = () => {
  const scope = useScope();
  const dispatch = useDispatch();
  const { responsiveMode } = useResponsiveContext();

  // Subscribe for data changes
  const error: IError | undefined = useSelector((state: any) => state?.modules?.Features?.error);
  const featureList: Array<IFeatureModel> = useSelector(
    (state: any) => state?.modules?.Features?.featureList,
  );

  let navigationItems: {
    key: any;
    text: any;
    userId: any;
    currentWorkspaceId: any;
    iconProps: any;
    featureList: Array<IFeature>;
    count: any;
  }[] = [];

  // Setup state variables

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navigationPaneCollapsed, setNavigationPaneCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');
  const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
    keyword: '',
    filters: {},
  });

  // Request all features
  useEffect(() => {
    dispatch(getFeatures(scope));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (featureList !== undefined && !isEmpty(featureList)) {
    // eslint-disable-next-line array-callback-return
    navigationItems = featureList
      ?.map((x: IFeatureModel) => {
        const featureJSON = {
          key: x?.distinctServiceName.toUpperCase(),
          text: x?.distinctServiceName.toUpperCase() + ' (' + x.featureList.length + ')',
          userId: x?.userId,
          currentWorkspaceId: x?.currentWorkspaceId,
          featureList: x?.featureList,
          iconProps: { iconName: 'ArrangeSendToBack' },
          count: x.featureList.length,
        };
        return featureJSON;
      })
      .filter(navItem => {
        if (!searchCriteria.keyword) {
          return true;
        }
        navItem.featureList = navItem.featureList.filter(feature =>
          feature.Name.toLowerCase().includes(searchCriteria.keyword.trim().toLowerCase()),
        );
        navItem.text = navItem.key + '(' + navItem.featureList.length + ')';
        return navItem.featureList.length > 0;
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigationItem = useMemo(() => {
    //const emptyIconProps = { iconName: '' };
    return navigationItems.sort((a, b) => {
      return b.count - a.count;
    }) as NavigationItem[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationPaneCollapsed]);

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />,
            },
            scope,
          ),
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.',
            },
            scope,
          ),
        );
      }
    } else {
      // Clear any existing error message
      dispatch(uxClearContainerMessageAction(scope));
    }
  });

  // Check if there are no features
  if (featureList === undefined) {
    return (
      <div className="default-contract">
        <img src={EmptyData} alt="BigCo Inc. logo" style={{}} />
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO RESULTS TO DISPLAY. SELECT A FEATURE !{' '}
        </b>
      </div>
    );
  }

  // Otherwise, display feature list
  else {
    return (
      <ElxScopedContainer
        scope={scope}
        headerText="FEATURES"
        subActions={[
          <ElxSearchBox
            size={SearchBoxSize.Medium}
            onChange={setSearchCriteria}
            placeholder="Search"
            key={1}
          />,
        ]}
        navigationProps={{
          items: navigationItems.sort((a, b) => {
            return b.count - a.count;
          }),
          selectedKey: navigationItems.length > 0 ? selectedKey : '',
          onItemSelect: (_, item: IElxSidebarItemProps) => {
            setSelectedKey(item.key);
            dispatch(uxSetSidebarDefaultCollapsedAction(true));
          },
        }}
        styles={{
          subHeader: {
            display: 'none',
          },
          subActions: { display: 'flex' },
          body: {
            ...(responsiveMode > ResponsiveMode.Large && {
              padding: 0,
            }),
          },
        }}>
        <FeatureDetails
          scope={scope}
          selectedKey={selectedKey}
          userId={navigationItems.find(obj => obj.key === selectedKey)?.userId}
          currentWorkspaceId={
            navigationItems.find(obj => obj.key === selectedKey)?.currentWorkspaceId
          }
          featureList={navigationItems.find(obj => obj.key === selectedKey)?.featureList ?? []}
        />
      </ElxScopedContainer>
    );
  }
};
export default Features;
