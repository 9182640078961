import { ElxScopedContainer, useScope } from '@elixir/fx';
import Scripts from './scripts';

export const ScriptDetails = () => {
	const scope = useScope();
	return (
		<ElxScopedContainer scope={scope}>
			<Scripts scope={scope} />
		</ElxScopedContainer>
	);
};
export default ScriptDetails;
