import '../../../styling/css/style.css'
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import IIncidentModel from '../store/types/variabletypes'
import { IStackTokens, mergeStyles, IStackItemStyles } from '@fluentui/react'
import { isEmpty, isEqual } from 'lodash'
import {
  DocumentCard,
  DocumentCardTitle,
} from 'office-ui-fabric-react/lib/DocumentCard'
import { Stack } from '@fluentui/react'
import PremierEntitlements from './PremierEntitlements'
import CommercialEntitlements from './CommercialEntitlements'
import ShowRoles from './ShowRoles'
import JSONPretty from 'react-json-pretty'
import { useLocation } from 'react-router'
import {
  ElxPanel,
  ElxSecondaryButton,
  ElxMasterDetailPanel,
  ILogger,
  IMessage,
  IScope,
  isNullOrWhitespace,
  MessageBarType,
  PanelSize,
  ElxScopedContainer,
  getLogger,
  IElxMasterDetailTabProps,
  ElxTabControlOrientation,
  useScope,
  ElxCopy,
  ElxIconButton,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx'
import {
  clearLOD,
  getSearchResults,
  getEmailVerification,
} from '../store/action'
import { ResultStatus, ErrorType, IError } from '../../utility/errorHandling';
import { IUsersColumns } from '../store/types'
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';

interface IPanelExampleState {
  show: boolean
  size: PanelSize
  message?: IMessage
  isLoading: boolean
  fillBackground: boolean
}

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 5,
    cursor: 'Pointer',
    flex: 1,
  },
}
export const cardContentStackStyles = mergeStyles({
  width: 300,
})
export const cardContentStackTokens: IStackTokens = {
  childrenGap: 'l1',
}

export const Users = (props: { scope: IScope; OpsType: string }) => {
  const logger = getLogger()
  const scope = useScope()
  const usersColumns: IUsersColumns[] = []
  const usersColumns2: { [key: string]: Array<any> } = {}
  const usersColumns3: { [key: string]: IUsersColumns } = {}
  const [copyJSON, setCopyJSON] = React.useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const Id = queryParams.get('id') ?? ''
  const dispatch = useDispatch()

  // Subscribe for data changes
  const clearLodStatus: ResultStatus = useSelector(
    (state: any) => state?.modules?.Operations?.clearLodStatus
  );
  const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.error
  );
  const users: IIncidentModel<Object> | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.allresults,
  );
  const email: IIncidentModel<Object> | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.emailJson,
  );

  // Setup state variables
  const [wizardPanelOpen, setWizardPanelOpen] = useState(false)
  const [userIdforEntitlement, setUserIdforEntitlement] = useState('')

  const [defaultIndex, setDefaultIndex] = useState(0);

  const [panelData, setPanelData] = useState('');
  const [panelState, setPanelState] = useState<IPanelExampleState>({
    show: false,
    size: PanelSize.small,
    isLoading: false,
    fillBackground: false,
  });
  const { show, size, isLoading, fillBackground, message } = panelState;

  const [panelState2, setPanelState2] = useState<IPanelExampleState>({
    show: false,
    size: PanelSize.small,
    isLoading: false,
    fillBackground: false,
  });

  const [panelState3, setPanelState3] = useState<IPanelExampleState>({
    show: false,
    size: PanelSize.small,
    isLoading: false,
    fillBackground: false,
  });

  // Setup event handlers
  const closeWizard = useCallback(() => {
    setWizardPanelOpen(false)
    setDefaultIndex(0)
  }, []);

  const tabChange = (tabIndex: number, tab: IElxMasterDetailTabProps) => {
    logger.debug(
      'Example: Update coming from the masterdetail panel current Tab',
      tabIndex,
      tab,
    )
    setDefaultIndex(tabIndex)
    logger.debug(defaultIndex)
  };

  function entitlementsPanel(id: string) {
    setUserIdforEntitlement(id)
    setWizardPanelOpen(true)
  }

  function openPanel2(
    id: string,
    size: PanelSize,
    fillBackground: boolean,
    setPanelState: React.Dispatch<React.SetStateAction<IPanelExampleState>>,
  ): void {
    setUserIdforEntitlement(id)
    setPanelState((state) => ({
      ...state,
      show: true,
      size,
      isLoading: false,
      fillBackground,
    }))
  }

  function openPanel3(
    size: PanelSize,
    fillBackground: boolean,
    setPanelState: React.Dispatch<React.SetStateAction<IPanelExampleState>>,
  ): void {
    setPanelState2((state) => ({
      ...state,
      show: true,
      size,
      isLoading: false,
      fillBackground,
    }))
  }

  function openPanel4(
    data: any,
    size: PanelSize,
    fillBackground: boolean,
    setPanelState: React.Dispatch<React.SetStateAction<IPanelExampleState>>,
  ): void {
    setPanelData(data)
    setPanelState3((state) => ({
      ...state,
      show: true,
      size,
      isLoading: false,
      fillBackground,
    }))
  }

  // Setup tabs
  const getTabs = (
    logger: ILogger,
    setDefaultIndex: (defaultIndex: number) => void,
  ): IElxMasterDetailTabProps[] => {
    const tabs: IElxMasterDetailTabProps[] = [
      {
        key: '1',
        name: 'PREMIER ENTITLEMENTS',
        component: (
          <div>
            <PremierEntitlements UserID={userIdforEntitlement} scope={scope} />
          </div>
        ),
        primaryButton: {},
        cancelButton: { text: 'Close' },
      },
      {
        key: '2',
        name: 'COMMERCIAL ENTITLEMENTS',
        component: (
          <div>
            <CommercialEntitlements
              UserID={userIdforEntitlement}
              scope={scope}
            />
          </div>
        ),
        primaryButton: {},
        cancelButton: { text: 'Close' },
      },
    ]
    return tabs
  };

  const tabs = getTabs(logger, setDefaultIndex);

  // Search for requested users
  useEffect(() => {
    if (!isNullOrWhitespace(Id)) {
      dispatch(getSearchResults(Id, props.OpsType, props.scope))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: (
                <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
              )
            },
            props.scope)
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.'
            },
            props.scope)
        );
      }
    }

    // Check for success status from clearLOD
    else if (clearLodStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: 'LOD external ids were removed successfully. It can take up 10 minutes for search results to refresh.'
          },
          props.scope)
      );
    }

    // Otherwise, clear any previous message
    else {
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there is no user data to display
  if (users === undefined || isEqual(users, {}) || isEmpty(users.results) ||
    !users.results.some((r: any) => r.documentType === props.OpsType.toLowerCase())) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO RESULTS TO DISPLAY. START SEARCHING !{' '}
        </b>
      </div>
    )
  } 
  
  // Otherwise, display user data
  else {
    if (users?.results) {
      // eslint-disable-next-line array-callback-return
      users.results?.map((x: any) => {
        const usersJSON = {
          ID: x.item.Id ?? 'N/A',
          'FIRST NAME': x.item?.FirstName ?? 'N/A',
          'LAST NAME': x.item?.LastName ?? 'N/A',
          EMAIL: x.item?.Email ?? 'N/A',
          'PREFERRED EMAIL': x.item?.PreferredEmail ?? 'N/A',
          PUID: x.item?.PUID ?? 'N/A',
          'ADO ID': x.item?.ADObjectIdentifier ?? 'N/A',
        }
        const showUser = {
          User: x?.item ?? 'N/A',
        }
        const LODJSON: Array<any> = []
        // eslint-disable-next-line array-callback-return
        x.item?.ExternalSystemIds?.map((lod: any, index: number) => {
          LODJSON.push(lod)
        })
        usersColumns.push(usersJSON)
        usersColumns2[x.item.Id] = LODJSON
        usersColumns3[x.item.Id] = showUser
      })
    }
    var JSONPrettyMon = require('react-json-pretty/dist/monikai')
    return (
      <div style={{ padding: 20 }}>
        <ElxPanel
          headerText="USER JSON"
          isOpen={panelState3.show}
          size={panelState3.size}
          fillBackground={panelState3.fillBackground}
          message={panelState3.message}
          isLoading={panelState3.isLoading}
          onDismiss={() => setPanelState3({ ...panelState, show: false })}
        >
          <div className="contract-modal">
            <ElxCopy
              textToCopy={JSON.stringify(panelData, null, 2)}
              onClick={() => setCopyJSON(copyJSON)}
            >
              <ElxIconButton text="Copy" iconProps={{ iconName: 'Info' }} />
            </ElxCopy>
            <JSONPretty
              data={panelData}
              theme={JSONPrettyMon}
              style={{ padding: 10 }}
            ></JSONPretty>
          </div>
        </ElxPanel>
        <ElxMasterDetailPanel
          isOpen={wizardPanelOpen}
          wizard={true}
          selectedTabIndex={defaultIndex}
          headerText="ENTITLEMENTS"
          tabs={tabs}
          onDismiss={closeWizard}
          onTabChange={tabChange}
          tabsOrientation={ElxTabControlOrientation.Horizontal}
        />
        <ElxPanel
          headerText="ROLES & CLAIMS"
          isOpen={show}
          size={size}
          fillBackground={fillBackground}
          message={message}
          isLoading={isLoading}
          onDismiss={() => setPanelState({ ...panelState, show: false })}
        >
          <div style={{ padding: 10 }}>
            <ElxScopedContainer scope={scope}>
              <ShowRoles UserID={userIdforEntitlement} scope={scope} />
            </ElxScopedContainer>
          </div>
        </ElxPanel>
        <ElxPanel
          headerText="EMAIL VERIFICATION"
          isOpen={panelState2.show}
          size={panelState2.size}
          fillBackground={panelState2.fillBackground}
          message={panelState2.message}
          isLoading={panelState2.isLoading}
          onDismiss={() => setPanelState2({ ...panelState, show: false })}
        >
          <ElxScopedContainer scope={scope}>
            <div style={{ padding: 10 }}>
              {isEmpty(email?.results) ? (
                <div className="default-email-verification-card">
                  <b style={{ color: '#605E5C', letterSpacing: 2 }}>
                    THERE ARE NO RESULTS TO DISPLAY.
                  </b>
                </div>
              ) : (
                <JSONPretty
                  data={email}
                  theme={JSONPrettyMon}
                  style={{ padding: 10 }}
                ></JSONPretty>
              )}
            </div>
          </ElxScopedContainer>
        </ElxPanel>
        {usersColumns.map((column: IUsersColumns, index: number) => {
          return (
            <div key={`${column['ID']}${index}`}>
              <DocumentCard className="sub-card-styles-packages">
                <Stack horizontal>
                  <Stack.Item align="start" styles={stackItemStyles}>
                    <DocumentCardTitle
                      title="USER"
                      className="user-title"
                      shouldTruncate
                    />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <ElxSecondaryButton
                      text="Show Entitlements"
                      onClick={() => entitlementsPanel(column.ID)}
                      tabIndex={0}
                      ariaDescription="Show entitlements"
                      style={{ marginRight: 10 }}
                    />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <ElxSecondaryButton
                      text="Show Roles"
                      onClick={() =>
                        openPanel2(
                          column.ID,
                          PanelSize.large,
                          false,
                          setPanelState,
                        )
                      }
                      tabIndex={0}
                      ariaDescription="Show user roles"
                      style={{ marginRight: 10 }}
                    />
                  </Stack.Item>

                  <Stack.Item align="end">
                    <ElxSecondaryButton
                      text="Email Verification"
                      onClick={() => {
                        dispatch(getEmailVerification(column.EMAIL, scope))
                        openPanel3(PanelSize.medium, false, setPanelState)
                      }}
                      tabIndex={0}
                      ariaDescription="Email Verification"
                      style={{ marginRight: 10 }}
                    />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <ElxSecondaryButton
                      text="SHOW USER JSON"
                      onClick={() => {
                        openPanel4(
                          usersColumns3[column['ID']],
                          PanelSize.large,
                          false,
                          setPanelState,
                        )
                      }}
                      tabIndex={0}
                      ariaDescription="Show user Json"
                    />
                  </Stack.Item>
                </Stack>
                <Stack horizontal tokens={cardContentStackTokens} wrap>
                  {Object.entries(column).map(([key, value]) => {
                    return (
                      <Stack className={cardContentStackStyles} key={key}>
                        <Stack.Item>
                          <b>{key}</b>: {value}
                        </Stack.Item>
                      </Stack>
                    )
                  })}
                </Stack>
                <br />
                <Stack>
                  <div>
                    <DocumentCardTitle
                      className="customer-contract-title"
                      title="External System Ids"
                      shouldTruncate
                    />
                    <Stack horizontal tokens={cardContentStackTokens} wrap>
                      {Object.keys(usersColumns2[column['ID']]).length > 0 ? (
                        <div>
                          <Stack
                            horizontal
                            tokens={cardContentStackTokens}
                            wrap
                          >
                            {usersColumns2[column['ID']].map((value, index) => {
                              return (
                                <Stack
                                  className={cardContentStackStyles}
                                  key={`${value.Name}${index}`}
                                >
                                  <Stack.Item>
                                    <b>{value.Name}: </b>
                                    {value.Value}
                                  </Stack.Item>
                                </Stack>
                              )
                            })}
                          </Stack>
                          <br />
                          <button
                            className="workspace-contract-panel-button"
                            onClick={() => dispatch(clearLOD(column['ID'], props.scope))}
                          >
                            CLEAR LOD ID
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </div>
                  <br />
                </Stack>
              </DocumentCard>
            </div>
          )
        })}
      </div>
    )
  }
}
export default Users
