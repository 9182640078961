/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { ElxActionButton, ElxTableContainer, IElxColumn } from '@elixir/fx';
import { Icon, SelectionMode } from 'office-ui-fabric-react';
import { IContractCheckerPayload, IContractCheckerTableRow, IValidationReportItem } from '../../../store/types';
import ValidatorDetails from './ValidatorDetails';

export const ContractValidatorTable = (props: { validator: IContractCheckerPayload }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 40;

  const onPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }
  const onNextPage = () => {
    const maxPage = props.validator.ValidationReportItems.length / itemsPerPage;
    if (currentPage < maxPage-1) {
      setCurrentPage(currentPage + 1);
    }
  }

  function getColumns(): IElxColumn[] {
    return [
      {
        key: '0',
        name: '',
        fieldName: 'index',
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
        onRender: item => {
          return item.index + 1;
        },
      },
      {
        key: '1',
        name: '',
        fieldName: 'Icon',
        minWidth: 20,
        maxWidth: 20,
        isIconOnly: true,
        isResizable: true,
        onRender: item => {
          let iconError = <Icon iconName='Error' style={{ color: '#FF0000', fontSize: '90' }} />;
          let iconWarning = <Icon iconName='Warning' style={{ color: '#AAAA00' }} />;
          let iconInformation = <Icon iconName={'Info'} style={{ color: '#00AA11' }} />;
          let iconResult = iconInformation;
          if (item.Icon === 'Error') {
            iconResult = iconError;
          }
          if (item.Icon === 'Warning') {
            iconResult = iconWarning;
          }
          return (iconResult);
        },
      },
      {
        key: '2',
        name: 'Severity',
        fieldName: 'Severity',
        minWidth: 50,
        maxWidth: 80,
        isResizable: true,
       },
      {
        key: '3',
        name: 'Type',
        fieldName: 'Type',
        minWidth: 20,
        maxWidth: 50,
        isResizable: true,
      },
      {
        key: '4',
        name: 'Id',
        fieldName: 'Id',
        minWidth: 20,
        maxWidth: 80,
        isResizable: true,
      },
      {
        key: '5',
        name: 'Condition',
        fieldName: 'Condition',
        minWidth: 160,
        maxWidth: 480,
        isResizable: true,
      },
      {
        key: '6',
        name: 'Action',
        fieldName: 'Action',
        minWidth: 130,
        maxWidth: 350,
        isResizable: true,
      },
      {
        key: '7',
        name: 'Who',
        fieldName: 'Who',
        minWidth: 50,
        maxWidth: 130,
        isResizable: true,
      }
    ]
  }

  function getTableData(reportItems: Array<IValidationReportItem>): Array<IContractCheckerTableRow> {
    return reportItems.sort((item1, item2) => item1.EntityType.localeCompare(item2.EntityType)).map((item: IValidationReportItem, index: any) => {
      var itemSeverity = item.Messages.find(m => m.Category === 'Severity')?.Message ?? 'Information';
      return {
        index,
        Icon: itemSeverity,
        Severity: itemSeverity,
        Id: item.EntityId,
        Type: item.EntityType,
        Action: item.Messages.find(m => m.Category === 'Action')?.Message ?? 'none',
        Condition: item.Messages.find(m => m.Category === 'Condition')?.Message ?? 'none',
        Topic: item.Messages.find(m => m.Category === 'Topic')?.Message ?? 'none',
        Who: item.Messages.find(m => m.Category === 'Who')?.Message ?? 'none',
      }
    });
  }

  const GetPaginationButtons = () => {
    if (props.validator.ValidationReportItems.length > itemsPerPage)
      return (
        <div>
          <ElxActionButton
            disabled={currentPage < 1}
            iconProps={{ iconName: 'Previous' }}
            id="agreement-validator-previous-page"
            onClick={onPreviousPage}
            text={"Previous"}
          />
          <ElxActionButton
            disabled={currentPage > props.validator.ValidationReportItems.length / itemsPerPage - 1 }
            iconProps={{ iconName: 'Next' }}
            id="agreement-validator-next-page"
            onClick={onNextPage}
            text={"Next"}
          />
        </div>
      );
  }

  return (
    <>
      <ValidatorDetails validator={props.validator} />
      {GetPaginationButtons()}
      <ElxTableContainer
        containerProps={{
          className: "contract-validation-table"
        }}
        tableProps={{
          compact: true,
          columns: getColumns(),
          items: getTableData(props.validator.ValidationReportItems),
          selectionMode: SelectionMode.none,
          listProps: {
            renderCount: itemsPerPage,
            getItemCountForPage: () => itemsPerPage,
            startIndex: currentPage * itemsPerPage
          },
        }}
      />
    </>
  );
}

export default ContractValidatorTable
