import * as type from './types';
import { IFeatureModel } from './types/variabletypes';
import { ResultStatus, IError } from '../../utility/errorHandling'

const initialState = {
	featureList: [],
	feature: null,
	loading: false,
	error: null,
	saveFeatureStatus: ResultStatus.Unknown,
};

export default function features(
	state = initialState,
	action: {
		type: any;
		featureList: IFeatureModel[],
		feature: IFeatureModel,
		loading: boolean,
		error: IError,
		saveFeatureStatus: ResultStatus,
	}
) {
	switch (action.type) {
		case type.GET_FEATURES_REQUESTED:
			return {
				...state,
				loading: true,
			};
		case type.GET_FEATURES_SUCCESS:
			return {
				...state,
				loading: false,
				featureList: ((action as unknown) as type.IGetFeaturesSuccess).featureList,
				saveFeatureStatus: initialState.saveFeatureStatus,
				error: initialState.error,
			};
		case type.GET_FEATURES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
				featureList: initialState.featureList,
				saveFeatureStatus: initialState.saveFeatureStatus,
			};
		case type.SAVE_FEATURE_REQUESTED:
			return {
				...state,
				loading: true,
				saveFeatureStatus: ResultStatus.Unknown,
			};
		case type.SAVE_FEATURE_SUCCESS:
			return {
				...state,
				loading: false,
				saveFeatureStatus: ResultStatus.Success,
				feature: ((action as unknown) as type.ISaveFeatureSuccess).feature,
				error: initialState.error,
			};
		case type.SAVE_FEATURE_FAILURE:
			return {
				...state,
				loading: false,
				saveFeatureStatus: ResultStatus.Failure,
				error: action.error,
				feature: state.feature
			};
		default:
			return state;
	}
}
