import * as type from './types';
import { ResultStatus, IError } from '../../utility/errorHandling';

const initialState = {
  // resultStatus: ResultStatus.Unknown,
  loading: false,
  contract: [],
  contractDetails: [],
  contacts: [],
  msContact: [],
  services: {},
  config: {},
  sendSccEventStatus: ResultStatus.Unknown,
  contractChecker: {},
  contractsByAlias: [],
  error: null,
};

export default function contract(
  state = initialState,
  action: {
    type: any;
    loading: boolean;
    contract: any;
    contractDetails: any;
    contacts: any;
    msContact: any;
    services: any;
    config: any;
    contractId: any,
    sendSccEventStatus: ResultStatus;
    contractChecker: any;
    contractsByAlias: any;
    error: IError;
  },
) {
  switch (action.type) {
    case type.CLEAR_STATE:
      return {
        ...state,
        contract: initialState.contract,
        contractDetails: initialState.contractDetails,
        contacts: initialState.contacts,
        msContact: initialState.msContact,
        services: initialState.services,
        sendSccEventStatus: ResultStatus.Unknown,
        contractChecker: initialState.contractChecker,
        contractsByAlias: initialState.contractsByAlias,
        error: initialState.error,
      };

    case type.GET_CONTRACT_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CONTRACT_SUCCESS:
      const contractSuccessAction = action as type.IContractSuccess;
      return {
        ...state,
        loading: false,
        contract: contractSuccessAction.contract,
        config: contractSuccessAction.config,
        contractId: contractSuccessAction.contractId,
        error: initialState.error,
      };
    case type.GET_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        contract: initialState.contract,
        contractId: null,
        config: initialState.config,
      };

    case type.GET_CONTRACT_DETAILS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CONTRACT_DETAILS_SUCCESS:
      const contractDetailsAction = action as type.IContractDetailsSuccess;
      return {
        ...state,
        loading: false,
        contractDetails: contractDetailsAction.contractDetails,
        config: contractDetailsAction.config,
        error: initialState.error,
      };
    case type.GET_CONTRACT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        contractDetails: initialState.contractDetails,
        config: initialState.config,
      };

    case type.GET_CONTACTS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: (action as type.IContactsSuccess).contacts,
        error: initialState.error,
      };
    case type.GET_CONTACTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        contacts: initialState.contacts,
      };

    case type.GET_MS_CONTACT_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_MS_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        msContact: (action as type.IMSContactSuccess).msContact,
        error: initialState.error,
      };
    case type.GET_MS_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        msContact: initialState.msContact,
      };

    case type.GET_SERVICES_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: (action as type.IServicesSuccess).services,
        error: initialState.error,
      };
    case type.GET_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        services: initialState.services,
      };

    case type.SEND_SCC_EVENT_REQUESTED:
      return {
        ...state,
        loading: true,
        sendSccEventStatus: ResultStatus.Unknown,
      };
    case type.SEND_SCC_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        sendSccEventStatus: ResultStatus.Success,
        error: initialState.error,
      };
    case type.SEND_SCC_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        sendSccEventStatus: ResultStatus.Failure,
        error: action.error,
      };

    case type.GET_CONTRACT_CHECKER_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CONTRACT_CHECKER_SUCCESS:
      return {
      ...state,
      loading: false,
      contractChecker: (action as type.IContractCheckerSuccess).contractChecker,
      error: initialState.error,
    };
    case type.GET_CONTRACT_CHECKER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        contractChecker: initialState.contractChecker
    };

    case type.GET_CONTRACT_BY_ALIAS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CONTRACT_BY_ALIAS_SUCCESS:
      const contractsByAliasAction = action as type.IContractByAliasSuccess;
      return {
        ...state,
        loading: false,
        contractsByAlias: contractsByAliasAction.contractsByAlias,
        config: contractsByAliasAction.config,
        error: initialState.error,
      };
    case type.GET_CONTRACT_BY_ALIAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        contractsByAlias: initialState.contractsByAlias,
        config: initialState.config,
      };

    default:
      return state;
  }
}
