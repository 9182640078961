import './styling/css/style.css';
import { Stack, Icon, IIconStyles } from '@fluentui/react';
import { DocumentCard } from 'office-ui-fabric-react/lib/DocumentCard';
import { ElxContainer } from '@elixir/fx';
import { DocumentCardTitle } from 'office-ui-fabric-react/lib/DocumentCard';
import { getCurrentThemeFromStorage } from './app';

export const HomePage = () => {
  const ScriptStyles: IIconStyles = { root: { color: 'var(--scripts-color)' } };
  const SearchStyles: IIconStyles = { root: { color: 'var(--search-color)' } };
  const ContractStyles: IIconStyles = { root: { color: 'var(--contracts-color)' } };
  const SettingStyles: IIconStyles = { root: { color: 'var(--settings-color)' } };
  const WikiStyles: IIconStyles = { root: { color: 'var(--wiki-color)' } };
  const FeatureStyles: IIconStyles = { root: { color: 'var(--features-color)' } };
  const InternalSupportStyles: IIconStyles = { root: { color: 'var(--atlas-color)' } };
  const ExploreDataStyles: IIconStyles = { root: { color: 'var(--audit-color)' } };

  const theme = getCurrentThemeFromStorage(localStorage);
  return (
    <ElxContainer headerText="Welcome to Services Hub Operations">
      <div className="home-cards-back">
        <Stack horizontal wrap>
          <Stack.Item>
            <a href="/Operations">
              <DocumentCard className="home-cards">
                <div className="heading1">
                  <DocumentCardTitle title="Operations" shouldTruncate />

                  <p className="card-heading">
                    <Icon iconName="Search" styles={SearchStyles} />
                  </p>
                </div>
                <div
                  style={{
                    color: theme === 'darkTheme' ? 'white' : 'black',
                    fontSize: 14,
                  }}>
                  Get more information on Customers, Workspaces, Entitlement Mapping, Users and
                  Registrations
                </div>
              </DocumentCard>
            </a>
          </Stack.Item>
          <Stack.Item>
            <a href="/Contract">
              <DocumentCard className="home-cards-contracts">
                <div className="heading1">
                  <DocumentCardTitle title="Contracts" shouldTruncate />

                  <p className="card-heading">
                    <Icon iconName="OpenEnrollment" styles={ContractStyles} />
                  </p>
                </div>

                <div
                  style={{
                    color: theme === 'darkTheme' ? 'white' : 'black',
                    fontSize: 14,
                  }}>
                  Additional details on a customer contract such as services, contacts, provisioning
                  status etc. from source systems like Braavos or SAP.
                </div>
              </DocumentCard>
            </a>
          </Stack.Item>
          <Stack.Item>
            <a href="/script">
              <DocumentCard className="home-cards-scripts">
                <div className="heading1">
                  <DocumentCardTitle title="Scripts" shouldTruncate />

                  <p className="card-heading">
                    <Icon iconName="Script" styles={ScriptStyles} />
                  </p>
                </div>

                <div
                  style={{
                    color: theme === 'darkTheme' ? 'white' : 'black',
                    fontSize: 14,
                  }}>
                  Automated scripts to resolve frequent customer issues and generate trace logs.
                </div>
              </DocumentCard>
            </a>
          </Stack.Item>
          <Stack.Item>
            <a href="/Features">
              <DocumentCard className="home-cards-features">
                <div className="heading1">
                  <DocumentCardTitle title="Features" shouldTruncate />

                  <p className="card-heading">
                    <Icon iconName="SetAction" styles={FeatureStyles} />
                  </p>
                </div>

                <div
                  style={{
                    color: theme === 'darkTheme' ? 'white' : 'black',
                    fontSize: 14,
                  }}>
                  Search for application features by status and perform actions like activate ,
                  deactivate or flight them to a specific audience.
                </div>
              </DocumentCard>
            </a>
          </Stack.Item>
          <Stack.Item>
            <a href="/settings">
              <DocumentCard className="home-cards-settings">
                <div className="heading1">
                  <DocumentCardTitle title="Settings" shouldTruncate />

                  <p className="card-heading">
                    <Icon iconName="Settings" styles={SettingStyles} />
                  </p>
                </div>

                <div
                  style={{
                    color: theme === 'darkTheme' ? 'white' : 'black',
                    fontSize: 14,
                  }}>
                  Inspect or update key values and configuration settings.
                </div>
              </DocumentCard>
            </a>
          </Stack.Item>
          <Stack.Item>
            <DocumentCard className="home-cards-kb">
              <a
                href="https://aka.ms/ops-wiki"
                target="_blank"
                className="card-link"
                rel="noreferrer">
                <div className="heading1">
                  <DocumentCardTitle title="KB Articles" shouldTruncate />

                  <p className="card-heading">
                    <Icon iconName="Documentation" styles={WikiStyles} />
                  </p>
                </div>

                <div
                  style={{
                    color: theme === 'darkTheme' ? 'white' : 'black',
                    fontSize: 14,
                  }}>
                  Internal knowledge base articles for troubleshooting the issues.
                </div>
              </a>
            </DocumentCard>
          </Stack.Item>
          <Stack.Item>
            <a href="https://aka.ms/esddatlasdash" target="_blank" rel="noreferrer">
              <DocumentCard className="home-cards-atlas">
                <div className="heading1">
                  <DocumentCardTitle title="Internal Support Insights" shouldTruncate />

                  <p className="card-heading">
                    <Icon iconName="ViewAll2" styles={InternalSupportStyles} />
                  </p>
                </div>
                <div
                  style={{
                    color: theme === 'darkTheme' ? 'white' : 'black',
                    fontSize: 14,
                  }}>
                  Displays Trends, Insights for Product Owners, Actions needed by Support Engineers,
                  and Summary of Defects and DevOps tasks.
                </div>
              </DocumentCard>
            </a>
          </Stack.Item>
          <Stack.Item>
            <a href="https://aka.ms/ops-audit" target="_blank" rel="noreferrer">
              <DocumentCard className="home-cards-aceaudit">
                <div className="heading1">
                  <DocumentCardTitle title="Azure Case Insights Audit" shouldTruncate />

                  <p className="card-heading">
                    <Icon iconName="ReportAdd" styles={ExploreDataStyles} />
                  </p>
                </div>
                <div
                  style={{
                    color: theme === 'darkTheme' ? 'white' : 'black',
                    fontSize: 14,
                  }}>
                  To detect changes in ACE Customer Definition, and onboard new customers by sending
                  auto alerts for issues and warnings.
                </div>
              </DocumentCard>
            </a>
          </Stack.Item>
        </Stack>
      </div>
    </ElxContainer>
  );
};
export default HomePage;
