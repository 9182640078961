import React from 'react';

export const FnEmpty = (): void => {
	// no-op
};
export interface IExampleThemeContext {
	darkMode: boolean;
	setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExampleThemeContext = React.createContext<IExampleThemeContext>({
	darkMode: false,
	setDarkMode: FnEmpty,
});

export const useExampleThemeContext = (): IExampleThemeContext =>
	React.useContext(ExampleThemeContext);
