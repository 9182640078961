import React from 'react';
import { ElxLink } from '@elixir/fx';
import { Stack } from "@fluentui/react/lib/Stack";
import { mergeStyles } from "@fluentui/react/lib/Styling";

const customStackStyles = mergeStyles({
  marginTop: -8
});
const roleNameStyle = mergeStyles({
  fontWeight: "bold"
});
const linkStyle = mergeStyles({
  fontWeight: "bold"
});

type permissionErrorMessageProps = {
  missingRole: string;
};

export const PermissionErrorMessage = (props: React.PropsWithChildren<permissionErrorMessageProps>): React.ReactElement => {
  return (
    <Stack className={customStackStyles}>
      <Stack.Item>
        You must have the{' '}
        <span className={roleNameStyle}>{props.missingRole}</span>{' '}
        role in order to perform this operation.  Please see the {' '}
        <ElxLink
          target="_blank"
          href="https://aka.ms/OpsCenterElevation"
          className={linkStyle}
        >
          JIT Elevation Wiki
        </ElxLink>
        {' '} page which explains how to elevate your Torus debug account into the
        security group associated with this role.  Once elevation is complete,
        you may return to the Services Hub Operations Center, sign in with your Torus
        debug account, and perform the operation.
      </Stack.Item>
    </Stack>
  );
};
