import * as type from './types';
import { IScope } from '@elixir/fx';
import {
	ICustomerRemoveAccessPayload,
} from './types';

export function getSearchResults(id: any, OpsType: any, scope: IScope) {
	return {
		type: type.GET_SEARCH_RESULTS_REQUESTED,
		id,
		OpsType,
		scope,
		payload: {
			id,
			OpsType,
			scope,
		},
	};
}

export function getCustomerWorkspaces(name: any, id: any, workspaceIds: string[], scope: IScope) {
	return {
		type: type.GET_CUSTOMER_WORKSPACES_REQUESTED,
		name,
		id,
		workspaceIds,
		scope,
		payload: {
			name,
			id,
			workspaceIds,
			scope,
		},
	};
}

export function getUserEntitlements(
	userId: any,
	entitlementType: any,
	scope: IScope
) {
	return {
		type: type.GET_USER_ENTITLEMENTS_REQUESTED,
		userId,
		entitlementType,
		scope,
		payload: {
			userId,
			entitlementType,
			scope,
		},
	};
}

export function getShowRoles(userId: string, scope: IScope) {
	return {
		type: type.GET_SHOW_ROLES_REQUESTED,
		userId,
		scope,
		payload: {
			userId,
			scope,
		},
	};
}

export function clearLOD(lodId: any, scope: IScope) {
	return {
		type: type.CLEAR_LOD_REQUESTED,
		lodId,
		payload: {
			lodId,
			scope,
		},
	};
}

export function addAccessToWorkspace(id: string, scope: IScope) {
	return {
		type: type.WORKSPACE_ADD_ACCESS_REQUESTED,
		id,
		scope,
		payload: {
			id,
			scope,
		},
	};
}

export function removeAccessFromWorkspaces(id: string, scope: IScope) {
	return {
		type: type.WORKSPACE_REMOVE_ACCESS_REQUESTED,
		id,
		payload: {
			id,
			scope,
		},
	};
}

export function addMsaToWorkspace(id: string, scope: IScope) {
	return {
		type: type.WORKSPACE_ADD_MSA_REQUESTED,
		id,
		scope,
		payload: {
			id,
			scope,
		},
	};
}

export function removeMsaFromWorkspaces(id: string, scope: IScope) {
	return {
		type: type.WORKSPACE_REMOVE_MSA_REQUESTED,
		id,
		payload: {
			id,
			scope,
		},
	};
}

export function addAccessToCustomer(id: string, scope: IScope) {
	return {
		type: type.CUSTOMER_ADD_ACCESS_REQUESTED,
		id,
		scope,
		payload: {
			id,
			scope,
		},
	};
}

export function removeAccessFromCustomer(id: string, scope: IScope) {
	return {
		type: type.CUSTOMER_REMOVE_ACCESS_REQUESTED,
		id,
		scope,
		payload: {
			id,
			scope,
		},
	} as ICustomerRemoveAccessPayload;
}

export function getAssessment(workspaceId: string, scope: IScope) {
	return {
		type: type.GET_ASSESSMENT_REQUESTED,
		workspaceId,
		scope,
		payload: {
			workspaceId,
			scope,
		},
	};
}

export function overrideAssessment(workspaceId: string, overrideJson: Array<any>, scope: IScope) {
	return {
		type: type.OVERRIDE_ASSESSMENT_REQUESTED,
		workspaceId,
		overrideJson,
		scope,
		payload: {
			workspaceId,
			overrideJson,
			scope,
		},
	};
}

export function getEmailVerification(userAlias: string, scope: IScope) {
	return {
		type: type.EMAIL_VERIFICATION_REQUESTED,
		userAlias,
		scope,
		payload: {
			userAlias,
			scope,
		},
	};
}
