/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stack } from '@fluentui/react';
import { isEmpty } from 'lodash';
import { DatePicker } from '@fluentui/react';
import {
  ElxActionButton,
  ElxDateTimePicker,
  ElxDateTimePickerDisplayMode,
  ElxLink,
  ElxDialog,
  ElxDropdown,
  ElxPanel,
  ElxScopedContainer,
  ElxTableContainer,
  ElxTextField,
  IElxColumn,
  IElxContainerProps,
  IElxDateTimePickerTimeZone,
  IElxSearchBoxProps,
  IScope,
  ITableAction,
  PanelSize,
  SelectionMode,
  useScope,
  MessageBarType,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';
import {
  addAnnouncement,
  approveAnnouncements,
  deleteAnnouncement,
  getAnnouncements,
  previewAnnouncementImages,
  previewAnnouncementImageRead,
  saveAnnouncement,
} from './store/actions';
import EmptyData from './styling/images/EmptyData.svg';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import './styling/css/style.css';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { IAnnouncement } from './store/types/variableTypes';
import { IAnnouncementColumn } from './store/types';
import { ErrorType, IError } from '../utility/errorHandling';
import { PermissionErrorMessage } from '../utility/PermissionErrorMessage';
;
export const Announcements = (props: {
  searchProps?: IElxSearchBoxProps;
  containerProps?: IElxContainerProps;
}) => {
  const scope = useScope();
  const dispatch = useDispatch();
  const searchBoxProps = {
    ...props.searchProps,
  };
  const announcementColumn: IAnnouncementColumn[] = [];
  const announcementColumn2: IAnnouncementColumn[] = [];
  const announcementColumn3: IAnnouncementColumn[] = [];
  const timeZones: IElxDateTimePickerTimeZone[] = [
    { utcOffsetMinutes: 0, symbol: 'UTC' },
    { utcOffsetMinutes: -480, symbol: 'PST' },
    { utcOffsetMinutes: -420, symbol: 'PDT' },
    { utcOffsetMinutes: -300, symbol: 'EST' },
    { utcOffsetMinutes: -240, symbol: 'EDT' },
    { utcOffsetMinutes: 330, symbol: 'IST' },
    { utcOffsetMinutes: 480, symbol: 'CST' },
    { utcOffsetMinutes: 540, symbol: 'JST' },
  ];
  const selectedKeys: Array<string> = [];
  const options: IDropdownOption[] = [
    { key: 'd_defaultGreyImage.svg', text: 'Grey' },
    { key: 'd_defaultLightBlueImage.svg', text: 'Blue' },
    { key: 'd_defaultImage.svg', text: 'None' },
  ];

  // Subscribe for data changes
  const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Announcements?.error
  );
  const announcementList: Array<any> = useSelector(
    (state: any) => state?.modules?.Announcements?.announcementList,
  );
  const preview: Array<any> = useSelector(
    (state: any) => state?.modules?.Announcements?.imagePreviewResult,
  );
  const imageURLPreview: Array<any> = useSelector(
    (state: any) => state?.modules?.Announcements?.imageDetails,
  );
  var imageReadPreview: string = useSelector(
    (state: any) => state?.modules?.Announcements?.imageURL,
  );

  // Setup state variables
  const [openPanel, setOpenPanel] = useState(false);
  const [openPanel2, setOpenPanel2] = useState(false);
  const [openPanel3, setOpenPanel3] = useState(false);
  const [openPanel4, setOpenPanel4] = useState(false);
  const [elementToEdit, setElementToEdit] = useState(undefined);
  const [newElement, setNewElement] = useState({
    order: '',
    title: '',
    description: '',
    alternateText: '',
    desktopImage: 'd_defaultImage.svg',
    tabletImage: 't_defaultImage.svg',
    mobileImage: 'm_defaultImage.svg',
    callToActionText: '',
    callToActionLink: '',
    isDefault: true,
    expiryDate: '',
    modifiedById: '',
    isDarkThemeEnabled: false,
    isApproved: false,
    partitionKey: 'DefaultPartitionKey',
  });
  const [isDismissable, setIsDismissable] = React.useState(true);
  const [showDialog, setShowDialog] = React.useState(false);

  const [titleForPreview, setTitleForPreview] = useState('');
  const [callToActionTextForPreview, setCallToActionTextForPreview] = useState(
    '',
  );
  const [callToActionLinkForPreview, setCallToActionLinkForPreview] = useState(
    '',
  )
  const [approvedIds, setApprovedIds] = useState({});
  const [desktopImageName, setDesktopImageName] = useState('');
  const [mobileImageName, setMobileImageName] = useState('');
  const [tabletImageName, setTabletImageName] = useState('');
  const [desktopFileName, setDesktopFileName] = useState('');
  const [mobileFileName, setMobileFileName] = useState('');
  const [tabletFileName, setTabletFileName] = useState('');
  const [desktopFile, setDesktopFile] = useState<File>();
  const [mobileFile, setMobileFile] = useState<File>();
  const [tabletFile, setTabletFile] = useState<File>();

  const result: any = [];

  // Callback action functions
  function addAnnouncementData(element: any) {
    const ele = (element as unknown) as IAnnouncement;
    dispatch(addAnnouncement(ele, scope));
  }

  function previewApprovedAnnouncements(list: any) {
    for (const i of list) {
      if (i.expiryDate >= new Date().toISOString()) {
        i.isApproved && result.push(i.rowKey);
      }
    }
    setApprovedIds(result);
    dispatch(previewAnnouncementImages(result, scope));
  }

  function deleteAnnouncementsData(key: string, sc: IScope) {
    dispatch(deleteAnnouncement(key, sc));
  }

  function seeImagePreview(element: any, sc: IScope) {
    const id = element?.rowKey;
    const name = element?.desktopImage;
    const listOfIds: Array<string> = [];
    listOfIds.push(id);
    setTitleForPreview(element?.title);
    setCallToActionTextForPreview(element?.callToActionText);
    setCallToActionLinkForPreview(element?.callToActionLink);
    dispatch(previewAnnouncementImages(listOfIds, sc));
    //dispatch(previewAnnouncementImageRead(name, sc));
  }

  function seeImagePreviewInsideEdit(ele: any) {
    const name = ele.desktopImage;
    dispatch(previewAnnouncementImageRead(name, scope));
  }
  
  function _onChangeFileDesktop(event: any, key: string) {
    let desktopFileName = 'd_' + key + event.target.files[0].name;
    setDesktopFile(event.target.files);
    setDesktopFileName(desktopFileName);
  }

  function _onChangeFileMobile(event: any, key: string) {
    let mobileFileName = 'm_' + key + event.target.files[0].name;
    setMobileFile(event.target.files);
    setMobileFileName(mobileFileName);
  }

  function _onChangeFileTablet(event: any, key: string) {
    let tabletFileName = 't_' + key + event.target.files[0].name;
    setTabletFile(event.target.files);
    setTabletFileName(tabletFileName);
  }

  function saveAnnouncementData(element: any) {
    const ele = (element as unknown) as IAnnouncement;
    dispatch(saveAnnouncement(ele, scope));
  }

  // function getImageURL(desktop: string, tablet: string, mobile: string) {
  //   if (!isEmpty(desktop))
  //     dispatch(getDesktopImageURI(desktop, desktopFile as File));
  //   if (!isEmpty(mobile))
  //     dispatch(getMobileImageURI(mobile, mobileFile as File));
  //   if (!isEmpty(tablet))
  //     dispatch(getTabletImageURI(tablet, tabletFile as File));
  // }

  // Request all existing announcemnts
  useEffect(() => {
    // QUESTION: Is this right? (passing hard-coded workspaceId)
    dispatch(getAnnouncements(scope, '880c7335-bf79-40d1-a3af-985f15caacfb'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: (
                <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
              )
            },
            scope)
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.'
            },
            scope)
        );
      }
    } else {
      // Clear any existing error message
      dispatch(uxClearContainerMessageAction(scope));
    }
  });

  // Check if there is no announcement data to display
  if (announcementList === undefined) {
    return (
      <div className="default-contract">
        <img src={EmptyData} alt="BigCo Inc. logo" style={{}} />
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO RESULTS TO DISPLAY. SELECT A FEATURE !{' '}
        </b>
      </div>
    );
  }

  // Otherwise, display the announcements
  else {
    if (announcementList.length > 0) {
      // eslint-disable-next-line array-callback-return
      announcementList?.map((x: any) => {
        const announcementJSON = {
          rowKey: x?.rowKey ?? 'N/A',
          isApproved: x?.isApproved ?? 'N/A',
          order: x?.order ?? 'N/A',
          title: x?.title ?? 'N/A',
          description: x?.description ?? 'N/A',
          callToActionText: x?.callToActionText ?? 'N/A',
          callToActionLink: x?.callToActionLink ?? 'N/A',
          imageAlternateText: x?.alternateText ?? 'N/A',
          expiryDate: x?.expiryDate ?? 'N/A',
          lastModifiedById: x?.modifiedById ?? 'N/A',
          isDarkThemeEnabled: x?.isDarkThemeEnabled,
          desktopImage: x?.desktopImage,
          mobileImage: x?.mobileImage,
          tabletImage: x?.tabletImage,
          Element: x,
        };
        selectedKeys.push(x?.IsApproved);
        announcementColumn.push(announcementJSON);
      });
      // eslint-disable-next-line array-callback-return
      preview?.map((x: any) => {
        const imagePreviewJSON = {
          title: x?.title,
          callToActionText: x?.callToActionText ?? 'N/A',
          callToActionLink: x?.callToActionLink ?? 'N/A',
          desktopImage: x?.desktopImageUri,
          mobileImage: x?.mobileImageUri,
          tabletImage: x?.tabletImageUri,
        };
        announcementColumn2.push(imagePreviewJSON);
      });
      // eslint-disable-next-line array-callback-return
      imageURLPreview?.map((x: any) => {
        const imagePreviewJSON = {
          title: x?.title,
          callToActionText: x?.callToActionText ?? 'N/A',
          callToActionLink: x?.callToActionLink ?? 'N/A',
          desktopImage: x?.desktopImageUri,
        };
        announcementColumn3.push(imagePreviewJSON);
      });
    }

    return (
      <ElxScopedContainer scope={scope}>
        <ElxPanel
          isOpen={openPanel4}
          size={PanelSize.large}
          onDismiss={() => {
            setOpenPanel4(false)
          }}
          styles={{
            body: {
              padding: '1rem',
              width: '800',
              overflow: 'hidden',
            },
          }}
          actions={[
            {
              key: 'Approve',
              text: 'Approve',
              isPrimary: true,
              onClick: () => {
                dispatch(approveAnnouncements(approvedIds, scope))
                setOpenPanel4(false)
              },
            },
            {
              key: 'Close',
              text: 'Dismiss',
              onClick: () => {
                setOpenPanel4(false)
              },
            },
          ]}
          headerText="PREVIEW APPROVED ANNOUNCEMENTS"
        >
          <ElxScopedContainer scope={scope}>
            {isEmpty(imageURLPreview) ? (
              <div className="default-contract">
                <b style={{ color: '#605E5C', letterSpacing: 2 }}>
                  IT'S EXPIRED. PLEASE PREVIEW A UNEXPIRED ONE!
                </b>
              </div>
            ) : (
              announcementColumn3.map(
                (column: IAnnouncementColumn, index: number) => {
                  return (
                    <Stack>
                      <Stack.Item>
                        <div
                          id="container"
                          className="announcementsImageContainer"
                          style={{
                            maxWidth: 750,
                            maxHeight: 200,
                            position: 'relative',
                          }}
                        >
                          <img src={column['desktopImage']} alt="" />
                          <p
                            id="text"
                            style={{
                              zIndex: 100,
                              position: 'absolute',
                              fontSize: 14,
                              fontWeight: 600,
                              fontStyle: 'bold',
                              left: 35,
                              top: 15,
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              color: 'black',
                              maxWidth: '60%',
                            }}
                          >
                            {column['title'] ? column['title'] : ''}
                          </p>
                          <a
                            id="text"
                            href={
                              column['callToActionLink']
                                ? column['callToActionLink']
                                : ''
                            }
                            style={{
                              zIndex: 100,
                              position: 'absolute',
                              fontSize: 12,
                              left: 35,
                              top: 40,
                              color: 'black',
                            }}
                          >
                            {column['callToActionText']
                              ? column['callToActionText']
                              : ''}
                          </a>
                        </div>
                      </Stack.Item>
                    </Stack>
                  )
                },
              )
            )}
          </ElxScopedContainer>
        </ElxPanel>

        <ElxPanel
          isOpen={openPanel3}
          size={PanelSize.large}
          onDismiss={() => {
            setOpenPanel3(false)
          }}
          styles={{
            body: {
              padding: '1rem',
              width: '800',
              overflow: 'hidden',
            },
          }}
          actions={[
            {
              key: 'Save',
              text: 'Save',
              isPrimary: true,
              onClick: () => {
                setOpenPanel3(false)
                addAnnouncementData(newElement)
              },
            },
            {
              key: 'Preview',
              text: 'Preview',
              isPrimary: true,
              onClick: () => {
                seeImagePreviewInsideEdit(newElement)
              },
            },
            {
              key: 'Close',
              text: 'Dismiss',
              onClick: () => {
                setOpenPanel3(false)
              },
            },
          ]}
          headerText="ADD ANNOUNCEMENTS"
        >
          <div style={{ paddingLeft: 10, paddingRight: 20 }}>
            <Toggle
              inlineLabel
              onText="Approved"
              offText="Not Approved"
              checked={newElement.isApproved}
              onChange={(event, newValue) => {
                setNewElement((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.isApproved = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-order"
              label="ORDER"
              name="Order"
              value={newElement.order}
              onChange={(event, newValue) => {
                setNewElement((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.order = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-title"
              label="TITLE"
              multiline
              name="title"
              value={newElement.title}
              onChange={(event, newValue) => {
                setNewElement((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.title = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-description"
              label="DESCRIPTION"
              multiline
              name="Description"
              value={newElement.description}
              onChange={(event, newValue) => {
                //(newValue)
                setNewElement((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.description = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-CallToActionText"
              label="CALL TO ACTION TEXT"
              multiline
              onChange={(event, newValue) => {
                setNewElement((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.callToActionText = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-CallToActionLink"
              label="CALL TO ACTION LINK"
              value={newElement.callToActionLink}
              multiline
              onChange={(event, newValue) => {
                setNewElement((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.callToActionLink = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-AlternateText"
              label="IMAGE ALTERNATE TEXT"
              multiline
              value={newElement.alternateText}
              onChange={(event, newValue) => {
                setNewElement((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.alternateText = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <div style={{ width: '14.2rem' }}>
              <DatePicker
                id="datepicker"
                placeholder="Expiry Date"
                ariaLabel="Expiry Date"
                value={
                  newElement.expiryDate
                    ? new Date(newElement.expiryDate)
                    : undefined
                }
                onSelectDate={(date) => {
                  setNewElement((originalElement) => {
                    if (originalElement) {
                      const existingStatusObj = originalElement as any;
                      existingStatusObj.expiryDate = date
                        ?.toISOString()
                        .toString();

                      return {
                        ...existingStatusObj,
                      };
                    }
                    return originalElement;
                  });
                }}
                isRequired={true}
              />
            </div>
            <br />
            <Toggle
              inlineLabel
              onText="Dark Theme Enabled"
              offText="Dark Theme Disabled"
              checked={newElement.isDarkThemeEnabled}
              onChange={(event, newValue) => {
                setNewElement((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.isDarkThemeEnabled = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />
            <div>
              <ElxDropdown
                placeholder="Default Image"
                label="DEFAULT IMAGE"
                options={options}
                defaultSelectedKey={newElement.desktopImage}
                onChange={(event, newValue) => {
                  var img = newValue?.key as string;
                  if (img?.includes('Grey')) {
                    setNewElement((originalElement) => {
                      if (originalElement) {
                        const existingStatusObj = originalElement as any;
                        existingStatusObj.desktopImage = 'd_defaultGreyImage.svg';
                        existingStatusObj.mobileImage = 'm_defaultGreyImage.svg';
                        existingStatusObj.tabletImage = 't_defaultGreyImage.svg';
                        return {
                          ...existingStatusObj,
                        };
                      }
                      return originalElement;
                    });
                  } else if (img?.includes('Blue')) {
                    setNewElement((originalElement) => {
                      if (originalElement) {
                        const existingStatusObj = originalElement as any;
                        existingStatusObj.desktopImage =
                          'd_defaultLightBlueImage.svg';
                        existingStatusObj.mobileImage =
                          'm_defaultLightBlueImage.svg';
                        existingStatusObj.tabletImage =
                          't_defaultLightBlueImage.svg';
                        return {
                          ...existingStatusObj,
                        };
                      }
                      return originalElement;
                    });
                  } else {
                    setNewElement((originalElement) => {
                      if (originalElement) {
                        const existingStatusObj = originalElement as any;
                        existingStatusObj.desktopImage = newValue?.key;
                        existingStatusObj.mobileImage = newValue?.key;
                        existingStatusObj.tabletImage = newValue?.key;
                        return {
                          ...existingStatusObj,
                        };
                      }
                      return originalElement;
                    });
                  }
                }}
              />
              <br />
              {newElement.desktopImage ? (
                newElement.desktopImage === 'd_defaultGreyImage.svg' ? (
                  <div>
                    <label className="imageLabel">
                      Desktop Image (1366 x 150 px):
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="DesktopImage"
                      value="d_defaultGreyImage.svg"
                      disabled
                    />
                    <br />
                    <label className="imageLabel">
                      Tablet Image (990 x 150 px)
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="TabletImage"
                      value="t_defaultGreyImage.svg"
                      disabled
                    />
                    <br />
                    <label className="imageLabel">
                      Mobile Image (767 x 375 px)
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="MobileImage"
                      value="m_defaultGreyImage.svg"
                      disabled
                    />
                    <br />
                  </div>
                ) : newElement.desktopImage === 'd_defaultLightBlueImage.svg' ? (
                  <div>
                    <label className="imageLabel">
                      Desktop Image (1366 x 150 px):
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="DesktopImage"
                      value="d_defaultLightBlueImage.svg"
                      disabled
                    />
                    <br />
                    <label className="imageLabel">
                      Tablet Image (990 x 150 px)
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="TabletImage"
                      value="t_defaultLightBlueImage.svg"
                      disabled
                    />
                    <br />
                    <label className="imageLabel">
                      Mobile Image (767 x 375 px)
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="MobileImage"
                      value="m_defaultLightBlueImage.svg"
                      disabled
                    />
                    <br />
                  </div>
                ) : (
                  <div>
                    <label className="imageLabel">
                      Desktop Image (1366 x 150 px):
                    </label>
                    <input
                      type="file"
                      className="imageFile"
                      name="DesktopImage"
                    />
                    <br />
                    <label className="imageLabel">
                      Tablet Image (990 x 150 px)
                    </label>
                    <input
                      type="file"
                      className="imageFile"
                      name="TabletImage"
                    />
                    <br />
                    <label className="imageLabel">
                      Mobile Image (767 x 375 px)
                    </label>
                    <input
                      type="file"
                      className="imageFile"
                      name="MobileImage"
                    />
                    <br />
                  </div>
                )
              ) : (
                <></>
              )}
              <br />
              <br />
              {isEmpty(imageReadPreview) ? (
                <> </>
              ) : (
                <div
                  id="container"
                  className="announcementsImageContainer"
                  style={{
                    maxWidth: 750,
                    maxHeight: 200,
                    position: 'relative',
                  }}
                >
                  <img
                    id="image"
                    src={imageReadPreview}
                    alt=""
                    style={{
                      maxWidth: 750,
                      maxHeight: 150,
                      marginBottom: 20,
                      position: 'absolute',
                    }}
                  />
                  <p
                    id="text"
                    style={{
                      zIndex: 100,
                      position: 'absolute',
                      fontSize: 14,
                      fontWeight: 600,
                      fontStyle: 'bold',
                      left: 35,
                      top: 15,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '60%',
                    }}
                  >
                    {newElement ? newElement?.title : ''}
                  </p>
                  <a
                    id="text"
                    href={newElement ? newElement?.callToActionLink : ''}
                    style={{
                      zIndex: 100,
                      position: 'absolute',
                      fontSize: 12,
                      left: 35,
                      top: 40,
                      color: 'black',
                    }}
                  >
                    {newElement ? newElement?.callToActionText : ''}
                  </a>
                </div>
              )}
            </div>
          </div>
        </ElxPanel>

        <ElxPanel
          isOpen={openPanel}
          size={PanelSize.large}
          onDismiss={() => {
            setOpenPanel(false);
          }}
          styles={{
            body: {
              padding: '1rem',
              width: '800',
              overflow: 'hidden',
            },
          }}
          headerText="Edit Announcements"
          actions={[
            {
              key: 'Save',
              text: 'Save',
              isPrimary: true,
              onClick: () => {
                saveAnnouncementData(elementToEdit);
                setOpenPanel(false);
              },
            },
            {
              key: 'Preview',
              text: 'Preview',
              isPrimary: true,
              onClick: () => {
                seeImagePreviewInsideEdit(elementToEdit);
              },
            },
            {
              key: 'Close',
              text: 'Dismiss',
              onClick: () => {
                setOpenPanel(false);
              },
            },
          ]}
        >
          <div style={{ paddingLeft: 10, paddingRight: 20 }}>
            <Toggle
              inlineLabel
              onText="Approved"
              offText="Not Approved"
              checked={
                elementToEdit
                  ? Boolean(elementToEdit!['isApproved'])
                  : Boolean('')
              }
              onChange={(event, newValue) => {
                setElementToEdit((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.isApproved = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                })
              }}
            />
            <br />
            <ElxTextField
              role="announcement-order"
              defaultValue={elementToEdit ? elementToEdit!['order'] : ''}
              label="ORDER"
              onChange={(event, newValue) => {
                setElementToEdit((originalElement) => {
                  if (originalElement) {
                    const existingDescriptionObj = originalElement as any;
                    existingDescriptionObj.order = newValue;
                    return existingDescriptionObj;
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-title"
              defaultValue={elementToEdit ? elementToEdit!['title'] : ''}
              label="TITLE"
              multiline
              onChange={(event, newValue) => {
                setElementToEdit((originalElement) => {
                  if (originalElement) {
                    const existingDescriptionObj = originalElement as any;
                    existingDescriptionObj.title = newValue;
                    return existingDescriptionObj;
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-description"
              defaultValue={elementToEdit ? elementToEdit!['description'] : ''}
              label="DESCRIPTION"
              multiline
              onChange={(event, newValue) => {
                setElementToEdit((originalElement) => {
                  if (originalElement) {
                    const existingDescriptionObj = originalElement as any;
                    existingDescriptionObj.description = newValue;
                    return existingDescriptionObj;
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-CallToActionText"
              defaultValue={
                elementToEdit ? elementToEdit!['callToActionText'] : ''
              }
              label="CALL TO ACTION TEXT"
              multiline
              onChange={(event, newValue) => {
                setElementToEdit((originalElement) => {
                  if (originalElement) {
                    const existingDescriptionObj = originalElement as any;
                    existingDescriptionObj.callToActionText = newValue;
                    return existingDescriptionObj;
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxTextField
              role="announcement-CallToActionLink"
              defaultValue={
                elementToEdit ? elementToEdit!['callToActionLink'] : ''
              }
              label="CALL TO ACTION LINK"
              multiline
              onChange={(event, newValue) => {
                setElementToEdit((originalElement) => {
                  if (originalElement) {
                    const existingDescriptionObj = originalElement as any;
                    existingDescriptionObj.callToActionLink = newValue;
                    return existingDescriptionObj;
                  }
                  return originalElement;
                })
              }}
            />
            <br />
            <ElxTextField
              role="announcement-AlternateText"
              defaultValue={
                elementToEdit ? elementToEdit!['alternateText'] : ''
              }
              label="IMAGE ALTERNATE TEXT"
              multiline
              onChange={(event, newValue) => {
                setElementToEdit((originalElement) => {
                  if (originalElement) {
                    const existingDescriptionObj = originalElement as any;
                    existingDescriptionObj.alternateText = newValue;
                    return existingDescriptionObj;
                  }
                  return originalElement;
                });
              }}
            />
            <br />

            <ElxDateTimePicker
              id={'date-only'}
              label="EXPIRY DATE"
              timeZones={timeZones}
              defaultValue={elementToEdit ? elementToEdit!['expiryDate'] : ''}
              displayMode={ElxDateTimePickerDisplayMode.DateOnly}
              onSelectDateTime={(dateTime?: string) => {
                setElementToEdit((originalElement) => {
                  if (originalElement) {
                    const existingDescriptionObj = originalElement as any;
                    existingDescriptionObj.expiryDate = dateTime;

                    return existingDescriptionObj;
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <Toggle
              inlineLabel
              onText="Dark Theme Enabled"
              offText="Dark Theme Disabled"
              checked={
                elementToEdit
                  ? Boolean(elementToEdit!['isDarkThemeEnabled'])
                  : Boolean('')
              }
              onChange={(event, newValue) => {
                setElementToEdit((originalElement) => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    existingStatusObj.isDarkThemeEnabled = newValue;

                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxDropdown
              placeholder="Default Image"
              label="DEFAULT IMAGE"
              options={options}
              defaultSelectedKey={
                elementToEdit ? elementToEdit!['desktopImage'] : ''
              }
              onChange={(event, newValue) => {
                var img = newValue?.key as string
                if (img?.includes('Grey')) {
                  setElementToEdit((originalElement) => {
                    if (originalElement) {
                      const existingStatusObj = originalElement as any;
                      existingStatusObj.desktopImage = 'd_defaultGreyImage.svg';
                      existingStatusObj.mobileImage = 'm_defaultGreyImage.svg';
                      existingStatusObj.tabletImage = 't_defaultGreyImage.svg';
                      return {
                        ...existingStatusObj,
                      };
                    }
                    return originalElement;
                  });
                } else if (img?.includes('Blue')) {
                  setElementToEdit((originalElement) => {
                    if (originalElement) {
                      const existingStatusObj = originalElement as any;
                      existingStatusObj.desktopImage =
                        'd_defaultLightBlueImage.svg';
                      existingStatusObj.mobileImage =
                        'm_defaultLightBlueImage.svg';
                      existingStatusObj.tabletImage =
                        't_defaultLightBlueImage.svg';
                      return {
                        ...existingStatusObj,
                      };
                    }
                    return originalElement;
                  });
                } else {
                  setElementToEdit((originalElement) => {
                    if (originalElement) {
                      const existingStatusObj = originalElement as any;
                      existingStatusObj.desktopImage = newValue?.key;
                      existingStatusObj.mobileImage = newValue?.key;
                      existingStatusObj.tabletImage = newValue?.key;
                      return {
                        ...existingStatusObj,
                      };
                    }
                    return originalElement;
                  });
                }
              }}
            />

            <br />
            <div>
              {elementToEdit ? (
                elementToEdit!['desktopImage'] === 'd_defaultGreyImage.svg' ? (
                  <div>
                    <label className="imageLabel">
                      Desktop Image (1366 x 150 px):
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="DesktopImage"
                      value="d_defaultGreyImage.svg"
                      disabled
                    />
                    <br />
                    <label className="imageLabel">
                      Tablet Image (990 x 150 px)
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="TabletImage"
                      value="t_defaultGreyImage.svg"
                      disabled
                    />
                    <br />
                    <label className="imageLabel">
                      Mobile Image (767 x 375 px)
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="MobileImage"
                      value="m_defaultGreyImage.svg"
                      disabled
                    />
                    <br />
                  </div>
                ) : elementToEdit!['desktopImage'] ===
                  'd_defaultLightBlueImage.svg' ? (
                  <div>
                    <label className="imageLabel">
                      Desktop Image (1366 x 150 px):
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="DesktopImage"
                      value="d_defaultLightBlueImage.svg"
                      disabled
                    />
                    <br />
                    <label className="imageLabel">
                      Tablet Image (990 x 150 px)
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="TabletImage"
                      value="t_defaultLightBlueImage.svg"
                      disabled
                    />
                    <br />
                    <label className="imageLabel">
                      Mobile Image (767 x 375 px)
                    </label>
                    <input
                      type="text"
                      className="imageFile"
                      name="MobileImage"
                      value="m_defaultLightBlueImage.svg"
                      disabled
                    />
                    <br />
                  </div>
                ) : (
                  <div>
                    <label className="imageLabel">
                      Desktop Image (1366 x 150 px):
                    </label>
                    {elementToEdit!['desktopImage']}
                    <input
                      type="file"
                      className="imageFile"
                      name="DesktopImage"
                      // onChange={(event) => {
                      //   _onChangeFileDesktop(event, elementToEdit!['rowKey'])
                      //   setNewElement((originalElement) => {
                      //     if (originalElement) {
                      //       const existingStatusObj = originalElement as any
                      //       existingStatusObj.desktopImage = desktopFileName
                      //       existingStatusObj.mobileImage = mobileFileName
                      //       existingStatusObj.tabletImage = tabletFileName

                      //       return {
                      //         ...existingStatusObj,
                      //       }
                      //     }
                      //     return originalElement
                      //   })
                      // }}
                    />
                    <br />
                    <label className="imageLabel">
                      Tablet Image (990 x 150 px)
                    </label>
                    {elementToEdit!['tabletImage']}
                    <input
                      type="file"
                      className="imageFile"
                      name="TabletImage"
                      // onChange={(event) => {
                      //   _onChangeFileTablet(event, elementToEdit!['rowKey'])
                      //   setNewElement((originalElement) => {
                      //     if (originalElement) {
                      //       const existingStatusObj = originalElement as any
                      //       existingStatusObj.tabletImage = tabletFileName

                      //       return {
                      //         ...existingStatusObj,
                      //       }
                      //     }
                      //     return originalElement
                      //   })
                      // }}
                    />
                    <br />
                    <label className="imageLabel">
                      Mobile Image (767 x 375 px)
                    </label>
                    {elementToEdit!['mobileImage']}
                    <input
                      type="file"
                      className="imageFile"
                      name="MobileImage"
                      // onChange={(event) => {
                      //   _onChangeFileMobile(event, elementToEdit!['rowKey'])
                      //   setNewElement((originalElement) => {
                      //     if (originalElement) {
                      //       const existingStatusObj = originalElement as any
                      //       existingStatusObj.mobileImage = mobileFileName

                      //       return {
                      //         ...existingStatusObj,
                      //       }
                      //     }
                      //     return originalElement
                      //   })
                      // }}
                    />
                    <br />
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
            <br />
            {isEmpty(imageReadPreview) ? (
              <> </>
            ) : (
              <div
                id="container"
                className="announcementsImageContainer"
                style={{
                  maxWidth: 750,
                  maxHeight: 200,
                  position: 'relative',
                }}
              >
                <img
                  id="image"
                  src={imageReadPreview}
                  alt=""
                  style={{
                    maxWidth: 750,
                    maxHeight: 150,
                    marginBottom: 20,
                    position: 'absolute',
                  }}
                />
                <p
                  id="text"
                  style={{
                    zIndex: 100,
                    position: 'absolute',
                    fontSize: 14,
                    fontWeight: 600,
                    fontStyle: 'bold',
                    left: 35,
                    top: 15,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: '60%',
                  }}
                >
                  {elementToEdit ? elementToEdit!['title'] : ''}
                </p>
                <a
                  id="text"
                  href={elementToEdit ? elementToEdit!['callToActionLink'] : ''}
                  style={{
                    zIndex: 100,
                    position: 'absolute',
                    fontSize: 12,
                    left: 35,
                    top: 40,
                    color: 'black',
                  }}
                >
                  {elementToEdit ? elementToEdit!['callToActionText'] : ''}
                </a>
              </div>
            )}
          </div>
        </ElxPanel>

        <ElxTableContainer
          containerProps={{
            headerText: 'EDIT ANNOUNCEMENTS',
            fillBackground: false,
            compact: false,
            subActions: [
              <ElxActionButton
                key={1}
                text="Add Announcements"
                iconProps={{ iconName: 'Add' }}
                onClick={(item: any) => {
                  setOpenPanel3(true);
                }}
              />,
              <ElxActionButton
                key={2}
                text="Preview Selected Announcements"
                iconProps={{ iconName: 'EntryView' }}
                onClick={(item: any) => {
                  setOpenPanel4(true);
                  previewApprovedAnnouncements(announcementList);
                }}
              />,
            ],
          }}
          tableProps={{
            compact: false,
            columns: getColumns(),
            items: announcementColumn,
            actions: actions(),
            selectionMode: SelectionMode.none,
          }}
          searchBoxProps={searchBoxProps}
          onRenderEmpty={() => (
            <>
            </>
          )}
        ></ElxTableContainer>
        <ElxDialog
          hidden={!showDialog}
          dismissable={isDismissable}
          dialogContentProps={{ title: "Are you sure, you want to delete this announcement?" }}
          primaryButtonProps={{ text: "Delete" }}
          cancelButtonProps={{ text: "Cancel" }}
          onPrimaryButtonClick={() =>
            deleteAnnouncementsData(elementToEdit!['rowKey'], scope)
          }
          onDismiss={() => setShowDialog(false)}
        ></ElxDialog>
      </ElxScopedContainer>
    )
  }
  function actions(): ITableAction[] {
    return [
      {
        key: 'Edit',
        text: 'Edit',
        disableBulkAction: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onAction: (item: any) => {
          setElementToEdit(item['Element']);
          setDesktopImageName(item.desktopImage);
          setOpenPanel(true);
        },
        iconProps: { iconName: 'EditSolid12' },
        trackingContext: {
          label: 'CustomActionLabel',
        },
      },

      {
        key: 'Delete',
        text: 'Delete',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onAction: (item: any) => {
          setShowDialog(true);
          setElementToEdit(item['Element']);
        },
        iconProps: { iconName: 'Delete' },
        trackingContext: {
          label: 'CustomActionLabel',
        },
      },
    ];
  }
  function getColumns(): IElxColumn[] {
    return [
      {
        key: '1',
        name: 'IsApproved',
        fieldName: 'isApproved',
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
      },
      {
        key: '2',
        name: 'Order',
        fieldName: 'order',
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
      },
      {
        key: '3',
        name: 'Title',
        fieldName: 'title',
        minWidth: 150,
        //maxWidth: 170,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '4',
        name: 'Description',
        fieldName: 'description',
        minWidth: 150,
        //maxWidth: 170,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '5',
        name: 'CallToActionText',
        fieldName: 'callToActionText',
        minWidth: 150,
        //maxWidth: 170,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '6',
        name: 'CallToActionLink',
        fieldName: 'callToActionLink',
        minWidth: 150,
        //maxWidth: 170,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '7',
        name: 'ImageAlternateText',
        fieldName: 'imageAlternateText',
        minWidth: 150,
        //maxWidth: 170,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '8',
        name: 'ExpiryDate',
        fieldName: 'expiryDate',
        minWidth: 90,
        //maxWidth: 120,
        isResizable: true,
        isMultiline: true,
        onRender: (item) => {
          return (
            <span>
              {new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZone: 'UTC',
              }).format(new Date(item['expiryDate']))}
            </span>
          );
        },
      },
      {
        key: '9',
        name: 'LastModifiedBy',
        fieldName: 'lastModifiedById',
        minWidth: 110,
        //maxWidth: 150,
        isResizable: true,
        isMultiline: true,
      },
    ];
  }
}

export default Announcements;
