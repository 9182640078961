import { Action } from '@reduxjs/toolkit';
import IIncidentModel from './types/variabletypes';

export const GET_SEARCH_RESULTS_REQUESTED = 'GET_SEARCH_RESULTS_REQUESTED';
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_FAILURE = 'GET_SEARCH_RESULTS_FAILURE';
export const GET_CUSTOMER_WORKSPACES_REQUESTED = 'GET_CUSTOMER_WORKSPACES_REQUESTED';
export const GET_CUSTOMER_WORKSPACES_SUCCESS = 'GET_CUSTOMER_WORKSPACES_SUCCESS';
export const GET_CUSTOMER_WORKSPACES_FAILURE = 'GET_CUSTOMER_WORKSPACES_FAILURE';
export const GET_USER_ENTITLEMENTS_REQUESTED = 'GET_USER_ENTITLEMENTS_REQUESTED';
export const GET_USER_ENTITLEMENTS_SUCCESS = 'GET_USER_ENTITLEMENTS_SUCCESS';
export const GET_USER_ENTITLEMENTS_FAILURE = 'GET_USER_ENTITLEMENTS_FAILURE';
export const GET_ASSESSMENT_REQUESTED = 'GET_ASSESSMENT_REQUESTED';
export const GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS';
export const GET_ASSESSMENT_FAILURE = 'GET_ASSESSMENT_FAILURE';
export const EMAIL_VERIFICATION_REQUESTED = 'EMAIL_VERIFICATION_REQUESTED';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const EMAIL_VERIFICATION_FAILURE = 'EMAIL_VERIFICATION_FAILURE';
export const OVERRIDE_ASSESSMENT_REQUESTED = 'OVERRIDE_ASSESSMENT_REQUESTED';
export const OVERRIDE_ASSESSMENT_SUCCESS = 'OVERRIDE_ASSESSMENT_SUCCESS';
export const OVERRIDE_ASSESSMENT_FAILURE = 'OVERRIDE_ASSESSMENT_FAILURE';
export const GET_SHOW_ROLES_REQUESTED = 'GET_SHOW_ROLES_REQUESTED';
export const GET_SHOW_ROLES_SUCCESS = 'GET_SHOW_ROLES_SUCCESS';
export const GET_SHOW_ROLES_FAILURE = 'GET_SHOW_ROLES_FAILURE';
export const CLEAR_LOD_REQUESTED = 'CLEAR_LOD_REQUESTED';
export const CLEAR_LOD_SUCCESS = 'CLEAR_LOD_SUCCESS';
export const CLEAR_LOD_FAILURE = 'CLEAR_LOD_FAILURE';
export const WORKSPACE_ADD_ACCESS_REQUESTED = 'WORKSPACE_ADD_ACCESS_REQUESTED';
export const WORKSPACE_ADD_ACCESS_SUCCESS = 'WORKSPACE_ADD_ACCESS_SUCCESS';
export const WORKSPACE_ADD_ACCESS_FAILURE = 'WORKSPACE_ADD_ACCESS_FAILURE';
export const WORKSPACE_REMOVE_ACCESS_REQUESTED = 'WORKSPACE_REMOVE_ACCESS_REQUESTED';
export const WORKSPACE_REMOVE_ACCESS_SUCCESS = 'WORKSPACE_REMOVE_ACCESS_SUCCESS';
export const WORKSPACE_REMOVE_ACCESS_FAILURE = 'WORKSPACE_REMOVE_ACCESS_FAILURE';
export const WORKSPACE_ADD_MSA_REQUESTED = 'WORKSPACE_ADD_MSA_REQUESTED';
export const WORKSPACE_ADD_MSA_SUCCESS = 'WORKSPACE_ADD_MSA_SUCCESS';
export const WORKSPACE_ADD_MSA_FAILURE = 'WORKSPACE_ADD_MSA_FAILURE';
export const WORKSPACE_REMOVE_MSA_REQUESTED = 'WORKSPACE_REMOVE_MSA_REQUESTED';
export const WORKSPACE_REMOVE_MSA_SUCCESS = 'WORKSPACE_REMOVE_MSA_SUCCESS';
export const WORKSPACE_REMOVE_MSA_FAILURE = 'WORKSPACE_REMOVE_MSA_FAILURE';
export const CUSTOMER_ADD_ACCESS_REQUESTED = 'CUSTOMER_ADD_ACCESS_REQUESTED';
export const CUSTOMER_ADD_ACCESS_SUCCESS = 'CUSTOMER_ADD_ACCESS_SUCCESS';
export const CUSTOMER_ADD_ACCESS_FAILURE = 'CUSTOMER_ADD_ACCESS_FAILURE';
export const CUSTOMER_REMOVE_ACCESS_REQUESTED = 'CUSTOMER_REMOVE_ACCESS_REQUESTED';
export const CUSTOMER_REMOVE_ACCESS_SUCCESS = 'CUSTOMER_REMOVE_ACCESS_SUCCESS';
export const CUSTOMER_REMOVE_ACCESS_FAILURE = 'CUSTOMER_REMOVE_ACCESS_FAILURE';
export const ADD_ID_ON_WORKSPACE_ACCESS_SUCCESS = 'ADD_ID_ON_WORKSPACE_ACCESS_SUCCESS';
export const ADD_ID_ON_WORKSPACE_ACCESS_FAILURE = 'ADD_ID_ON_WORKSPACE_ACCESS_FAILURE';
export const DELETE_ID_ON_WORKSPACE_ACCESS_SUCCESS = 'DELETE_ID_ON_WORKSPACE_ACCESS_SUCCESS';
export const DELETE_ID_ON_WORKSPACE_ACCESS_FAILURE = 'DELETE_ID_ON_WORKSPACE_ACCESS_FAILURE';
export const ADD_ID_ON_CUSTOMER_ACCESS_SUCCESS = 'ADD_ID_ON_CUSTOMER_ACCESS_SUCCESS';
export const ADD_ID_ON_CUSTOMER_ACCESS_FAILURE = 'ADD_ID_ON_CUSTOMER_ACCESS_FAILURE';
export const DELETE_ID_ON_CUSTOMER_ACCESS_SUCCESS = 'DELETE_ID_ON_CUSTOMER_ACCESS_SUCCESS';
export const DELETE_ID_ON_CUSTOMER_ACCESS_FAILURE = 'DELETE_ID_ON_CUSTOMER_ACCESS_SUCCESS';
export const CUSTOMER_ADD_ACCESS_RETURN_ID_SUCCESS = 'CUSTOMER_ADD_ACCESS_RETURN_ID_SUCCESS';
export const CUSTOMER_ADD_ACCESS_RETURN_ID_FAILURE = 'CUSTOMER_ADD_ACCESS_RETURN_ID_FAILURE';

// getSearchResults
export interface ISearchResultsPayload extends Action<typeof GET_SEARCH_RESULTS_REQUESTED> {
  opsType: string;
  id: string;
}

export interface ISearchResultsSuccess extends Action<typeof GET_SEARCH_RESULTS_SUCCESS> {
  allresults: IIncidentModel<Object>;
}

// getCustomerWorkspaces
export interface ICustomerWorkspacesPayload
  extends Action<typeof GET_CUSTOMER_WORKSPACES_REQUESTED> {
  name: string;
  id: string;
  workspaceIds: string[];
}

export interface ICustomerWorkspacesSuccess extends Action<typeof GET_CUSTOMER_WORKSPACES_SUCCESS> {
  workspaceList: any;
}

// getUserEntitlements
export interface IUserEntitlementsPayload extends Action<typeof GET_USER_ENTITLEMENTS_REQUESTED> {
  userId: string;
  entitlementType: string;
}

export interface IUserEntitlementsSuccess extends Action<typeof GET_USER_ENTITLEMENTS_SUCCESS> {
  entitlement: IIncidentModel<Object>;
}

// getShowRoles
export interface IShowRolesPayload extends Action<typeof GET_SHOW_ROLES_REQUESTED> {
  userId: string;
}

export interface IShowRolesSuccess extends Action<typeof GET_SHOW_ROLES_SUCCESS> {
  showRoles: IIncidentModel<Object>;
}

// clearLOD
export interface IClearLODPayload extends Action<typeof CLEAR_LOD_SUCCESS> {
  lodId: String;
}

// addAccessToWorkspace
export interface IWorkspaceAddAccessPayload extends Action<typeof WORKSPACE_ADD_ACCESS_REQUESTED> {
  id: String;
}

export interface IWorkspaceAddAccessSuccess extends Action<typeof WORKSPACE_ADD_ACCESS_SUCCESS> {
  //accessibleWorkspaceIdList: Array<string>;
  id: string;
}

// removeAccessFromWorkspaces
export interface IWorkspaceRemoveAccessPayload
  extends Action<typeof WORKSPACE_REMOVE_ACCESS_REQUESTED> {
  id: string;
}

export interface IWorkspaceRemoveAccessSuccess
  extends Action<typeof WORKSPACE_REMOVE_ACCESS_SUCCESS> {
  //accessibleWorkspaceIdList: Array<string>;
  id: string;
}

// addAccessToCustomer
export interface ICustomerAddAccessPayload extends Action<typeof CUSTOMER_ADD_ACCESS_REQUESTED> {
  id: string;
}

export interface ICustomerAddAccessSuccess extends Action<typeof CUSTOMER_ADD_ACCESS_SUCCESS> {
  id: string;
  idForCustomerAccess: string;
}

// removeAccessFromCustomer
export interface ICustomerRemoveAccessPayload
  extends Action<typeof CUSTOMER_REMOVE_ACCESS_REQUESTED> {
  id: string;
}

export interface ICustomerRemoveAccessSuccess
  extends Action<typeof CUSTOMER_REMOVE_ACCESS_SUCCESS> {
  id: string;
}

// addMsaToWorkspace
export interface IWorkspaceAddMsaPayload extends Action<typeof WORKSPACE_ADD_MSA_REQUESTED> {
  id: String;
}

export interface IWorkspaceAddMsaSuccess extends Action<typeof WORKSPACE_ADD_MSA_SUCCESS> {
  id: string;
}

// removeMsaFromWorkspaces
export interface IWorkspaceRemoveMsaPayload
  extends Action<typeof WORKSPACE_REMOVE_MSA_REQUESTED> {
  id: string;
}

export interface IWorkspaceRemoveMsaSuccess
  extends Action<typeof WORKSPACE_REMOVE_MSA_SUCCESS> {
  id: string;
}

// getAssessment
export interface IAssessmentPayload extends Action<typeof GET_ASSESSMENT_REQUESTED> {
  workspaceId: string;
}

export interface IAssessmentSuccess extends Action<typeof GET_ASSESSMENT_SUCCESS> {
  assessments: Array<object>;
}

// overrideAssessment
export interface IAssessmentOverridePayload extends Action<typeof GET_ASSESSMENT_REQUESTED> {
  workspaceId: string;
  overrideJson: Array<any>;
}

// getEmailVerification
export interface IEmailPayload extends Action<typeof EMAIL_VERIFICATION_REQUESTED> {
  userAlias: string;
}

export interface IEmailSuccess extends Action<typeof EMAIL_VERIFICATION_SUCCESS> {
  emailJson: any;
}

export interface IWorkspaceColumns {
  [key: string]: string;
}

export interface IUsersColumns {
  [key: string]: string;
}

export interface IRegistrationRolesColumns {
  Value: string;
  ValueLower: string;
}

export interface IRegistrationColumns {
  [key: string]: any;
}

export interface IPremierColumns {
  [key: string]: string;
}

export interface IRolesColumns {
  [key: string]: string;
}

export interface IAssessmentColumns {
  [key: string]: string;
}

export interface ICommercialColumns {
  [key: string]: string;
}

export interface ICustomerColumns {
  [key: string]: string;
}

// unused
//export interface IContentCards {
//  name: string;
//}
//
// unused
//export interface IContentColumns {
//  name: string;
//  data: string | React.ReactNode;
//}
//
// unused
//export interface IWorkspaceColumns2 {
//  [key: string]: string;
//}
//
//export default IContentColumns;
