import { Action } from '@reduxjs/toolkit';
import { IFeature, IFeatureModel } from './types/variabletypes';
import { ResultStatus } from '../../utility/errorHandling'

export const GET_FEATURES_REQUESTED = 'GET_FEATURES_REQUESTED';
export const GET_FEATURES_SUCCESS = 'GET_FEATURES_SUCCESS';
export const GET_FEATURES_FAILURE = 'GET_FEATURES_FAILURE';
export const SAVE_FEATURE_REQUESTED = 'SAVE_FEATURE_REQUESTED';
export const SAVE_FEATURE_SUCCESS = 'SAVE_FEATURE_SUCCESS';
export const SAVE_FEATURE_FAILURE = 'SAVE_FEATURE_FAILURE';

export interface IGetFeaturesPayload extends Action<typeof GET_FEATURES_REQUESTED> {
}

export interface IGetFeaturesSuccess extends Action<typeof GET_FEATURES_SUCCESS> {
	featureList: Array<IFeatureModel>;
}

export interface ISaveFeaturePayload
	extends Action<typeof SAVE_FEATURE_REQUESTED> {
	feature: IFeature;
}

export interface ISaveFeatureSuccess extends Action<typeof SAVE_FEATURE_SUCCESS> {
  saveFeatureStatus: ResultStatus,
  feature: IFeatureModel;
}

export interface IFeatureColumn {
	[key: string]: string;
}
