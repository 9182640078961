import { IModule } from '@elixir/fx'
import contract from '../components/contracts/store/reducers'
import { ContractSagas } from '../components/contracts/store/sagas'
import Contracts from '../components/contracts/contracts/Contracts'

export const contractModule: IModule = {
  name: 'Contract',
  env: 'development',
  reducer: contract,
  saga: ContractSagas,
  route: {
    index: 'Contract',
    name: 'Contract',
    iconProps: {
      iconName: "OpenEnrollment",
      styles: {
        root: {
          color: '#8d1212'
        }
      }
    },
    path: '/Contract/',
    isRouteable: true,
    exact: false,
    inSidebar: true,
    component: Contracts,
  },
}
