/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
    ElxActionButton,
  ElxPrimaryButton,
  ElxScopedContainer,
  IScope,
  MessageBarType,
  useScope,
  uxAddContainerMessageAction,
} from '@elixir/fx';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorType, IError } from '../../../utility/errorHandling';
import { PermissionErrorMessage } from '../../../utility/PermissionErrorMessage';
import { getContractChecker } from '../../store/action';
import { IContractCheckerPayload } from '../../store/types';
import NoResults from './components/NoResults';
import ContractValidatorTable from './components/ContractValidatorTable';

export const ContractChecker = (props: { scope: IScope }) => {
  const scope = useScope()
  const dispatch = useDispatch()
  const [contractId, setContractId] = useState('')
  const [installationSiteId, setInstallationSiteId] = useState('')
  
  const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Contract?.error
  );
  const contractChecker: IContractCheckerPayload = useSelector(
    (state: any) => state?.modules?.Contract?.contractChecker
  );
  const contractIdFromState: string = useSelector(
    (state: any) => state?.modules?.Contract?.contractId
  );

  useEffect(() => {
    if(contractIdFromState){
      setContractId(contractIdFromState);
      dispatch(getContractChecker(contractIdFromState, '1', props.scope))
    }
  }, [contractIdFromState]);

  useEffect(() => {
    if(!isEmpty(error)){
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: (
                <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
              )
            },
            props.scope)
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.'
            },
            props.scope)
        );
      }
    }
  }, [error]);


  const GetInputData = () => {
    return (<ElxScopedContainer scope={scope}>
      <div style={{ margin: 16, marginTop: 0 }}>
        <div>
          <input
            type="text"
            className="scc-input-style"
            placeholder="Enter Contract ID"
            value={contractId}
            onChange={(event) => setContractId(event.target.value)}
          />
        </div>
        <div>
          <input
            type="text"
            className="scc-input-style"
            placeholder="Enter Installation Site ID (optional)"
            value={installationSiteId}
            onChange={(event) => setInstallationSiteId(event.target.value)}
          />
        </div>
        <div>
          <ElxPrimaryButton
            text="Submit"
            className="json-button"
            onClick={() => dispatch(dispatch(getContractChecker(contractId, installationSiteId, props.scope)))}
            disabled={!contractId}
          />
        </div>
      </div>
    </ElxScopedContainer>)
  }

  const CopyToClipboard = (textToCopy: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  };

  const OnClickCopyToClipboard = () => {
    const textForClipboard = JSON.stringify(contractChecker, null, 2);
    CopyToClipboard(textForClipboard);
  };


  const GetCopyToClipboardButton = () => {
    if (!isEmpty(contractChecker)) {
      return (
        <div>
          <ElxActionButton
            iconProps={{ iconName: 'Copy' }}
            id="agreement-validator-copy-to-clipboard"
            onClick={OnClickCopyToClipboard}
            text={"copy results to clipboard"}
          />
        </div>
      );
    }
  }

  return (
    <ElxScopedContainer scope={scope}>
      <div style={{ margin: 20 }}>
        {GetInputData()}
        {GetCopyToClipboardButton()}
        {isEmpty(contractChecker) ? (
            NoResults()
          ) :(
            <ContractValidatorTable validator={contractChecker} />
          )
        }
      </div>
    </ElxScopedContainer>
  )
}

export default ContractChecker
