import * as type from './types';
import { ResultStatus, IError } from '../../utility/errorHandling';

const initialState = {
  loading: false,
  allresults: undefined,
  workspaceList: [],
  id: '',
  lodId: '',
  accessibleWorkspaceIdList: [],
  accessibleCustomerIdList: [],
  msaWorkspaceIdList: [],
  idForCustomerAccess: '',
  entitlement: [],
  showRoles: [],
  assessments: [],
  emailJson: [],
  clearLodStatus: ResultStatus.Unknown,
  addAccessToWorkspaceStatus: ResultStatus.Unknown,
  removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
  addAccessToCustomerStatus: ResultStatus.Unknown,
  removeAccessFromCustomerStatus: ResultStatus.Unknown,
  addMsaToWorkspaceStatus: ResultStatus.Unknown,
  removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
  overrideAssessmentStatus: ResultStatus.Unknown,
  error: null,
};

export default function Operation(
  state = initialState,
  action: {
    type: any;
    loading: boolean;
    allresults: any;
    workspaceList: [];
    id: '';
    lodId: '';
    accessibleWorkspaceIdList: [];
    accessibleCustomerIdList: [];
    msaWorkspaceIdList: [];
    idForCustomerAccess: '';
    entitlement: any;
    showRoles: any;
    assessments: any;
    emailJson: [];
    clearLodStatus: ResultStatus;
    addAccessToWorkspaceStatus: ResultStatus;
    removeAccessFromWorkspaceStatus: ResultStatus;
    addAccessToCustomerStatus: ResultStatus;
    removeAccessFromCustomerStatus: ResultStatus;
    addMsaToWorkspaceStatus: ResultStatus;
    removeMsaFromWorkspaceStatus: ResultStatus;
    overrideAssessmentStatus: ResultStatus;
    error: IError;
  },
) {
  switch (action.type) {
    // getSearchResults
    case type.GET_SEARCH_RESULTS_REQUESTED:
      return {
        ...state,
        loading: true,
        clearLodStatus: ResultStatus.Unknown,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.GET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allresults: action.allresults,
        error: initialState.error,
      };
    case type.GET_SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        allresults: initialState.allresults,
      };

    // getCustomerWorkspaces
    case type.GET_CUSTOMER_WORKSPACES_REQUESTED:
      return {
        ...state,
        loading: true,
        clearLodStatus: ResultStatus.Unknown,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.GET_CUSTOMER_WORKSPACES_SUCCESS:
      return {
        ...state,
        loading: false,
        workspaceList: action.workspaceList,
        error: initialState.error,
      };
    case type.GET_CUSTOMER_WORKSPACES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        workspaceList: initialState.workspaceList,
      };

    // clearLOD
    case type.CLEAR_LOD_REQUESTED:
      return {
        ...state,
        loading: true,
        clearLodStatus: ResultStatus.Unknown,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.CLEAR_LOD_SUCCESS:
      return {
        ...state,
        loading: false,
        clearLodStatus: ResultStatus.Success,
        error: initialState.error,
      };
    case type.CLEAR_LOD_FAILURE:
      return {
        ...state,
        loading: false,
        clearLodStatus: ResultStatus.Failure,
        error: action.error,
      };

    // addAccessToWorkspace
    case type.WORKSPACE_ADD_ACCESS_REQUESTED:
      return {
        ...state,
        loading: true,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        clearLodStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.WORKSPACE_ADD_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addAccessToWorkspaceStatus: ResultStatus.Success,
        id: ((action as unknown) as type.IWorkspaceAddAccessSuccess).id,
        accessibleWorkspaceIdList: (state.accessibleWorkspaceIdList as string[]).concat([
          ((action as unknown) as type.IWorkspaceAddAccessSuccess).id,
        ]),
        error: initialState.error,
      };
    case type.WORKSPACE_ADD_ACCESS_FAILURE:
      return {
        ...state,
        loading: false,
        addAccessToWorkspaceStatus: ResultStatus.Failure,
        error: action.error,
        id: initialState.id,
        accessibleWorkspaceIdList: state.accessibleWorkspaceIdList,
      };

    // removeAccessFromWorkspace
    case type.WORKSPACE_REMOVE_ACCESS_REQUESTED:
      return {
        ...state,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        clearLodStatus: ResultStatus.Unknown,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
        loading: true,
      };
    case type.WORKSPACE_REMOVE_ACCESS_SUCCESS:
      const workspaceRemoveAccessAction = (action as unknown) as type.IWorkspaceRemoveAccessSuccess;
      return {
        ...state,
        removeAccessFromWorkspaceStatus: ResultStatus.Success,
        loading: false,
        id: workspaceRemoveAccessAction.id,
        accessibleWorkspaceIdList: (state.accessibleWorkspaceIdList as string[]).filter(
          x => x !== workspaceRemoveAccessAction.id,
        ),
        error: initialState.error,
      };
    case type.WORKSPACE_REMOVE_ACCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        removeAccessFromWorkspaceStatus: ResultStatus.Failure,
        id: initialState.id,
        accessibleWorkspaceIdList: state.accessibleWorkspaceIdList,
      };

    // addAccessToCustomer
    case type.CUSTOMER_ADD_ACCESS_REQUESTED:
      return {
        ...state,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        loading: true,
        id: ((action as unknown) as type.ICustomerAddAccessPayload).id,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        clearLodStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.CUSTOMER_ADD_ACCESS_SUCCESS:
      const customerAddAccessAction = (action as unknown) as type.ICustomerAddAccessSuccess;
      return {
        ...state,
        loading: false,
        addAccessToCustomerStatus: ResultStatus.Success,
        idForCustomerAccess: customerAddAccessAction.idForCustomerAccess,
        accessibleCustomerIdList: (state.accessibleCustomerIdList as string[]).concat([
          customerAddAccessAction.id,
        ]),
        error: initialState.error,
      };
    case type.CUSTOMER_ADD_ACCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        addAccessToCustomerStatus: ResultStatus.Failure,
        idForCustomerAccess: initialState.idForCustomerAccess,
        accessibleCustomerIdList: state.accessibleCustomerIdList,
      };

    // removeAccessFromCustomer
    case type.CUSTOMER_REMOVE_ACCESS_REQUESTED:
      return {
        ...state,
        loading: true,
        clearLodStatus: ResultStatus.Unknown,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.CUSTOMER_REMOVE_ACCESS_SUCCESS:
      const customerRemoveAccessAction = (action as unknown) as type.ICustomerRemoveAccessSuccess;
      return {
        ...state,
        loading: false,
        removeAccessFromCustomerStatus: ResultStatus.Success,
        id: customerRemoveAccessAction.id,
        accessibleCustomerIdList: (state.accessibleCustomerIdList as string[]).filter(
          x => x !== customerRemoveAccessAction.id,
        ),
        error: initialState.error,
      };
    case type.CUSTOMER_REMOVE_ACCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        removeAccessFromCustomerStatus: ResultStatus.Failure,
        id: initialState.id,
        accessibleCustomerIdList: state.accessibleCustomerIdList,
      };

    // getEntitlements
    case type.GET_USER_ENTITLEMENTS_REQUESTED:
      return {
        ...state,
        loading: true,
        clearLodStatus: ResultStatus.Unknown,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.GET_USER_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        entitlement: ((action as unknown) as type.IUserEntitlementsSuccess).entitlement,
        error: initialState.error,
        clearLodStatus: initialState.clearLodStatus,
      };
    case type.GET_USER_ENTITLEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        entitlement: initialState.entitlement,
        clearLodStatus: initialState.clearLodStatus,
      };

    // getShowRoles
    case type.GET_SHOW_ROLES_REQUESTED:
      return {
        ...state,
        loading: true,
        clearLodStatus: ResultStatus.Unknown,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.GET_SHOW_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        showRoles: action.showRoles,
        error: initialState.error,
      };
    case type.GET_SHOW_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        showRoles: initialState.showRoles,
      };

    // getAssessment
    case type.GET_ASSESSMENT_REQUESTED:
      return {
        ...state,
        loading: true,
        //assessments: [],
      };
    case type.GET_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        assessments: action.assessments,
        error: initialState.error,
      };
    case type.GET_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        assessments: initialState.assessments,
      };

    // overrideAssessment
    case type.OVERRIDE_ASSESSMENT_REQUESTED:
      return {
        ...state,
        overrideAssessmentResultStatus: ResultStatus.Unknown,
        loading: true,
      };
    case type.OVERRIDE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        overrideAssessmentResultStatus: ResultStatus.Success,
        loading: false,
        error: initialState.error,
      };
    case type.OVERRIDE_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: false,
        overrideAssessmentResultStatus: ResultStatus.Failure,
        error: action.error,
      };

    // getEmailVerification
    case type.EMAIL_VERIFICATION_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case type.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        emailJson: action.emailJson,
        error: initialState.error,
      };
    case type.EMAIL_VERIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        emailJson: initialState.emailJson,
      };

    // addMsaToWorkspace
    case type.WORKSPACE_ADD_MSA_REQUESTED:
      return {
        ...state,
        loading: true,
        clearLodStatus: ResultStatus.Unknown,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        removeAccessFromCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.WORKSPACE_ADD_MSA_SUCCESS:
      return {
        ...state,
        loading: false,
        addMsaToWorkspaceStatus: ResultStatus.Success,
        id: ((action as unknown) as type.IWorkspaceAddMsaSuccess).id,
        msaWorkspaceIdList: (state.msaWorkspaceIdList as string[]).concat([
          ((action as unknown) as type.IWorkspaceAddMsaSuccess).id,
        ]),
        error: initialState.error,
      };
    case type.WORKSPACE_ADD_MSA_FAILURE:
      return {
        ...state,
        loading: false,
        addMsaToWorkspaceStatus: ResultStatus.Failure,
        error: action.error,
        id: initialState.id,
        msaWorkspaceIdList: state.msaWorkspaceIdList,
      };

    // removeMsaFromCustomer
    case type.WORKSPACE_REMOVE_MSA_REQUESTED:
      return {
        ...state,
        loading: true,
        clearLodStatus: ResultStatus.Unknown,
        addAccessToWorkspaceStatus: ResultStatus.Unknown,
        removeAccessFromWorkspaceStatus: ResultStatus.Unknown,
        addAccessToCustomerStatus: ResultStatus.Unknown,
        addMsaToWorkspaceStatus: ResultStatus.Unknown,
        removeMsaFromWorkspaceStatus: ResultStatus.Unknown,
      };
    case type.WORKSPACE_REMOVE_MSA_SUCCESS:
      const msaRemoveAccessAction = (action as unknown) as type.IWorkspaceRemoveMsaPayload;
      return {
        ...state,
        loading: false,
        id: msaRemoveAccessAction.id,
        msaWorkspaceIdList: (state.msaWorkspaceIdList as string[]).filter(
          x => x !== msaRemoveAccessAction.id,
        ),
        error: initialState.error,
        removeMsaFromWorkspaceStatus: ResultStatus.Success,
      };
    case type.WORKSPACE_REMOVE_MSA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        id: initialState.id,
        msaWorkspaceIdList: state.msaWorkspaceIdList,
        removeMsaFromWorkspaceStatus: ResultStatus.Failure,
      };

    default:
      return state;
  }
}
