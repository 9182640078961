import { DocumentCardTitle, IStackTokens, mergeStyles, Stack } from 'office-ui-fabric-react';
import {IAttachedToWorkspace, IContractCheckerPayload, IPackageServiceOffering} from '../../../store/types';

const groupTokens: IStackTokens = {
  childrenGap: '1',
};
const itemStyles1 = mergeStyles({
  width: '30%',
});
const itemStyles2 = mergeStyles({
  width: '15%',
});
const itemStyles3 = mergeStyles({
  width: '100%',
});
const groupStyles = mergeStyles({
  marginBottom: '16px',
});

export const ValidatorDetails = (props: { validator: IContractCheckerPayload }) => {
  const { AttachedToCustomers, AttachedToWorkspaces, ContractId, 
    InstallationSiteId, CurrentContractType, CurrentCountOfPackages, 
    CurrentCountOfContacts, CurrentCountOfContactsDistinct, RenewedFromContracts, HasErrors, HasWarnings, IsActive,
    IsSupportEnabled, IsMultiYear, IsMultiCountry, ReportDateUtc, PackageServiceOfferings } = props.validator;

  const GetNoResults = (message: string) => {
    return (
      <b style={{ color: '#605E5C' }}>
        { message }
      </b>
    )
  }

  const GetCustomersDisplay = AttachedToCustomers?.length ? AttachedToCustomers.map((customerName: string) => {
    return (
      <Stack.Item className={itemStyles1}>
        <b>CUSTOMER:</b> {customerName}
      </Stack.Item>
    );
  }) : GetNoResults('The agreement is not attached to any customers.');

  const GetWorkspacesDisplay = AttachedToWorkspaces?.length ? AttachedToWorkspaces.map((workspace: IAttachedToWorkspace) => {
    return (
      <Stack.Item className={itemStyles3}>
        <b>WORKSPACE:</b> {workspace.WorkspaceId}
        <b> PACKAGE:</b> {workspace.PackageId}
      </Stack.Item>
    )
  }) : GetNoResults('The agreement is not attached to any workspaces.');

  const GetPackageServiceOfferingsDisplay = PackageServiceOfferings?.length ? PackageServiceOfferings.map((item: IPackageServiceOffering) => {
    return (
        <Stack.Item className={itemStyles3}>
          <b>PACKAGE:</b> {item.PackageId}
          <b> SERVICE OFFERING:</b> {item.ServiceOfferingId}
        </Stack.Item>
    )
  }) : GetNoResults('There are no package service offerings to display.');

  return (
    <>
      <Stack className={groupStyles}>
        <DocumentCardTitle
          className="customer-contract-title"
          title="CONTRACT VALIDATOR DETAILS"
          shouldTruncate
        />
        <Stack
          horizontal
          tokens={groupTokens}
          wrap>
          <Stack.Item className={itemStyles2}>
            <b>CONTRACT ID:</b> {ContractId}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>INSTALLATION SITE ID:</b> {InstallationSiteId}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>HAS ERRORS:</b> {HasErrors.toString()}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>HAS WARNINGS:</b> {HasWarnings.toString()}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>IS ACTIVE:</b> {IsActive.toString()}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>AGREEMENT TYPE:</b> {CurrentContractType}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>COUNT OF PACKAGES:</b> {CurrentCountOfPackages}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>TOTAL COUNT OF CONTACTS:</b> {CurrentCountOfContacts}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>DISTINCT COUNT OF CONTACTS:</b> {CurrentCountOfContactsDistinct}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>RENEWED FROM:</b> {RenewedFromContracts ?? 'unknown'}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>IS SUPPORT ENABLED:</b> {IsSupportEnabled.toString()}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>IS MULTI YEAR:</b> {IsMultiYear.toString()}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>IS MULTI COUNTRY:</b> {IsMultiCountry?.toString() ?? 'unknown'}
          </Stack.Item>
          <Stack.Item className={itemStyles2}>
            <b>REPORT DATE UTC: </b> 
            {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit'}).format(new Date(ReportDateUtc))}
          </Stack.Item>
        </Stack>
      </Stack>
      <Stack className={groupStyles}>
        <DocumentCardTitle
          className="customer-contract-title"
          title="ATTACHED TO CUSTOMERS"
          shouldTruncate
        />
        <Stack
          horizontal
          tokens={groupTokens}
          wrap>
          {GetCustomersDisplay}
        </Stack>
      </Stack>
      <Stack className={groupStyles}>
        <DocumentCardTitle
          className="customer-contract-title"
          title="ATTACHED TO WORKSPACES"
          shouldTruncate
        />
        <Stack
          horizontal
          tokens={groupTokens}
          wrap>
          {GetWorkspacesDisplay}
        </Stack>
      </Stack>
      <Stack className={groupStyles}>
        <DocumentCardTitle
            className="customer-contract-title"
            title="PACKAGE SERVICE OFFERINGS"
            shouldTruncate
        />
        <Stack
            horizontal
            tokens={groupTokens}
            wrap>
          {GetPackageServiceOfferingsDisplay}
        </Stack>
      </Stack>
    </>
  )
}

export default ValidatorDetails
