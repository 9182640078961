export const NoResults = () => {
  return (
    <div className="default-contract">
      <b style={{ color: '#605E5C', letterSpacing: 2 }}>
        THERE ARE NO RESULTS TO DISPLAY. START SEARCHING !{' '}
      </b>
    </div>
  )
}

export default NoResults
