import { IModule } from "@elixir/fx";
import Home from "../Home";

export const homeModule: IModule = {
  name: "Home",
  env: "development",
  route: {
    index: "Home",
    name: "Home",
    iconProps: {
      iconName: "Home",
    },
    path: "/home",
    isRouteable: true,
    exact: true,
    inSidebar: true,
    component: Home,
  },
};
