import { IScope } from '@elixir/fx';
import { Action } from '@reduxjs/toolkit';

export const GET_SCRIPTS_REQUESTED = 'GET_SCRIPTS_REQUESTED';
export const GET_SCRIPTS_SUCCESS = 'GET_SCRIPTS_SUCCESS';
export const GET_SCRIPTS_FAILURE = 'GET_SCRIPTS_FAILURE';
export const RUN_SCRIPT_REQUESTED = 'RUN_SCRIPT_REQUESTED';
export const RUN_SCRIPT_SUCCESS = 'RUN_SCRIPT_SUCCESS';
export const RUN_SCRIPT_FAILURE = 'RUN_SCRIPT_FAILURE';
export const CANCEL_SCRIPT_REQUESTED = 'CANCEL_SCRIPT_REQUESTED';
export const CANCEL_SCRIPT_SUCCESS = 'CANCEL_SCRIPT_SUCCESS';
export const CANCEL_SCRIPT_FAILURE = 'CANCEL_SCRIPT_FAILURE';
export const GET_TRACES_REQUESTED = 'GET_TRACES_REQUESTED';
export const GET_TRACES_SUCCESS = 'GET_TRACES_SUCCESS';
export const GET_TRACES_FAILURE = 'GET_TRACES_FAILURE';
export const CLEAR_STATE = 'CLEAR_STATE';

export interface IGetScriptsPayload extends Action<typeof GET_SCRIPTS_REQUESTED> {
}

export interface IGetScriptsSuccess extends Action<typeof GET_SCRIPTS_SUCCESS> {
    scriptList: Array<Object>;
}

export interface IRunScriptPayload extends Action<typeof RUN_SCRIPT_REQUESTED> {
  scriptName: string;
  scriptJson: string;
}

export interface IRunScriptSuccess extends Action<typeof RUN_SCRIPT_SUCCESS> {
  scriptSuccessID: string;
}

export interface ICancelScriptPayload extends Action<typeof CANCEL_SCRIPT_REQUESTED> {
  scriptName: string;
}

export interface ICancelScriptSuccess extends Action<typeof CANCEL_SCRIPT_SUCCESS> {
  scriptName: string;
  scope: IScope;
}

export interface IGetTracesPayload extends Action<typeof GET_TRACES_REQUESTED> {
  scriptName: string;
  startDate: string;
  endDate: string;
}

export interface IGetTracesSuccess extends Action<typeof GET_TRACES_SUCCESS> {
  traces: Array<Object>
}

export interface IScriptsColumn {
	[key: string]: string;
}

export interface ISettingsColumn {
	[key: string]: string;
}