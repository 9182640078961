import * as type from './types';
import { IScope } from '@elixir/fx';
import { IFeature } from './types/variabletypes';

export function getFeatures(scope: IScope) {
	return {
		type: type.GET_FEATURES_REQUESTED,
		scope,
		payload: {
			scope,
		},
	};
}

export function saveFeature(feature: IFeature, scope: IScope) {
	return {
		type: type.SAVE_FEATURE_REQUESTED,
		feature,
		scope,
		payload: {
			feature,
			scope,
		},
	};
}
