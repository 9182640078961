import * as type from './types'
import { IScope } from '@elixir/fx'

export function getContract(contractId: any, scope: IScope) {
  return {
    type: type.GET_CONTRACT_REQUESTED,
    contractId,
    scope,
    payload: {
      contractId,
      scope,
    },
  }
}

export function getContractDetails(contractId: any, scope: IScope) {
  return {
    type: type.GET_CONTRACT_DETAILS_REQUESTED,
    contractId,
    payload: {
      contractId,
      scope,
    },
  }
}

export function getMSContact(packageId: string, scope: IScope) {
  return {
    type: type.GET_MS_CONTACT_REQUESTED,
    packageId,
    scope,
    payload: {
      scope,
      packageId,
    },
  }
}

export function getServices(packageId: string, scope: IScope) {
  return {
    type: type.GET_SERVICES_REQUESTED,
    packageId,
    scope,
    payload: {
      scope,
      packageId,
    },
  }
}

export function getContacts(packageId: string, scope: IScope) {
  return {
    type: type.GET_CONTACTS_REQUESTED,
    packageId,
    scope,
    payload: {
      scope,
      packageId,
    },
  }
}

export function sendSccEvent(
  contractId: string,
  scheduleId: string,
  installationId: string,
  scope: IScope,
) {
  return {
    type: type.SEND_SCC_EVENT_REQUESTED,
    contractId,
    scheduleId,
    installationId,
    scope,
    payload: {
      contractId,
      scheduleId,
      installationId,
      scope,
    },
  }
}

export function getContractChecker(contractId: any, installationSiteId: any, scope: IScope) {
  return {
    type: type.GET_CONTRACT_CHECKER_REQUESTED,
    contractId,
    installationSiteId,
    payload: {
      contractId,
      installationSiteId,
      scope,
    },
  }
}

export function getContractByAliasDetails(csamAlias: any, installationID: any, scope: IScope) {
  return {
    type: type.GET_CONTRACT_BY_ALIAS_REQUESTED,
    csamAlias,
    installationID,
    payload: {
      csamAlias,
      installationID,
      scope,
    },
  }
}