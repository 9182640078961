import { Action } from '@reduxjs/toolkit';
import { IScope } from '@elixir/fx';
import { ResultStatus } from '../../utility/errorHandling'
import { IFasSettingsModel } from './types/variabletypes';

export const GET_SETTINGS_REQUESTED = 'GET_SETTINGS_REQUESTED';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';
export const SAVE_SETTING_SUCCESS = 'SAVE_SETTING_SUCCESS';
export const SAVE_SETTING_REQUESTED = 'SAVE_SETTING_REQUESTED';
export const SAVE_SETTING_FAILURE = 'SAVE_SETTING_FAILURE';

export interface IGetSettingsPayload extends Action<typeof GET_SETTINGS_REQUESTED> {
}

export interface IGetSettingsSuccess extends Action<typeof GET_SETTINGS_SUCCESS> {
	settingList: Array<Object>;
}

export interface ISaveSettingPayload
	extends Action<typeof SAVE_SETTING_REQUESTED> {
	setting: IFasSettingsModel;
	scope: IScope;
}

export interface ISaveSettingSuccess
  extends Action<typeof SAVE_SETTING_SUCCESS> {
  saveSettingStatus: ResultStatus,
}
