import * as type from './types';
import { ResultStatus, IError } from '../../utility/errorHandling'

const initialState = {
	settingList: [],
	loading: false,
	error: null,
	settingServiceName: '',
	settingValue: '',
	settingId: '',
	isActive: true,
	LUID: '',
	saveSettingStatus: ResultStatus.Unknown,
};

export default function script(
	state = initialState,
	action: {
		type: any;
		loading: boolean,
		error: IError,
		LUID: string;
		setting: any;
		settingList: [],
		settingServiceName: string;
		settingValue: string;
		settingId: string;
		isActive: boolean;
		saveSettingStatus: ResultStatus;
	}
) {
	switch (action.type) {
		case type.GET_SETTINGS_REQUESTED:
			return {
				...state,
				loading: true,
				error: initialState.error
			};
		case type.GET_SETTINGS_SUCCESS:
			return {
				...state,
				loading: false,
				settingList: action.settingList,
				error: initialState.error,
				saveSettingStatus: initialState.saveSettingStatus
			};
		case type.GET_SETTINGS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
				settingList: initialState.settingList,
				saveSettingStatus: initialState.saveSettingStatus
			};
		case type.SAVE_SETTING_REQUESTED:
			return {
				...state,
				loading: true,
				saveSettingStatus: ResultStatus.Unknown,
				error: initialState.error
			};
		case type.SAVE_SETTING_SUCCESS:
			return {
				...state,
				loading: false,
				saveSettingStatus: ResultStatus.Success,
				error: initialState.error
			};
		case type.SAVE_SETTING_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
				saveSettingStatus: ResultStatus.Failure,
			};
		default:
			return state;
	}
}
