import { AxiosError } from 'axios';

export const enum ResultStatus {
  Unknown,
  Success,
  Failure
}

export const enum ErrorType {
  None,
  Permission,
  Other
}

export interface IError {
  errorType: ErrorType;
  errorMessage?: string;
  data?: string;
}


export function getApiErrorAction(
  err: any,
  errorReturnType: string,
  customErrorMessageMap?: Map<number, string>
): { type: string, error: IError } {
  const axiosError = err as AxiosError;
  const status = err?.response?.status;
  if (status === 403) {
    // Required role is in form "Orion.Ops.RoleName".  
    // Take the last part and add a space between words, if necessary.
    const requiredRole: string = axiosError.response?.headers['required-role'] ?? '';
    const roleName = getRoleName(requiredRole);
    return {
      type: errorReturnType,
      error: {
        errorType: ErrorType.Permission,
        data: roleName,
      } as IError
    };
  } else if (status === 400 && !customErrorMessageMap?.has(400)) {
    return {
      type: errorReturnType,
      error: {
        errorType: ErrorType.Other,
        errorMessage: `Bad request: ${axiosError?.response?.data ?? 'unknown'}`,
      } as IError
    }
  } else if (status === 404 && !customErrorMessageMap?.has(404) &&
      axiosError?.response?.data?.length > 0) {
    return {
      type: errorReturnType,
      error: {
        errorType: ErrorType.Other,
        errorMessage: axiosError?.response?.data,
      } as IError
    }
  } else if (status === 500 && !customErrorMessageMap?.has(500) &&
    axiosError?.response?.data?.length > 0) {
    return {
      type: errorReturnType,
      error: {
        errorType: ErrorType.Other,
        errorMessage: axiosError?.response?.data
      } as IError
    }
  } else {
    return {
        type: errorReturnType,
        error: {
          errorType: ErrorType.Other,
          errorMessage: customErrorMessageMap?.get(status) ||   // specific message for status
            customErrorMessageMap?.get(0) ||                    // specific message for any status
            axiosError?.message ||                              // returned message
            'An unexpected error occurred.'                     // generic message
        } as IError
      };
  }
}

function getRoleName(roleData: string): string {
  // roleData is in form "Orion.Ops.RoleName".  
  // Take the last part and add a space between words, if necessary.
  return roleData.replace(/.*?([A-Z]\w+?[a-z])([A-Z]\w+)?$/, '$1 $2').trimEnd()
}
