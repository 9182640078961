import React, { useEffect } from 'react';
import { isNull, uniqueId } from 'lodash';
import { useState } from 'react';
import { IFeature } from '../store/types/variabletypes';
import {
  ElxCardList,
  ElxPanel,
  ElxTagPicker,
  ElxTextField,
  IElxColumn,
  IScope,
  SelectionMode,
  TagContainerOrientation,
  ElxDropdown,
  ElxCopy,
  PanelSize,
  ElxTable,
  ITableAction,
  ElxIconButton,
  MessageBarType,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';
import { ResultStatus, ErrorType, IError } from '../../utility/errorHandling';
import { IFeatureColumn } from '../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { saveFeature } from '../store/action';
import { isEmpty } from 'lodash';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';
import { IInvariantString } from '../../../globalTypes/globalTypes';

interface IFeatureDetails {
  scope: IScope;
  selectedKey: string;
  featureList: IFeature[];
  userId: string;
  currentWorkspaceId: string;
}

const options: IDropdownOption[] = [
  { key: 0, text: 'On' },
  { key: 1, text: 'Off' },
  { key: 2, text: 'Pilot' },
  { key: 3, text: 'Deprecated' },
];
// const KeyOptions: IDropdownOption[] = [
// 	{ key: 'userid', text: 'UserId' },
// 	{ key: 'workspaceid', text: 'WorkspaceId' },
// 	{ key: 'others', text: 'Others' },
// ];

export const FeatureDetails = (props: IFeatureDetails) => {
  const dispatch = useDispatch();
  const flightKeyValue: { [key: string]: Array<any> } = {};
  const featureColumn: IFeatureColumn[] = [];
  const UserIDFeature = props.userId;
  const WorkspaceIDFeature = props.currentWorkspaceId;

  // Subscribe for data changes
  const saveFeatureStatus: ResultStatus = useSelector(
    (state: any) => state?.modules?.Features?.saveFeatureStatus,
  );
  const error: IError | undefined = useSelector((state: any) => state?.modules?.Features?.error);

  // Setup state variables
  const [openPanel, setOpenPanel] = useState(false);
  const [elementToEdit, setElementToEdit] = useState(undefined);
  const [elementToEditDesc, setElementToEditDesc] = useState(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [toggleUserId, setToggleUserId] = useState('');
  const [userIDForFeature, setUserIDForFeature] = React.useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [workspaceIDForFeature, setWorkspaceIDForFeature] = React.useState<string>('');

  // Callback action functions
  function onSaveFeature(element: any) {
    const ele = (element as unknown) as IFeature;
    dispatch(saveFeature(ele, props.scope));
  }

  // function KeySelectionDropDown(key: string) {
  // 	switch (key) {
  // 		case 'userid': {
  // 			return 'userid';
  // 		}
  // 		case 'workspaceid': {
  // 			return 'workspaceid';
  // 		}
  // 		default: {
  // 			return 'others';
  // 		}
  // 	}
  // }

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />,
            },
            props.scope,
          ),
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.',
            },
            props.scope,
          ),
        );
      }
    }

    // Check for success status from saveFeature
    else if (saveFeatureStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: 'Feature update succeeded',
          },
          props.scope,
        ),
      );
    }

    // Otherwise, clear any previous message
    else {
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there no features to display
  // eslint-disable-next-line array-callback-return
  if ((props?.featureList?.length ?? 0) === 0) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO RESULTS TO DISPLAY. SELECT A FEATURE!{' '}
        </b>
      </div>
    );
  }

  // Otherwise, show feature list
  else {
    // eslint-disable-next-line array-callback-return
    props.featureList?.map((x: any) => {
      const featureJSON = {
        Name: x?.Name ?? 'N/A',
        Description: x?.Description ?? 'N/A',
        Status: x?.Mode ?? 'N/A',
        CreatedDate: x?.Created.Date ?? 'N/A',
        UpdatedDate: x?.LastUpdated.Date ?? 'N/A',
        LastModifiedBy: x?.LastUpdatedBy ?? 'System Account',
        Element: x,
        title: x?.Description ?? 'N/A',
      };
      const FlightDetails: Array<any> = [];
      // eslint-disable-next-line array-callback-return
      x?.Flights?.map((data: any, index: number) => {
        FlightDetails.push(data);
      });
      flightKeyValue[x.Name] = FlightDetails;
      featureColumn.push(featureJSON);
    });
    return (
      <>
        <ElxTable
          items={featureColumn}
          columns={getColumns(props.selectedKey)}
          selectionMode={SelectionMode.none}
          actions={actions()}
        />
        <ElxPanel
          isOpen={openPanel}
          size={PanelSize.medium}
          onDismiss={() => {
            setOpenPanel(false);
            setElementToEdit(undefined);
            //setElementToEditDesc(undefined)
          }}
          className="feature-panel"
          styles={{
            body: {
              padding: '1rem',
              width: '800',
            },
          }}
          headerText={elementToEdit ? elementToEdit!['Name'] : ''}
          actions={[
            {
              key: 'Save',
              text: 'Save',
              isPrimary: true,
              onClick: () => {
                onSaveFeature(elementToEdit);
                setElementToEdit(elementToEdit);
                setElementToEditDesc(undefined);
                setOpenPanel(false);
              },
            },
            {
              key: 'Close',
              text: 'Dismiss',
              onClick: () => {
                setOpenPanel(false);
                setToggleUserId('');
              },
            },
          ]}>
          <div style={{ paddingLeft: 10, paddingRight: 20 }}>
            <ElxTextField
              role="features-description"
              defaultValue={elementToEditDesc ? elementToEditDesc!['Description'] : ''}
              label="DESCRIPTION"
              multiline={true}
              onChange={(event, newValue) => {
                setElementToEditDesc(originalElement => {
                  if (originalElement) {
                    const existingDescriptionObj = originalElement as any;
                    let finalUpdated: IInvariantString = {
                      Value: props.userId,
                      ValueLower: props.userId.toLowerCase(),
                    };
                    existingDescriptionObj.Description = newValue;
                    existingDescriptionObj.LastUpdatedBy = finalUpdated;
                    return existingDescriptionObj;
                  }
                  return originalElement;
                });
              }}
            />
            <br />
            <ElxDropdown
              placeholder="Select Status"
              label="STATUS"
              options={options}
              defaultSelectedKey={elementToEdit ? elementToEdit!['Mode'] : ''}
              onChange={(event, newValue) => {
                setElementToEditDesc(originalElement => {
                  if (originalElement) {
                    const existingStatusObj = originalElement as any;
                    let finalUpdated: IInvariantString = {
                      Value: props.userId,
                      ValueLower: props.userId.toLowerCase(),
                    };
                    existingStatusObj.Mode = newValue?.key;
                    existingStatusObj.LastUpdatedBy = finalUpdated;
                    return {
                      ...existingStatusObj,
                    };
                  }
                  return originalElement;
                });
              }}
            />

            {elementToEdit ? (
              elementToEdit!['Mode'] === 2 ? (
                <>
                  <br />
                  <ElxCopy
                    textToCopy={UserIDFeature}
                    onClick={() => setUserIDForFeature(userIDForFeature)}>
                    <div>
                      <label className="elx-label label-root">CURRENT USER ID</label>
                    </div>
                    <input
                      type="text"
                      disabled={true}
                      className="ms-TextField-field field-267"
                      aria-describedby="elx-textfield489-description"
                      aria-invalid="false"
                      value={UserIDFeature}
                    />
                  </ElxCopy>
                  <br />
                  <ElxCopy
                    textToCopy={WorkspaceIDFeature}
                    onClick={() => setWorkspaceIDForFeature(WorkspaceIDFeature)}>
                    <div>
                      <label className="elx-label label-root">
                        CURRENT WORKSPACE ID
                        <ElxIconButton
                          text="Get Access to Customer Workspace through Operation Search"
                          iconProps={{ iconName: 'Info' }}
                        />
                      </label>
                    </div>
                    <input
                      type="text"
                      disabled={true}
                      className="ms-TextField-field field-267"
                      aria-describedby="elx-textfield489-description"
                      aria-invalid="false"
                      value={WorkspaceIDFeature}
                    />
                  </ElxCopy>
                  <ElxCardList
                    listLabel="FLIGHT"
                    items={!isNull(elementToEdit!['Flights']) ? elementToEdit!['Flights'] : []}
                    disableActions={true}
                    onRenderItemBody={item => {
                      if (!item || isEmpty(item)) {
                        return null;
                      }
                      const flightItem = item as any;
                      return (
                        <>
                          <br />
                          {/* <ElxDropdown
													label='Key'
													className='flight-key-style'
													options={KeyOptions}
													defaultSelectedKey={
														!isNull(flightItem.Key.Value)
															?  KeySelectionDropDown(
																	flightItem.Key.Value.toLowerCase()
															  )
                                                             
															: ''
													}
												/> */}
                          <ElxTextField
                            label="Key"
                            value={!isNull(flightItem.Key.Value) ? flightItem.Key.Value : ''}
                            className="panel-name"
                            onChange={(event, newValue) => {
                              setElementToEdit(originalElement => {
                                if (originalElement) {
                                  const existingFlights = (originalElement as any).Flights;
                                  const flight = existingFlights.find(
                                    (flight: { id: any }) => flight.id === flightItem.id,
                                  );
                                  flight.Key.Value = newValue;
                                  return {
                                    ...(originalElement as any),
                                    Flights: existingFlights,
                                  };
                                }
                                return originalElement;
                              });
                            }}
                          />
                          <br />
                          <ElxTagPicker
                            label="Values"
                            placeholder="Add Multiple comma separated Values"
                            items={
                              !isNull(flightItem.Values)
                                ? flightItem.Values.map((data: { Value: any }, index: number) => ({
                                    key: index.toString(),
                                    text: data.Value,
                                  }))
                                : ''
                            }
                            onAddTag={tag => {
                              setElementToEdit(originalElement => {
                                if (originalElement) {
                                  const existingFlights = (originalElement as any).Flights;

                                  const split = tag.text.split(',');
                                  split.map(
                                    value =>
                                      (flightItem.Values = [
                                        ...flightItem.Values,
                                        { Value: value },
                                      ]),
                                  );
                                  return {
                                    ...(originalElement as any),
                                    Flights: existingFlights,
                                  };
                                }
                                return originalElement;
                              });
                            }}
                            onRemoveTag={tag => {
                              setElementToEdit(originalElement => {
                                if (originalElement) {
                                  const existingFlights = (originalElement as any).Flights;
                                  flightItem.Values = flightItem.Values.filter(
                                    (data: { Value: string }) => data.Value !== tag.text,
                                  );
                                  return {
                                    ...(originalElement as any),
                                    Flights: existingFlights,
                                  };
                                }
                                return originalElement;
                              });
                            }}
                            orientation={TagContainerOrientation.Vertical}
                          />
                        </>
                      );
                    }}
                    addButtonProps={{
                      label: 'Add Flight',
                      onClick: () => {
                        const temp = {
                          ...(elementToEdit as any),
                          Flights: [
                            ...((elementToEdit as any).Flights ?? []),
                            {
                              id: uniqueId('new'),
                              Key: { Value: '' },
                              Values: [],
                            },
                          ],
                        };
                        setElementToEdit(temp);
                        // setElementToEditDesc(originalElement => {
                        // 	if (originalElement) {
                        // 		return {
                        // 			...(originalElement as any),
                        // 			Flights: [
                        // 				...((originalElement as any).Flights ?? []),
                        // 				{
                        // 					id: uniqueId('new'),
                        // 					Key: { Value: '' },
                        // 					Values: [],
                        // 				},
                        // 			],
                        // 		};
                        // 	}
                        // 	return originalElement;
                        // }
                      },
                    }}
                  />
                </>
              ) : (
                <>
                  {' '}
                  <br />{' '}
                </>
              )
            ) : (
              <>
                {' '}
                <br />{' '}
              </>
            )}
          </div>
        </ElxPanel>
      </>
    );
  }

  function actions(): ITableAction[] {
    return [
      {
        key: 'Edit',
        text: 'Edit',
        disableBulkAction: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onAction: (item: any) => {
          setElementToEdit(
            elementToEdit && item['Element']['Description'] === elementToEdit!['Description']
              ? elementToEdit
              : item['Element'],
          );
          setElementToEditDesc(
            elementToEditDesc &&
              item['Element']['Description'] === elementToEditDesc!['Description']
              ? elementToEditDesc
              : item['Element'],
          );
          setOpenPanel(true);
        },
        iconProps: { iconName: 'EditSolid12' },
        trackingContext: {
          label: 'CustomActionLabel',
        },
      },
    ];
  }
};

function getColumns(selectedKey: string): IElxColumn[] {
  return [
    {
      key: '2',
      name: 'Name',
      fieldName: 'Name',
      minWidth: 20,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: '3',
      name: 'Description',
      fieldName: 'Description',
      minWidth: 220,
      //maxWidth:500,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: '4',
      name: 'Status',
      fieldName: 'Status',
      minWidth: 50,
      // maxWidth: 70,
      isResizable: true,
      onRender: item => {
        return <span>{options[item['Status']].text}</span>;
      },
    },
    {
      key: '5',
      name: 'Created Date',
      fieldName: 'CreatedDate',
      minWidth: 90,
      //maxWidth: 90,
      isResizable: true,
      isMultiline: true,
      onRender: item => {
        return (
          <span>
            {new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              timeZone: 'UTC',
            }).format(new Date(item['CreatedDate']))}
          </span>
        );
      },
    },
    {
      key: '6',
      name: 'Updated Date',
      fieldName: 'UpdatedDate',
      minWidth: 90,
      //maxWidth:90,
      isResizable: true,
      isMultiline: true,
      onRender: item => {
        return (
          <span>
            {new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              timeZone: 'UTC',
            }).format(new Date(item['UpdatedDate']))}
          </span>
        );
      },
    },
    {
      key: '7',
      name: 'Modified By',
      fieldName: 'LastModifiedBy',
      minWidth: 90,
      //maxWidth:90,
      isResizable: true,
      isMultiline: true,
      onRender: item => {
        return <span>{item.LastModifiedBy.Value ?? 'System Account'}</span>;
      },
    },
  ];
}

export default FeatureDetails;
