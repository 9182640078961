import { Http, Logger, containerManagedAction, IScopedPayload } from '@elixir/fx';
import {
  GET_SETTINGS_REQUESTED,
  SAVE_SETTING_REQUESTED,
  IGetSettingsPayload,
  ISaveSettingPayload,
} from './types';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, takeEvery, put } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { getConfig } from '../../../OpsConfig';
import { getApiErrorAction, ErrorType, IError } from '../../utility/errorHandling';

// NOT USED
//export const NotAvailableMessage: IMessage = {
//  message:
//    'Common Solutions editing is temporarily unavailable.  Please try again in several minutes.',
//  type: MessageBarType.blocked,
//  isMultiline: false,
//}

function* getSettingList(
  action: PayloadAction<IScopedPayload & IGetSettingsPayload>,
): SagaIterator {
  yield put({ type: 'GET_SETTINGS_SUCCESS', settingList: {} });
  try {
    const config = yield call(getConfig);
    let settingPath;
    settingPath = `${config.apiBaseURL}/setting/GetSettingList`;
    const settings = yield call(Http.get, action, settingPath);
    yield put({
      type: 'GET_SETTINGS_SUCCESS',
      settingList: settings.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_SETTINGS_FAILURE'));
    Logger.error(err);
  } finally {
  }
}

function* saveSettings(action: PayloadAction<IScopedPayload & ISaveSettingPayload>): SagaIterator {
  try {
    const config = yield call(getConfig);
    let settingPath = `${config.apiBaseURL}/setting/UpsertSetting`;

    const setting = yield call(Http.post, action, settingPath, action.payload.setting);
    // save was successful if we got an updated setting back
    if (!isEmpty(setting.data)) {
      yield put({
        type: 'SAVE_SETTING_SUCCESS',
      });
    } else {
      yield put({
        type: 'SAVE_SETTING_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: 'Setting update failed',
        } as IError,
      });
    }
  } catch (err) {
    yield put(getApiErrorAction(err, 'SAVE_SETTING_FAILURE'));
    Logger.error(err);
  } finally {
  }
}

export function* SettingSagas(): Generator {
  yield takeEvery(GET_SETTINGS_REQUESTED, containerManagedAction(getSettingList));
  yield takeLatest(SAVE_SETTING_REQUESTED, containerManagedAction(saveSettings));
}
export default SettingSagas;
