import '../../../styling/css/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { IStackTokens, Link, mergeStyles } from '@fluentui/react';
import { isEmpty, isEqual } from 'lodash';
import Collapsible from 'react-collapsible';
import { DocumentCard } from 'office-ui-fabric-react/lib/DocumentCard';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { Stack } from '@fluentui/react';
import { useEffect } from 'react';
import { getShowRoles } from '../store/action';
import {
  IScope,
  MessageBarType,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';
import { ErrorType, IError } from '../../utility/errorHandling';
import { IRolesColumns } from '../store/types';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';

export const cardContentStackTokens: IStackTokens = {
  childrenGap: 'l1',
};
export const cardContentStackStyles2 = mergeStyles({
  width: 350,
});

export const ShowRoles = (props: { UserID: string; scope: IScope }) => {
  const dispatch = useDispatch();
  const rolesColumns: IRolesColumns[] = [];
  const claims: any = {};

  // Subscribe for data changes
  const error: IError | undefined = useSelector((state: any) => state?.modules?.Operations?.error);
  const roles: Array<object> = useSelector((state: any) => state?.modules?.Operations?.showRoles);

  // Request role data
  useEffect(() => {
    dispatch(getShowRoles(props.UserID, props.scope));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Do something with any returned data

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />,
            },
            props.scope,
          ),
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.',
            },
            props.scope,
          ),
        );
      }
    } else {
      // Clear any existing error message
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there is no role data to display
  if (roles === undefined || isEqual(roles, {}) || isEmpty(roles)) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO ROLES & CLAIMS TO DISPLAY.!{' '}
        </b>
      </div>
    );
  }

  // Otherwise, display role data
  else {
    // eslint-disable-next-line array-callback-return
    roles.map((x: any, index: any) => {
      const rolesJSON = {
		KEY: `${x.roleId}-_${index}`,
        NAME: x.roleName,
        ID: x.roleId,
        SCOPE: x.scope,
        AFFINITY: x.affinity,
      };
      if (x?.claimsForRole) {
        claims[`${x.roleId}`] = x.claimsForRole;
      }
      rolesColumns.push(rolesJSON);
    });

    return (
      <>
        {rolesColumns.map((column: IRolesColumns, index: number) => {
          return (
            <DocumentCard className="sub-card-roles">
              <Stack horizontal wrap>
                <Stack horizontal wrap>
                  {Object.entries(column).map(([key, value], index) => {
                    const link = `${'Workspace'}?id=${value}`;
                    return key !== 'AFFINITY' ? (
                      <Stack horizontal tokens={cardContentStackTokens} wrap>
                        <Stack className={cardContentStackStyles2}>
                          <Stack.Item>
                            <b>{key}</b>: {value}
                          </Stack.Item>
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack.Item>
                        <b>{key}</b>:&nbsp;
                        <Link
                          href={`${link}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ fontSize: 14, fontWeight: 600 }}>
                          {value}
                        </Link>
                      </Stack.Item>
                    );
                  })}
                  <br />
                  <br />
                </Stack>
                <Stack horizontal tokens={cardContentStackTokens} wrap>
                  <Collapsible
                    trigger="SHOW CLAIMS"
                    className="collapsibleStyles"
                    transitionTime={600}
                    triggerStyle={{
                      fontWeight: 600,
                      color: 'hsl(207deg 85% 43%)',
                      cursor: 'Pointer',
                    }}>
                    {claims[column.ID].map((claim: any, index: any) => {
                      return (
                        <div className="show-roles-packages">
                          <Stack
                            horizontal
                            tokens={cardContentStackTokens}
                            style={{ marginLeft: 20 }}>
                            <Stack.Item style={{ fontSize: 14 }}>{claim}</Stack.Item>
                          </Stack>
                        </div>
                      );
                    })}
                  </Collapsible>
                </Stack>
              </Stack>
            </DocumentCard>
          );
        })}
      </>
    );
  }
};

export default ShowRoles;
