import {
  IScope,
  ElxPanel,
  ElxTextField,
  IElxColumn,
  ElxTable,
  SelectionMode,
  ITableAction,
  MessageBarType,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISettings, IFasSettingsModel } from '../store/types/variabletypes';
import { saveSetting } from '../store/action';
import { isEmpty } from 'lodash';
import '../styling/css/style.css';
import { ISettingsColumn } from '../../scripts/store/types';
import { ResultStatus, ErrorType, IError } from '../../utility/errorHandling';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';
import { IInvariantString } from '../../../globalTypes/globalTypes';
import { Resources } from '../../../resources';

interface ISettingsDetails {
  scope: IScope;
  selectedKey: string;
  settingList: ISettings[];
  userId: string;
}
export const SettingsDetails = (props: ISettingsDetails) => {
  const dispatch = useDispatch();
  const settingColumn: ISettingsColumn[] = [];

  // Subscribe for data changes
  const saveSettingStatus: ResultStatus = useSelector(
    (state: any) => state?.modules?.Settings?.saveSettingStatus,
  );
  const error: IError | undefined = useSelector((state: any) => state?.modules?.Settings?.error);

  // Setup state variables
  const [openPanel, setOpenPanel] = useState(false);
  const [elementToEdit, setElementToEdit] = useState<IFasSettingsModel>({
    Id: '',
    ServiceName: '',
    IsActive: false,
    Name: {
      Value: '',
      ValueLower: '',
    },
    Value: {
      Value: '',
      ValueLower: '',
    },
  });

  // Callback action functions
  function onSaveSetting(element: IFasSettingsModel) {
    dispatch(saveSetting(element, props.scope));
  }

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />,
            },
            props.scope,
          ),
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: MessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.',
            },
            props.scope,
          ),
        );
      }
    }

    // Check for success status from saveFeature
    else if (saveSettingStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: MessageBarType.success,
            message: 'Setting update succeeded',
          },
          props.scope,
        ),
      );
    }

    // Otherwise, clear any previous message
    else {
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there no settings to display
  // eslint-disable-next-line array-callback-return
  if ((props?.settingList?.length ?? 0) === 0) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO RESULTS TO DISPLAY. SELECT A SETTING!{' '}
        </b>
      </div>
    );
  }

  // Otherwise, show setting list
  else {
    // eslint-disable-next-line array-callback-return
    props.settingList?.map((x: any) => {
      const featureJSON = {
        Name: x?.Name.Value ?? 'N/A',
        Value: x?.Value.Value ?? 'N/A',
        CreatedDate: x?.Created?.Date ?? 'N/A',
        UpdatedDate: x?.LastUpdated?.Date ?? 'N/A',
        LastUpdatedBy: x?.LastUpdatedBy ?? 'System Account',
        element: x,
      };
      settingColumn.push(featureJSON);
    });
    return (
      <>
        <ElxTable
          items={settingColumn}
          columns={getColumns()}
          selectionMode={SelectionMode.none}
          actions={actions()}
        />

        <ElxPanel
          isOpen={openPanel}
          onDismiss={() => {
            setOpenPanel(false);
            setElementToEdit({
              Id: '',
              ServiceName: '',
              IsActive: false,
              Name: {
                Value: '',
                ValueLower: '',
              },
              Value: {
                Value: '',
                ValueLower: '',
              },
            });
          }}
          styles={{
            body: {
              padding: '1rem',
            },
          }}
          headerText={elementToEdit ? elementToEdit.Name?.Value : ''}
          actions={[
            {
              key: 'Save',
              text: 'Save',
              isPrimary: true,
              onClick: () => {
                onSaveSetting(elementToEdit);
                setOpenPanel(false);
              },
            },
            {
              key: 'Close',
              text: 'Dismiss',
              onClick: () => {
                setOpenPanel(false);
                setElementToEdit({
                  Id: '',
                  ServiceName: '',
                  IsActive: false,
                  Name: {
                    Value: '',
                    ValueLower: '',
                  },
                  Value: {
                    Value: '',
                    ValueLower: '',
                  },
                });
              },
            },
          ]}>
          <ElxTextField
            defaultValue={elementToEdit ? elementToEdit.Value.Value : ''}
            label="VALUE"
            multiline={true}
            className="panel-name"
            onChange={(event, newValue) => {
              setElementToEdit(originalElement => {
                if (originalElement) {
                  const existingObj = originalElement as any;
                  existingObj.Value.Value = newValue;
                  let finalUpdated: IInvariantString = {
                    Value: props.userId,
                    ValueLower: props.userId.toLowerCase(),
                  };
                  existingObj.LastUpdatedBy = finalUpdated;
                  return {
                    ...(existingObj as any),
                  };
                }
                return originalElement;
              });
            }}
          />
        </ElxPanel>
      </>
    );
  }

  function actions(): ITableAction[] {
    return [
      {
        key: 'Edit',
        text: 'Edit',
        disableBulkAction: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onAction: (item: any) => {
          setOpenPanel(true);
          setElementToEdit(item['element']);
        },
        iconProps: { iconName: 'EditSolid12' },
        trackingContext: {
          label: 'CustomActionLabel',
        },
      },
    ];
  }
};

function getColumns(): IElxColumn[] {
  return [
    {
      key: '2',
      name: 'Name',
      fieldName: 'Name',
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: '3',
      name: 'Value',
      fieldName: 'Value',
      minWidth: 150,
      //maxWidth: 700,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: '4',
      name: 'Created Date',
      fieldName: 'CreatedDate',
      minWidth: 90,
      //maxWidth: 150,
      isResizable: true,
      isMultiline: true,
      onRender: item => {
        let createdDate = item['CreatedDate'] ?? Resources?.Dates?.DefaultDate;
        if (createdDate === 'N/A') createdDate = Resources?.Dates?.DefaultDate;
        return (
          <span>
            {new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              timeZone: 'UTC',
            }).format(new Date(createdDate))}
          </span>
        );
      },
    },
    {
      key: '5',
      name: 'Updated Date',
      fieldName: 'UpdatedDate',
      minWidth: 90,
      //maxWidth: 150,
      isResizable: true,
      isMultiline: true,
      onRender: item => {
        let updatedDate = item['UpdatedDate'] ?? Resources?.Dates?.DefaultDate;
        if (updatedDate === 'N/A') updatedDate = Resources?.Dates?.DefaultDate;
        return (
          <span>
            {new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              timeZone: 'UTC',
            }).format(new Date(updatedDate))}
          </span>
        );
      },
    },
    {
      key: '6',
      name: 'LastUpdatedBy',
      fieldName: 'LastUpdatedBy',
      minWidth: 90,
      isMultiline: true,
      //maxWidth: 100,
      isResizable: true,
      onRender: item => {
        return <span>{item.LastUpdatedBy?.Value ?? 'System Account'}</span>;
      },
    },
  ];
}

export default SettingsDetails;
